import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';

@NgModule({
  exports: [
    CalendarModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  imports: [
    CalendarModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class SharedImportsModule { }
