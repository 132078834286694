import { Pipe, PipeTransform } from '@angular/core';
import { BasketDeal } from '@app/models/basket-deal';
import { BasketItem } from '@app/models/basket-item';

@Pipe({ name: 'NonLocalBasketItemsFilter' })
export class NonLocalBasketItemsFilter implements PipeTransform {
  public transform(basketItems: (BasketItem | BasketDeal)[]): (BasketItem | BasketDeal)[] {
    return basketItems.filter((item: BasketItem | BasketDeal) => !!item.Id || !!item.DealId);
  }
}
