import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { Basket } from '@app/models/basket';
import { BasketItem } from '@app/models/basket-item';
import { BasketService } from '@app/services/basket.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NavigationSectionType } from '@app/models/navigation/navigation-section-type';
import { NavigatorService } from '@app/core/navigator.service';
import { Router } from '@angular/router';
import { Site } from '@app/models/site';
import { SiteService } from '@app/services/site.service';
import { User } from '@app/models/user';
import { UserService } from '@app/services/user.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { PaymentProviderType } from '@app/models/site/PaymentProviderType.enum';
import { PaymentTypeOverrideKey } from '@app/models/local-storage-keys';
import { takeUntil } from 'rxjs';
import { AppRouteStatus } from '@app/models/shared/AppRouteStatus';
import { OrderOccasion } from '@app/models/order-occasion';
import { StorageKeys } from '@app/models/_root/_storage-keys';

@Component({
  animations: [
    trigger('valueAnimation', [
      transition(':increment', [
        style({ fontSize: '36px' }),
        animate('0.5s ease', style('*'))
      ]
      ),
      transition(':decrement', [
        style({ fontSize: '36px' }),
        animate('0.5s ease', style('*'))
      ]
      )
    ])
  ],
  selector: 'app-navigation',
  styleUrls: ['./navigation.component.scss'],
  templateUrl: './navigation.component.html',
})
export class NavigationComponent extends AndroWebCoreComponent implements OnInit {
  @ViewChild('mobileMenuSlider') private mobileMenuSlider: ElementRef;
  @ViewChild('basketModalContent') private basketModalContent: TemplateRef<any>;

  public currentUser: User;
  public currentSite: Site;
  public currentBasket: Basket;
  public currentBasketCount: number;
  public showProviderSwapButton: boolean;
  public supportsStripe: boolean;
  public isProfilePage: boolean;
  public isMenuPage: boolean;
  public isCheckoutPage: boolean;
  public isHomePage: boolean;

  constructor(
    public navigatorService: NavigatorService,
    private router: Router,
    private dialog: MatDialog,
    private siteService: SiteService,
    private userService: UserService,
    private basketService: BasketService
  ) {
    super();
    this.setup();
  }

  ngOnInit(): void {
    this.navigatorService.status$
        .pipe(takeUntil(this.destroy$))
        .subscribe((event: AppRouteStatus) => {
          this.trackCurrentRoute(event);
        });
  }

  public get paymentProviderInverse() {
    const provider: string = localStorage.getItem(PaymentTypeOverrideKey);
    return provider && provider === 'SagePay' ? 'Stripe' : 'SagePay';
  }

  /**
   * opens a mat dialog with the basket template inside
   */
  public openBasket(): void {
    const options: MatDialogConfig = {
      height: this.isTablet ? '100%' : '90%',
      panelClass: 'basket-modal-window',
      position: {
        right: this.isTablet ? '0px' : '35px',
        top: this.isTablet ? '0' : (this.isShortDevice ? '45px' : '75px'),
      },
      width: this.isTablet ? '100%' : '580px',
    };

    this.openDialog(this.basketModalContent, 'basket-modal', options);
  }

  /**
   * either opens or closes the mat dialog housing basket inside
   */
  public toggleBasket(): void {
    const dialogRef = this.dialog.getDialogById('basket-modal');

    if (dialogRef) {
      dialogRef.close();
    } else {
      this.openBasket();
    }
  }

  /**
   * opens a ngb modal with the confirm change store template inside and if confirmed routes the user to the home page
   * @param content the confirm change store template
   */
  public confirmChangeStore(content: TemplateRef<any>): void {
    if (this.currentBasket?.Items?.length > 0) {
      this.openDialog(content, 'confirmSwitchStore', { maxWidth: '450px', panelClass: ['no-padding', 'confirmSwitchStore'] });
    } else {
      this.router.navigate([this.navigatorService.generateHomePath()]);
    }
  }

  /**
   * navigates to the home page and closes the warning modal.
   */
  public switchStore(): void {
    this.router.navigate([this.navigatorService.generateHomePath()]);
    this.closeModalById('confirmSwitchStore');
  }

  /**
   * switches the payment provider
   */
  public switchPaymentProvider(): void {
    const provider: string = localStorage.getItem(PaymentTypeOverrideKey);

    if (provider === 'SagePay') {
      localStorage.removeItem(PaymentTypeOverrideKey);
    } else {
      localStorage.setItem(PaymentTypeOverrideKey, 'SagePay');
    }

    location.reload();
  }

  /**
   * initiates the login process
   * @param returnRoute the route to return to once the user is logged in
   */
  public login(returnRoute?: string): void {
    this.userService.login(false, returnRoute);
  }

  /**
   * initiates the logout process
   */
  public logOut(): void {
    this.basketService.clearOldAndCreateNewBasket(this.currentSite.Id, null, this.currentBasket.Occasion);
    this.userService.logOutUser();
  }

  /**
   * returns the number of items in the basket
   */
  public totalItemsInBasket(): number {
    let total: number = 0;
    this.currentBasket.Items.forEach((item: BasketItem) => total += item.Product.Quantity);
    total += this.currentBasket.Deals.length;
    return total;
  }

  /**
   * returns whether the basket should be shown
   */
  public shouldShowBasket(): boolean {
    return !this.isMobile && this.currentBasket && !this.isHomePage && !this.isCheckoutPage;
  }

  /**
   * returns whether the storename should be shown
   */
  public shouldShowSite(): boolean {
    const isMenu: boolean = this.router.url.toLowerCase().includes('menu');
    return this.currentSite && isMenu && this.currentBasket?.Occasion !== OrderOccasion.DineIn;
  }


  /**
   * navigates to the menu page
   */
  public returnToMenu(): void {
    const location: string = this.navigatorService.getLocationBySiteId(this.currentSite?.Id);
    const routePath: string = this.navigatorService.generateRoutePath(location, this.currentBasket.Occasion, NavigationSectionType.Menu);
    this.router.navigate([routePath]);
  }

  /**
   * navigates to the home page
   */
  public navigateHome($event: MouseEvent): void {
    $event.preventDefault();

    const getBasketPath = (): string => {
      if (this.currentBasket?.Occasion === OrderOccasion.DineIn) {
        return this.navigatorService.generateDineInPath(this.currentBasket.SiteId);
      }

      const lastBasketOccasion: string = localStorage.getItem(StorageKeys.lastBasketOccasion);

      if (!this.currentBasket && lastBasketOccasion) {
        localStorage.removeItem(StorageKeys.lastBasketOccasion);
        const last = JSON.parse(lastBasketOccasion);

        if (last.occasion === OrderOccasion.DineIn) {
          return this.navigatorService.generateDineInPath(last.siteId);
        }
      }

      return this.navigatorService.generateHomePath();
    };

    this.router.navigate([getBasketPath()]);
  }

  /**
   * sets the currentSite, currentUser, currentBasket and logoImage
   */
  private setup(): void {
    this.subscriptions$['NavigationComponent-constructor-siteService-currentSite'] =
      this.siteService.currentSite.subscribe((currentSite: Site) => {
        this.currentSite = currentSite;

        if (currentSite && currentSite.PaymentProvidersSupported.includes(PaymentProviderType.Stripe)) {
          this.showProviderSwapButton = currentSite.Id === '9499b40a-ec3e-4250-9802-ed9b307eca08';
        }
      });

    this.subscriptions$['NavigationComponent-constructor-userService-currentUser$'] =
      this.userService.currentUser$.subscribe((user: User) => this.currentUser = user);

    this.subscriptions$['NavigationComponent-constructor-basketService-currentBasket'] =
      this.basketService.currentBasket.subscribe((basket: Basket) => {
        this.currentBasket = basket;
        this.trackBasketCount(basket);
      });
  }

  /**
   * tracks the basket total.
   * @param basket
   */
  private trackBasketCount(basket: Basket): void {
    if (basket) {
      let total: number = basket.Deals?.length ?? 0;
      basket.Items?.forEach((item: BasketItem) => total += item.Product.Quantity);

      if (this.currentBasketCount !== total) {
        this.currentBasketCount = total;
      }
    }
  }

  /**
   * tracks the current route.
   * @param url the current url
   */
  private trackCurrentRoute(status: AppRouteStatus): void {
    if (!status) {
      return;
    }

    this.isProfilePage = status.isProfilePage;
    this.isMenuPage = status.isMenuPage;
    this.isCheckoutPage = status.isCheckoutPage;
    this.isHomePage = status.isHomePage;
  }
}
