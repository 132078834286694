import { DisplayAvailableType } from '@app/models/display-available-type';
import { DisplayDealLineOptions } from '@app/models/display-deal-line-options';
import { DisplayLineTypes } from '@app/models/display-line-types';
import { DisplayOccasionType } from '@app/models/display-occasion-type';

/**
 * Data in a display deal item.
 * @export
 * @class Deal
 */
export class DisplayDealItem {
  AvailableOccasions?: Array<DisplayOccasionType>;
  CustomerName: string;
  DealLines: Array<DisplayDealLineOptions>;
  DealName: string;
  FromPrice: number;
  Id: string;
  ImagePath?: string;
  IsAvailableForWantedTime: DisplayAvailableType;
  OutOfStock?: boolean;
  ProductDescription: string;
  ProductName?: string;
  RequiresUnlock?: boolean;
  ToPrice: number;
  ValidForBasket: boolean;

  public get IsFixedPrice(): boolean {
    let notFixed = false;

    this.DealLines.forEach((dealLine: DisplayDealLineOptions) => {
      notFixed = notFixed || !((dealLine.LineType === DisplayLineTypes.Fixed) || dealLine.LineType === DisplayLineTypes.Percent && dealLine.LineTypeValue === 0);
    });

    return !notFixed;
  }

  public AvailableForWantedTime(): DisplayAvailableType {
    const availableOptions = Array.from(new Set(this.DealLines.map((e: DisplayDealLineOptions) => e.IsAvailableForWantedTime())).values());

    if (availableOptions.includes(DisplayAvailableType.Yes)) {
      return DisplayAvailableType.Yes;
    }

    if (availableOptions.includes(DisplayAvailableType.No)) {
      return DisplayAvailableType.No;
    }

    return DisplayAvailableType.Unknown;
  }
}
