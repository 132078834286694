import { ProductOptionGroup } from '@app/models/product-option-gGroup';
import { DisplayProduct } from '@app/models/display-product';
import { DisplayAvailableType } from '@app/models/display-available-type';
import { DisplayAllergenDetails } from '@app/models/display-allergen-details';
import { DisplayMenuNutrition } from '@app/models/display-menu-nutrition';

export class DisplayMenuItemGroup {
  GroupName: string;
  CustomerName?: string;
  CustomerDescription?: string;
  FromPrice: number;
  ToPrice: number;
  Products: Array<DisplayProduct> = new Array<DisplayProduct>();
  AllergenDetails: DisplayAllergenDetails;
  SubGroupsProducts: Array<DisplayProduct> = new Array<DisplayProduct>();
  SubGroups: Array<DisplayMenuItemGroup> = new Array<DisplayMenuItemGroup>();
  ProductOptionGroups: ProductOptionGroup[];
  Nutrition?: DisplayMenuNutrition;

  IsAvailableForWantedTime(): DisplayAvailableType {
    return this.AvailableForWantedTime();
  }

  public AvailableForWantedTime(): DisplayAvailableType {
    const availableOptions = Array.from(new Set(this.Products.map((e: DisplayProduct) => e.IsAvailableForWantedTime)).values());

    this.SubGroups.forEach((group: DisplayMenuItemGroup) => {
      availableOptions.push(group.IsAvailableForWantedTime());
    });

    if (availableOptions.includes(DisplayAvailableType.Yes)) {
      return DisplayAvailableType.Yes;
    }

    if (availableOptions.includes(DisplayAvailableType.No)) {
      return DisplayAvailableType.No;
    }

    return DisplayAvailableType.Unknown;
  }
}
