import { OrderOccasion } from '@app/models/order-occasion';
import { Deal } from '@app/models/Deal';
import { DealLine } from '@app/models/deal-line';
import { DisplayOccasionType } from '@app/models/display-occasion-type';
import { DisplayDealLine } from '@app/models/display-deal-line';

/**
 * Data in a display deal.
 * @export
 * @class Deal
 */
export class DisplayDeal {
  CustomerName: string;
  Description: string;
  DisplayOrder: number;
  First2LinesCouldBeReordered: boolean;
  Id: string;
  ImageUrl?: string;
  Lines: DisplayDealLine[];
  MinimumSpend: number;
  Name: string;
  Occasions: DisplayOccasionType[];
  OutOfStock: boolean;
  RequiresUnlock?: boolean;
  ValidForBasket: boolean;
  Sequence: number;

  public static FromApiDeal(apiDeal: Deal): DisplayDeal {
    const deal = new DisplayDeal();
    deal.CustomerName = apiDeal.CustomerName;
    deal.Description = apiDeal.CustomerDescription;
    deal.DisplayOrder = apiDeal.DisplayOrder;
    deal.First2LinesCouldBeReordered = apiDeal.First2LinesCouldBeReordered;
    deal.Id = apiDeal.Id;
    deal.ImageUrl = apiDeal.ImageUrl;
    deal.Lines = apiDeal.Lines.map((e: DealLine) => DisplayDealLine.FromApiDealLine(e));
    deal.MinimumSpend = apiDeal.MinimumSpend;
    deal.Name = apiDeal.Name;
    deal.Occasions = apiDeal.Occasions.map((e: OrderOccasion) => <DisplayOccasionType><unknown>e);
    deal.OutOfStock = apiDeal.OutOfStock;
    deal.RequiresUnlock = apiDeal.RequiresUnlock;
    deal.ValidForBasket = apiDeal.ValidForBasket;
    deal.Sequence = apiDeal.Sequence;

    return deal;
  }
}
