// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --footer-background-colour: #333333;
  --footer-link-colour: #fff;
  --nav-bar-background-colour: #fff;
  --product-content-padding: 0 10px;
}

.basket-item-error {
  font-weight: bold;
  color: red;
}

.deal-contents-container {
  max-width: 100%;
  width: 100%;
}

.deal-contents-container h4 {
  font-size: 19px;
  font-family: Tenant-Bold, Courier, Helvetica, sans-serif;
  margin-bottom: 0;
  text-decoration: underline;
}

.deal-contents-container .deal-item {
  margin-bottom: 5px;
}

.deal-contents-container .deal-item:last-of-type {
  margin-bottom: 0;
}

.deal-expansion-toggle {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deal-expansion-toggle .mat-icon {
  cursor: pointer;
  border-radius: 50%;
}

.deal-expansion-toggle .mat-icon:hover {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 0 5px 0 rgba(0, 0, 0, 0.15);
}

.basket-item-details-row-group {
  display: flex;
  align-items: center;
}

.basket-item-voucher-tag {
  background-color: #ff0;
  border: thin solid rgba(0, 0, 0, 0.05) !important;
  color: #000;
  font-weight: bold;
  font-family: Tenant-Bold, Courier, Helvetica, sans-serif;
  font-size: 13px;
  border-radius: 12px !important;
  line-height: initial !important;
  padding: 4px 6px !important;
  margin-left: 5px !important;
  cursor: default;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
