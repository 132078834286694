import { QuantityOf } from '@app/models/quantity-of';
import { Product } from '@app/models/Product';
import { DisplayMenuGroupItem } from '@app/models/display-menu-group-item';
import { DisplayAllergenDetails } from '@app/models/display-allergen-details';
import { DisplayMenuItemGroup } from '@app/models/display-menu-item-group';
import { DisplayMenuItem } from '@app/models/display-menu-item';
import { DisplayAvailableType } from '@app/models/display-available-type';
import { DisplayOccasionType } from '@app/models/display-occasion-type';
import { DisplayModifiers } from '@app/models/display-modifiers';
import { DisplayMenuPrice } from '@app/models/display-menu-price';
import { Nutrition } from '@app/models/menu/nutrition';

export class DisplayProduct {
  AllergenDetails?: DisplayAllergenDetails;
  CustomerName: string;
  DealPricePremium?: number;
  Description?: string;
  DisplayOrder?: number;
  Id: string;
  ImageUrl?: string;
  IsAvailableForWantedTime?: DisplayAvailableType; // Display_AvailableType.Unknown;
  Modifiers: DisplayModifiers;
  Name: string;
  OutOfStock?: boolean;
  Prices: Array<DisplayMenuPrice>;
  ValidForBasket?: boolean;
  Sequence?: number;
  NutritionSummary?: string;
  Nutrition?: Nutrition;

  get AvailableOccasions(): Array<DisplayOccasionType> {
    return Array.from(new Set(this.Prices.map((e: DisplayMenuPrice) => e.Occasion)).values());
  }

  public static CheckModifiersForProduct(product: DisplayProduct, removedDefaults: QuantityOf[], optionalModifiers: Array<QuantityOf>): void {
    if (optionalModifiers) {
      if (optionalModifiers.reduce((sum, next: QuantityOf) => sum + next.Quantity, 0) > product.Modifiers.Max) {
        throw new Error('Too many optional modifiers added');
      }

      if (optionalModifiers.length > 0 &&
          optionalModifiers.reduce((max, next: QuantityOf) => next.Quantity > max ? max = next.Quantity : null, 0) > product.Modifiers.MaxEach) {
        throw new Error('Too many of the same optional modifier added');
      }

      if (removedDefaults.find((removed: QuantityOf) => !product.Modifiers.Default.map((defaults: DisplayProduct) => defaults.Id).includes(removed.Item))) {
        throw new Error('Cannot remove a default product which isn\'t in defaults list');
      }

      if (optionalModifiers.find((additions: QuantityOf) => !product.Modifiers.Optional.map((optional: DisplayProduct) => optional.Id).includes(additions.Item) &&
          !product.Modifiers.Default.map((defaults: DisplayProduct) => defaults.Id).includes(additions.Item))) {
        throw new Error('Cannot add a product which isn\'t in optional list');
      }
    }
  }

  public static FromApiProducts(apiProducts: Array<Product>): Array<DisplayProduct> {
    if (!apiProducts) {
      return [];
    }

    return apiProducts.map((x: Product) => DisplayProduct.FromApiProduct(x));
  }

  public static FromApiProduct(apiProduct: Product): DisplayProduct {
    const product = new DisplayProduct();
    product.AllergenDetails = apiProduct.AllergenDetails;
    product.DealPricePremium = apiProduct.DealPricePremium;
    product.Description = apiProduct.CustomerDescription ? apiProduct.CustomerDescription : apiProduct.Description;
    product.ImageUrl = apiProduct.ImageUrl;
    product.Id = apiProduct.Id;
    product.Name = apiProduct.Name;
    product.CustomerName = apiProduct.CustomerName;
    product.Prices = DisplayMenuPrice.FromApiMenuPrices(apiProduct.Prices);
    product.ValidForBasket = apiProduct.ValidForBasket;
    product.OutOfStock = apiProduct.OutOfStock;
    product.Sequence = apiProduct.Sequence;
    product.Nutrition = apiProduct.Nutrition;
    product.NutritionSummary = apiProduct.Nutrition ? `${apiProduct.Nutrition.Calories} kcal • serves ${apiProduct.Nutrition.NumberOfPortions}` : null;
    return product;
  }

  public static ToApiProduct(displayProduct: DisplayProduct): Product {
    const product = new Product();
    product.AllergenDetails = displayProduct.AllergenDetails;
    product.DealPricePremium = displayProduct.DealPricePremium;
    product.Description = displayProduct.Description;
    product.ImageUrl = displayProduct.ImageUrl;
    product.Id = displayProduct.Id;
    product.Name = displayProduct.Name;
    product.CustomerName = displayProduct.CustomerName;
    product.Prices = DisplayMenuPrice.ToApiMenuPrices(displayProduct.Prices);
    product.ValidForBasket = displayProduct.ValidForBasket;
    product.OutOfStock = displayProduct.OutOfStock;
    product.Sequence = displayProduct.Sequence;
    product.Nutrition = displayProduct.Nutrition;

    return product;
  }

  public static toMenuItem(item: DisplayProduct, occasion: DisplayOccasionType) {
    const menuItem = new DisplayMenuItem();
    menuItem.FromPrice = item.Prices[occasion] ? item.Prices[occasion].Amount : 0;
    menuItem.ToPrice = item.Prices[occasion] ? item.Prices[occasion].Amount : 0;
    menuItem.ProductName = item.Name;
    menuItem.CustomerName = item.CustomerName;
    menuItem.ProductDescription = item.Description;
    menuItem.AllProductsInGroup = [DisplayProduct.productToMenuGroupItem(item)];
    menuItem.SubGroup = [];
    menuItem.ImagePath = item.ImageUrl;
    menuItem.ValidForBasket = item.ValidForBasket;
    menuItem.Sequence = item.Sequence;
    menuItem.NutritionSummary = item.NutritionSummary;

    return menuItem;
  }

  public static isSimpleProduct(menuGroupItem: DisplayMenuGroupItem): boolean {
    return !DisplayProduct.isProductEditable(menuGroupItem) && !DisplayProduct.hasAllergens(DisplayProduct.menuGroupItemToProduct(menuGroupItem));
  }

  public static isProductEditable(menuGroupItem: DisplayMenuGroupItem): boolean {
    return menuGroupItem.type === 'deal' ||
    ((menuGroupItem.Modifiers.Default && menuGroupItem.Modifiers.Default.length > 0) ||
    (menuGroupItem.Modifiers.Optional && menuGroupItem.Modifiers.Optional.length > 0));
  }

  public static hasAllergens(product: DisplayProduct): boolean {
    if (product.AllergenDetails) {
      return (product.AllergenDetails.Contains.length > 0 || product.AllergenDetails.MayContain.length > 0);
    } else {
      return false;
    }
  }

  public static hasOptionAllergens(group: DisplayMenuItemGroup): boolean {
    if (group.AllergenDetails?.Contains?.length > 0 || group.AllergenDetails?.MayContain?.length > 0) {
      return true;
    }
    return false;
  }

  public static productToMenuGroupItem(product: DisplayProduct) {
    const groupItem = new DisplayMenuGroupItem();
    groupItem.Id = product.Id;
    groupItem.Name = product.Name;
    groupItem.CustomerName = product.CustomerName;
    groupItem.Description = product.Description;
    groupItem.DisplayOrder = product.DisplayOrder;
    groupItem.ValidForBasket = product.ValidForBasket;
    groupItem.ImageUrl = product.ImageUrl;
    groupItem.Prices = product.Prices;
    groupItem.Modifiers = product.Modifiers;
    groupItem.DealPricePremium = product.DealPricePremium;
    groupItem.AllergenDetails = product.AllergenDetails;
    groupItem.IsAvailableForWantedTime = product.IsAvailableForWantedTime;
    groupItem.AvailableOccasions = product.AvailableOccasions;
    groupItem.type = 'product';
    groupItem.OutOfStock = product.OutOfStock;
    groupItem.Sequence = product.Sequence;
    groupItem.Nutrition = product.Nutrition;

    return groupItem;
  }

  public static menuGroupItemToProduct(item: DisplayMenuGroupItem) {
    const product = new DisplayProduct();
    product.Id = item.Id;
    product.Name = item.Name;
    product.CustomerName = item.CustomerName;
    product.Description = item.Description;
    product.DisplayOrder = item.DisplayOrder;
    product.ImageUrl = item.ImageUrl;
    product.ValidForBasket = item.ValidForBasket;
    product.Prices = item.Prices;
    product.Modifiers = item.Modifiers;
    product.DealPricePremium = item.DealPricePremium;
    product.AllergenDetails = item.AllergenDetails;
    product.IsAvailableForWantedTime = item.IsAvailableForWantedTime;
    product.OutOfStock = item.OutOfStock;
    product.Sequence = item.Sequence;
    product.Nutrition = item.Nutrition;
    product.NutritionSummary = item.Nutrition ? `${item.Nutrition.Calories} kcal • serves ${item.Nutrition.NumberOfPortions}` : null;

    return product;
  }
}
