import { DayOfWeek } from '@app/models/day-of-week.enum';
import { Injectable } from '@angular/core';
import { OpeningDaysAndTime } from '@app/models/opening-days-and-time';
import { IDayPeriod } from '@app/models/site/IDayPeriod';
import { IDayOfWeek } from '@app/models/IDayOfWeek.enum';
import { OpeningHours } from '@app/models/opening-hours';

/**
 * Helper methods for services
 * @export
 * @class ServiceHelper
 */
@Injectable({
  providedIn: 'root',
})
export class ServiceHelper {
  /**
   * returns opening dates and times that adheres to a trading date (6:30 - 6:30)
   * @param openingHours
   */
  public getOpeningDatesAndTimes(openingHours: OpeningHours[]): OpeningDaysAndTime[] {
    const daysOfWeek = Object.values(IDayOfWeek);
    const result: OpeningDaysAndTime[] = [];

    const sundayOverlap: OpeningHours[] = openingHours.filter((x: OpeningHours) => x.DayOfWeek === DayOfWeek.Monday && x.EndTime <= '06:30:00');
    sundayOverlap.forEach((x: OpeningHours) => {
      x.DayOfWeek = 6;
      x.Day = 'Sunday';
    });

    const mergedOpeningHours: OpeningHours[] = this.mergeTimePeriods(openingHours);

    let todaysDate = new Date().getDay() - 1;
    todaysDate = todaysDate === -1 ? 6 : todaysDate;

    daysOfWeek.forEach((item: IDayOfWeek) => {
      const matchingHours: IDayPeriod[] = mergedOpeningHours
          .filter((x: OpeningHours) => x.DayOfWeek === DayOfWeek[item])
          .map((x: OpeningHours) => ({
            End: this.formatTime(x.EndTime),
            Start: this.formatTime(x.StartTime)
          }));

      result.push({
        Day: item,
        DayOfWeek: DayOfWeek[item],
        End: matchingHours[0]?.End,
        Start: matchingHours[0]?.Start,
        isToday: this.isToday(todaysDate, item),
        open: matchingHours.length > 0,
        times: matchingHours,
      });
    });

    return result;
  }

  /**
   * returns true if the given day is today
   * @param todaysDate
   * @param dayOfWeek
   */
  private isToday(todaysDate: number, dayOfWeek: IDayOfWeek): boolean {
    return DayOfWeek[todaysDate] === dayOfWeek;
  }

  /**
   * formats the given string to HH:mm
   * @param time
   */
  private formatTime(time: string): string {
    let _time = time;

    if (time.includes('.')) {
      _time = time.split('.')[1];
    }

    return _time.split(':').slice(0, 2).join(':');
  }

  /**
   * Merges time periods that are adjacent to each other.
   */
  private mergeTimePeriods(periods: OpeningHours[]): OpeningHours[] {
    const mergedPeriods: OpeningHours[] = [];
    const usedIndices = new Set<number>();

    periods.forEach((period: OpeningHours, index: number) => {
      if (usedIndices.has(index)) {
        return;
      }

      const mergePeriod = (endTime: string, startTime: string, matchingIndex: number) => {
        mergedPeriods.push({
          Day: period.Day,
          DayOfWeek: period.DayOfWeek,
          EndTime: endTime,
          StartTime: startTime
        });
        usedIndices.add(index);
        usedIndices.add(matchingIndex);
      };

      if (period.StartTime === '00:00:00') {
        const matchingStartIndex: number = periods
            .findIndex((p: OpeningHours, i: number) => i !== index && p.DayOfWeek === period.DayOfWeek && p.EndTime === '1.00:00:00');

        if (matchingStartIndex > -1) {
          mergePeriod(period.EndTime, periods[matchingStartIndex].StartTime, matchingStartIndex);
          return;
        }
      }

      if (period.EndTime === '1.00:00:00') {
        const matchingEndIndex: number = periods.findIndex((p: OpeningHours, i: number) =>
          i !== index && (p.DayOfWeek === period.DayOfWeek + 1) && p.StartTime === '00:00:00' && !usedIndices.has(i));

        if (matchingEndIndex > -1) {
          mergePeriod(periods[matchingEndIndex].EndTime, period.StartTime, matchingEndIndex);
          return;
        }
      }

      mergedPeriods.push(period);
    });

    return mergedPeriods;
  }
}
