import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, Event } from '@angular/router';
import { NavigationSectionType } from '@app/models/navigation/navigation-section-type';
import { Site } from '@app/models/site';
import { SiteService } from '@app/services/site.service';
import { BehaviorSubject, Observable, filter, takeUntil } from 'rxjs';
import { AndroWebCoreService } from '@app/core/AndroWebCoreService';
import { AppRouteStatus } from '@app/models/shared/AppRouteStatus';

@Injectable({
  providedIn: 'root',
})
export class NavigatorService extends AndroWebCoreService {
  public status$: Observable<AppRouteStatus>;

  private readonly _pathSeparator: string = '/';
  private readonly _emptySpaceReplacement: string = '-';
  private readonly _space: string = ' ';
  private readonly _dealSeparator: string = 'offers';

  private _status: BehaviorSubject<AppRouteStatus>;

  /**
   * Initialises the NavigatorService
   */
  constructor(
    public siteService: SiteService,
    private router: Router,
  ) {
    super();
    this._status = new BehaviorSubject<AppRouteStatus>({} as Partial<AppRouteStatus> as AppRouteStatus);
    this.status$ = this._status.asObservable();
    this.trackCurrentRoute();
  }

  /**
   * The route path needs to be formatted in the following format for products domain/Town-PostcodeDistrict/occasionName/menu/group/product. If it
   * is part of a deal we format it slightly different for the angular route module to pick it up and point to the correct component.
   * domain/Town-PostcodeDistrict/occasionName/menu/group/Offers/product/DealId
   * @param {location} location The site location
   * @param {string} occasion The occasion type. Delivery or Collection/TakeAway
   * @param {NavigationSectionType} [navigationType=null] Navigation Segement
   * @param {string} [group=null] The web sections group
   * @param {string} [product=null] The product name
   * @param {string} [productId=null] The productId
   * @return {*}  {string} The path for routing
   * @memberof NavigatorService
   */
  public generateRoutePath(
      location: string,
      occasion: string,
      navigationType?: NavigationSectionType,
      group?: string,
      product?: string,
      productId?: string,
      dealId?: string
  ): string {
    const currentOccasion: string = this.changeCollectionToTakeAway(occasion);
    let path: string = `${location}${this._pathSeparator}${currentOccasion}`;
    if (navigationType) {
      path = path.concat(`${this._pathSeparator}${navigationType}`);
    }
    if (group) {
      path = path.concat(`${this._pathSeparator}${this.clean(group)}`);
    }
    if (dealId) {
      path = path.concat(`${this._pathSeparator}${this._dealSeparator}`);
    }
    if (product) {
      path = path.concat(`${this._pathSeparator}${this.clean(product)}`);
    }
    if (productId) {
      path = path.concat(`${this._pathSeparator}${productId}`);
    }
    if (dealId) {
      path = path.concat(`${this._pathSeparator}${dealId}`);
    }

    return path.toLocaleLowerCase();
  }

  /**
   * Returns the homepage
   * @return {*}  {string}
   * @memberof NavigatorService
   */
  public generateHomePath(): string {
    return NavigationSectionType.Home;
  }

  /**
   * Returns the homepage for the Dine in occasion
   * @return {*}  {string}
   * @memberof NavigatorService
   */
  public generateDineInPath(siteId: string): string {
    return `dine-in/${this.getLocationBySiteId(siteId) ?? ''}`;
  }

  /**
   * Takes the siteId and returns the store location as Town-PostcodeDistrict
   * @public
   * @param {string} siteId the site ID
   * @return {*}  {string} The  formatted name of Town-PostcodeDistrict
   * @memberof NavigatorService
   */
  public getLocationBySiteId(siteId: string): string {
    if (!this.siteService.sites.value) {
      return null;
    }

    const currentSite: Site = this.siteService.sites.value.find((s: Site) => s.Id === siteId);
    const postCode = currentSite.Address.Postcode.split(this._space);
    return this.ensureSingleSiteIsMatched(currentSite.Address.Town, postCode[0], postCode[1]);
  }

  /**
   * Takes in the location and returns the site id if found
   * @public
   * @param {string} location
   * @return {*}  {string}
   * @memberof NavigatorService
   */
  public getSiteIdByLocation(location: string): string {
    let siteId: string = null;
    if (location) {
      siteId = this.getSiteIdFromSegments(location, 1);
    }
    return siteId;
  }

  /**
   * Changes the naming convention from collection to takeaway
   * @public
   * @param {string} occasion
   * @return {*}  {string}
   * @memberof NavigatorService
   */
  public changeCollectionToTakeAway(occasion: string): string {
    if (occasion.toLocaleLowerCase() === 'collection') {
      return 'takeaway';
    }
    return occasion.toLocaleLowerCase();
  }

  /**
   * Changes the naming convention from takeaway to collection
   * @public
   * @param {string} occasion
   * @return {*}  {string}
   * @memberof NavigatorService
   */
  public changeTakeAwayToCollection(occasion: string): string {
    if (occasion.toLocaleLowerCase() === 'takeaway') {
      return 'collection';
    }
    return occasion.toLocaleLowerCase();
  }

  /**
   * Cleans the product and group name
   * @param {string} input
   * @return {*}  {string}
   * @memberof NavigatorService
   */
  public clean(input: string): string {
    const result: string = encodeURIComponent(this.cleanSpecialCharacters(this.replaceSpacing(input))).toLocaleLowerCase();
    return result;
  }

  /**
   * Helper function to change to title case
   * @param {*} input
   * @return {*}  {string}
   * @memberof NavigatorService
   */
  public toTitleCase(input: string): string {
    return input.replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
  }

  /**
   * filters through all sites to make sure that the `postcodeDistrict` and `inwardCode` is unique to a single site.
   * @memberof NavigatorService
   * @private
   */
  private ensureSingleSiteIsMatched(town: string, postcodeDistrict: string, inwardCode: string): string {
    // matching town && matching start of postcode
    const sites: Site[] = this.siteService.sites.value
        .filter((x: Site) => x.Address.Town === town && postcodeDistrict === x.Address.Postcode.split(this._space)[0]);

    if (sites.length > 1) {
      postcodeDistrict = this.getPostCodeDistrict(sites, inwardCode, postcodeDistrict);

      if (postcodeDistrict === null) {
        return null;
      }
    }

    return (`${town}-${postcodeDistrict}`).toLocaleLowerCase();
  }

  /**
   * Returns a string if the `postcodeDistrict` and `inwardCode` are unique to a single site, if it not adjusts the inwardCode
   * until it is unique. If no site is found or more than one is found it will returns null.
   * @param sites
   * @param inwardCode
   * @param postcodeDistrict
   * @param inwardCodeLimit
   * @memberof NavigatorService
   * @private
   */
  private getPostCodeDistrict(sites: Site[], inwardCode: string, postcodeDistrict: string, inwardCodeLimit: number = 1): string {
    const inwardCodeSubstring: string = inwardCode.substring(0, inwardCodeLimit);
    const _sites: Site[] = sites
        .filter((x: Site) => inwardCodeSubstring === x.Address.Postcode.split(this._space)[1].substring(0, inwardCodeLimit));

    if (_sites.length === 1) {
      return `${postcodeDistrict}-${inwardCodeSubstring}`;
    } else if (_sites.length > 1 && inwardCodeLimit <= inwardCode.length) {
      return this.getPostCodeDistrict(sites, inwardCode, postcodeDistrict, inwardCodeLimit + 1);
    } else {
      return null;
    }
  }

  /**
   * Returns a siteId that matches the given `location` area string. Returns null if a site is not found.
   * @param location the location area string
   * @param ignoredSegmentCount
   * @returns
   */
  private getSiteIdFromSegments(location: string, ignoredSegmentCount: number): string {
    const locationSegments: string[] = location.toLocaleLowerCase().split(this._emptySpaceReplacement);
    const locationSegmentsLength = locationSegments.length; // locationSegments.length is changed by the line below, so pull it out first
    const town: string = locationSegments.splice(0, locationSegmentsLength - ignoredSegmentCount).join(this._emptySpaceReplacement);
    const sites: Site[] = this.siteService.sites.value?.filter((x: Site) => town === x.Address.Town.toLocaleLowerCase());

    let value: string;

    if (sites.length === 1) {
      value = sites[0].Id;
    } else if (sites.length > 1) {
      value = this.handleMultipleMatchedSites(locationSegments.join(this._space), sites);
    } else if (ignoredSegmentCount <= locationSegmentsLength) {
      value = this.getSiteIdFromSegments(location, ignoredSegmentCount + 1);
    } else {
      value = null;
    }
    return value;
  }

  /**
   * Uses as the length of the given `postcodeSegment` string to find the site with the matching postcode.
   * @param postcodeSegment
   * @param sites
   * @returns
   */
  private handleMultipleMatchedSites(postcodeSegment: string, sites: Site[]): string {
    const _site = sites.find((x) => x.Address.Postcode.substring(0, postcodeSegment.length).toLocaleLowerCase() === postcodeSegment.toLocaleLowerCase());
    return _site ? _site.Id : null;
  }

  /**
   * Replaces the spacing in a string with a valid separator for the URI
   * @public
   * @param {string} input
   * @return {*}  {string}
   * @memberof NavigatorService
   */
  private replaceSpacing(input: string): string {
    let result: string = null;

    if (input.includes(this._space)) {
      result = input.split(this._space).join(this._emptySpaceReplacement);
    } else {
      result = input;
    }

    return result;
  }

  /**
   * Cleans the special chars. When updating this method be sure to update
   * the SiteMapController.cs page as well.
   * @private
   * @param {string} path
   * @return {*}  {string}
   * @memberof NavigatorService
   */
  private cleanSpecialCharacters(path: string): string {
    const removeIndex: number = 1;

    path = path.split('&').join('And');
    path = path.split('(').join('');
    path = path.split(')').join('');
    path = path.split('!').join('');
    path = path.split('*').join('');
    path = path.split('#').join('');
    path = path.split('$').join('');
    path = path.split('@').join('');
    path = path.split('£').join('');
    path = path.split('®').join('');
    path = path.split('™').join('');
    path = path.split('€').join('');
    path = path.split('.').join('');
    path = path.split(',').join('');
    path = path.split(':').join('');
    path = path.split(';').join('');
    path = path.split('--').join('');
    path = path.split('"').join('');
    path = path.split('').filter((char) => char.charCodeAt(0) <= 127).join('');

    path = path.trim();

    if (path.endsWith('-')) {
      path = path.substring(0, path.length - removeIndex);
    }

    if (path.startsWith('-')) {
      path = path.substring(removeIndex);
    }

    return encodeURIComponent(path);
  }

  /**
   * tracks the current route.
   * @param url the current url
   */
  private trackCurrentRoute(): void {
    this.setCurrentRouteStatus(this.router.url);

    if (!this.router || !this.router.events) {
      return;
    }

    this.router.events
        .pipe(filter((event: Event | RouterEvent) => event instanceof NavigationEnd))
        .pipe(takeUntil(this.destroy$))
        .subscribe((event: RouterEvent) => {
          this.setCurrentRouteStatus(event.url);
        });
  }

  /**
   * sets the current route status.
   * @param url the current url
   */
  private setCurrentRouteStatus(url: string): void {
    if (!url) {
      return;
    }

    const isProfilePage = url.startsWith('/profile');
    const reg: RegExp = /\/menu\/\w+\//g;
    const isMenuPage = url.toLowerCase().includes('/menu') && !reg.test(url.toLowerCase());
    const isCheckoutPage = url.startsWith('/checkout');
    const isLocationsPage = url.startsWith('/locations');
    const isHomePage = url === '/';
    const isAboutUsPage = url.startsWith('/about-us');

    const payload = {
      isAboutUsPage,
      isCheckoutPage,
      isHomePage,
      isLocationsPage,
      isMenuPage,
      isProfilePage,
    };

    this._status.next(payload);
  }
}
