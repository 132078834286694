// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group.postcode-search .button-action-1.mat-mdc-raised-button {
  border-radius: 6px !important;
  height: 42px;
}

.postcode-search {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.postcode-search input {
  margin: 0 5px 0 0;
  width: unset;
  border-radius: 6px !important;
  flex: 1;
}
.postcode-search select {
  border: 1px solid #ced4da;
  cursor: pointer;
}

.postcode-select {
  margin: 5px 0 -5px;
  width: 100%;
}

/* hide arrows on number inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.no-addresses-found {
  padding: 10px;
  text-align: center;
  background-color: #DBDFE6;
  margin-bottom: 10px;
  border-radius: 5px;
}

.login-action {
  display: flex;
  text-align: center;
  padding: 5px 0;
}

.login-action button {
  width: calc(100% - 4px);
  margin: 0 2px;
}

.add-address-form.with-padding {
  padding: 5px 10px;
}

.add-address-form .androweb-form-group input {
  width: calc(100% - 36px);
  margin-left: -4px !important;
}

.sticky-footer {
  overflow: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
