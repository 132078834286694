/* eslint-disable sort-keys */
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { NgModule } from '@angular/core';
import { ShellComponent } from '@app/shell/shell.component';
import { isTenantReadyGuard } from '@app/guards/is-tenant-ready/is-tenant-ready.provider';
import { isLoggedInGuard } from '@app/guards/is-logged-in/is-logged-in.provider';

const routes: Routes =
[
  {
    path: '',
    canActivateChild: [isTenantReadyGuard],
    component: ShellComponent,
    children: [
      {
        loadChildren: () => import('@app/modules/home/home.module').then((m) => m.HomeModule),
        path: ''
      },
      {
        canActivate: [isLoggedInGuard],
        loadChildren: () => import('@app/modules/checkout/checkout.module').then((m) => m.CheckoutModule),
        path: 'checkout/:basketId'
      },
      {
        loadChildren: () => import('@app/modules/locations/locations.module').then((m) => m.LocationsModule),
        path: 'locations'
      },
      {
        loadChildren: () => import('@app/modules/menu/menu.module').then((m) => m.MenuModule),
        path: ':location/:occasion/menu'
      },
      {
        canActivate: [isLoggedInGuard],
        loadChildren: () => import('@app/modules/profile/profile.module').then((m) => m.ProfileModule),
        path: 'profile'
      },
      {
        loadChildren: () => import('@app/modules/custom-sections/custom-sections.module').then((m) => m.CustomSectionsModule),
        path: 'sections/:section'
      },
      {
        path: 'sitemap',
        loadChildren: () => import('@app/modules/sitemap/sitemap.module').then((m) => m.SitemapModule)
      },
      {
        loadChildren: () => import('@app/modules/terms-and-conditions/terms-and-conditions.module').then((m) => m.TermsAndConditionsModule),
        path: 'terms-and-conditions'
      }
    ]
  },
  {
    canActivateChild: [isTenantReadyGuard],
    loadChildren: () => import('@app/modules/table-number/table-number.module').then((m) => m.TableNumberModule),
    path: 'dine-in'
  },
  {
    path: '**',
    redirectTo: ''
  }
];

const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() { }
}
