import { MenuGroup } from '@app/models/menu-group';
import { ProductOptionGroup } from '@app/models/product-option-gGroup';
import { DisplayDeal } from '@app/models/display-deal';
import { DisplayMenuGroupItem } from '@app/models/display-menu-group-item';
import { DisplayAllergenDetails } from '@app/models/display-allergen-details';
import { DisplayProduct } from '@app/models/display-product';
import { DisplayMenuNutrition } from './display-menu-nutrition';

export class DisplayMenuGroup {
  Name: string;
  CustomerName?: string;
  CustomerDescription?: string;
  ImageUrl?: string;
  Groups: Array<DisplayMenuGroup>;
  Products: DisplayMenuGroupItem[];
  ProductOptionGroups: ProductOptionGroup[];
  AllergenDetails: DisplayAllergenDetails;
  Hidden: boolean;
  Nutrition: DisplayMenuNutrition;

  public static FromApiMenuGroups(apiMenuGroups: MenuGroup[], products: DisplayProduct[], deals: DisplayDeal[]): Array<DisplayMenuGroup> {
    if (!apiMenuGroups) {
      return [];
    }
    const menuGroups: DisplayMenuGroup[] = apiMenuGroups.map((x) => DisplayMenuGroup.FromApiMenuGroup(x, products, deals));

    menuGroups.forEach((group: DisplayMenuGroup) => {
      group.ProductOptionGroups = menuGroups[0].ProductOptionGroups;
    });

    return menuGroups;
  }

  public static FromApiMenuGroup(apiMenuGroup: MenuGroup, products: DisplayProduct[], deals: DisplayDeal[]): DisplayMenuGroup {
    const group = new DisplayMenuGroup();
    group.Name = apiMenuGroup.Name;
    group.CustomerName = apiMenuGroup.CustomerName;
    group.CustomerDescription = apiMenuGroup.CustomerDescription;
    group.ImageUrl = apiMenuGroup.ImageUrl;
    group.AllergenDetails = apiMenuGroup.AllergenDetails;
    group.Groups = DisplayMenuGroup.FromApiMenuGroups(apiMenuGroup.Groups, products, deals);
    group.Products = DisplayMenuGroup.getProductsOrDeals(apiMenuGroup, products, deals);
    group.ProductOptionGroups = apiMenuGroup.ProductOptionGroups;
    group.Hidden = apiMenuGroup.Hidden;

    const isBottomGroup: boolean = group.Groups.every((x) => (!x.Groups || x.Groups.length === 0) && x.Products.length > 0);

    if (isBottomGroup) {
      const groupNutrition = group.Groups.flatMap((x) => x.Products.flatMap((t) => t.Nutrition).filter((y) => y));

      if (groupNutrition.length > 0) {
        const calories = groupNutrition.map((x) => x.Calories).sort((a, b) => a - b);
        const numberOfPortions = groupNutrition.map((x) => x.NumberOfPortions).sort((a, b) => a - b);

        group.Nutrition = {
          Calories: `${calories[0]} - ${calories[calories.length - 1]} kcal`,
          NumberOfPortions: `${numberOfPortions[0]} - ${numberOfPortions[numberOfPortions.length - 1]}`,
          isRange: true
        };
      } else if (group.Groups.length === 0 && group.Products.length > 0) {
        const nut = group.Products.map((x) => x.Nutrition).filter((x) => x);
        const calories = nut.map((x) => x.Calories).sort((a, b) => a - b);
        const numberOfPortions = nut.map((x) => x.NumberOfPortions).sort((a, b) => a - b);

        if (calories.length > 0 && numberOfPortions.length > 0) {
          group.Nutrition = {
            Calories: `${calories[0]} - ${calories[calories.length - 1]} kcal`,
            NumberOfPortions: `${numberOfPortions[0]} - ${numberOfPortions[numberOfPortions.length - 1]}`,
            isRange: true
          };
        }
      }
    }

    return group;
  }

  public static getProductsOrDeals(apiMenuGroup: MenuGroup, products: DisplayProduct[], deals: DisplayDeal[]): DisplayMenuGroupItem[] {
    if (!apiMenuGroup.Products) {
      return [];
    }

    return apiMenuGroup.Products
        .map((productId: string) => {
          const findItem = <T>(items: T[]) => items.find((x) => x['Id'] === productId);
          const displayProduct: DisplayProduct = findItem<DisplayProduct>(products);
          let menuGroupItem: DisplayMenuGroupItem;

          if (displayProduct) {
            menuGroupItem = DisplayProduct.productToMenuGroupItem(displayProduct);
          } else {
            const deal: DisplayDeal = findItem<DisplayDeal>(deals);

            if (deal) {
              menuGroupItem = DisplayMenuGroupItem.dealToMenuGroupItem(deal);
            }
          }

          return menuGroupItem;
        })
        .filter((product: DisplayMenuGroupItem) => product);
  }
}
