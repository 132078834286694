/**
 * The raw data of a modifier
 * @export
 * @class QuantityOf
 */
export class QuantityOf {
    Item: string;
    Quantity: number;

    constructor(values: Object = {}) {
      Object.assign(this, values);
    }
}
