import { ActivatedRoute, NavigationEnd, Router, RouterEvent, Event } from '@angular/router';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';

import { AnalyticsService } from '@app/services/analytics.service';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { Basket } from '@app/models/basket';
import { BasketService } from '@app/services/basket.service';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { DealItemResult } from '@app/models/deal-item-result';
import { DisplayAllergenDetails } from '@app/models/display-allergen-details';
import { DisplayDisplayGroup } from '@app/models/display-display-group';
import { DisplayMenu } from '@app/models/display-menu';
import { DisplayMenuGroupItem } from '@app/models/display-menu-group-item';
import { DisplayMenuItem } from '@app/models/display-menu-item';
import { DisplayMenuItemGroup } from '@app/models/display-menu-item-group';
import { DisplayMenuPrice } from '@app/models/display-menu-price';
import { DisplayModifierSection } from '@app/models/display-modifier-section';
import { DisplayModifierSubSection } from '@app/models/display-modifier-sub-section';
import { DisplayOccasionType } from '@app/models/display-occasion-type';
import { DisplayProduct } from '@app/models/display-product';
import { IAllergenGroupString } from '@app/models/IAllergenGroupString';
import { IAllergenItem } from '@app/models/IAllergenItem';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from '@app/services/menu.service';
import { ModifierVariant } from '@app/models/modifier-variant';
import { NavigationSectionType } from '@app/models/navigation/navigation-section-type';
import { NavigatorService } from '@app/core/navigator.service';
import { Product } from '@app/models/Product';
import { ProductModalData } from '@app/models/product/ProductModalData';
import { ProductOptionGroup } from '@app/models/product-option-gGroup';
import { QuantityOf } from '@app/models/quantity-of';
import { SEOService } from '@app/services/seo.service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Site } from '@app/models/site';
import { SiteService } from '@app/services/site.service';
import { AllergenInfoModalDetails } from '@app/models/allergenInfoModalDetails';
import { BehaviorSubject, combineLatest, map, Observable, takeUntil } from 'rxjs';
import { IProductModifiersState } from '@app/models/product/IProductModifiersState';
import { DisplayLineTypes } from '@app/models/display-line-types';
import { ToastTypes } from '@app/models/ToastTypes.enum';
import { BasketItemCreate } from '@app/models/basket/BasketItemCreate';
import { HttpStatusCodeHandler } from '@app/core/http.status.codes';
import { IQuantityOfIn } from '@app/models/basket/IQuantityOfIn';

@Component({
  selector: 'app-product',
  styleUrls: ['./product.component.scss'],
  templateUrl: './product.component.html',
})
export class ProductV2Component extends AndroWebCoreComponent implements OnInit, OnDestroy {
  @Input() isDealModal: boolean;
  @Input() modalData: ProductModalData;
  @Input() public lineType: DisplayLineTypes;
  @Output() allDone = new EventEmitter<DealItemResult>();
  @Output() private close: EventEmitter<null> = new EventEmitter<null>();

  public allergens: IAllergenGroupString = { contained: '', mayContain: '' };
  public selectedForcedModifiers: { [id: string]: string } = {};
  public isLoading: boolean = true;
  public displaySubGroups: boolean;
  public displaySubGroupTwo: boolean;
  public selectedProduct: DisplayProduct;
  public selectedMenuItem: DisplayMenuItem;
  public selectedSubGroup1: DisplayMenuItemGroup;
  public selectedSubGroup2: DisplayMenuItemGroup;
  public addedModifiers: QuantityOf[] = [];
  public defaultModifiers: QuantityOf[] = [];
  public displayedOptions2: DisplayMenuItemGroup[] = [];
  public selectedSubSections: DisplayModifierSubSection[] = [];
  public location: string;
  public orderOccasion: DisplayOccasionType;
  public NavigationSectionType = NavigationSectionType;
  public selectedMenuGroups: DisplayDisplayGroup[];
  public displayGroup: string[];
  public allergenInfoModalDetails: AllergenInfoModalDetails= { customerName: '', description: '', name: '', };
  public availableModifiers: DisplayProduct[] = [];
  public productOptions: ProductOptionGroup[] = [];
  public enabledOptions: { [id: string]: boolean } = {};
  public productPrice$: Observable<number>;
  public modifierPrice$: Observable<number>;
  public option2PricePremiums: { [id: string]: number } = {};
  public displayLineTypes: typeof DisplayLineTypes = DisplayLineTypes;
  public quantity$: Observable<number>;

  private offerNoModifierOnForced: { [id: string]: boolean } = {};
  private siteId: string;
  private currentSite: Site;
  private seoSet: boolean;
  private useProductId: boolean;
  private menu: DisplayMenu;
  private currentBasket: Basket;
  private knownAllergens: { [id: string]: string } = {};
  private selectedModifiers: { [id: string]: DisplayProduct } = {};
  private _variantPrice: BehaviorSubject<number>;
  private _modifiersPrice: BehaviorSubject<number>;
  private _quantitySubject = new BehaviorSubject<number>(1);

  constructor(
    public navigatorService: NavigatorService,
    private router: Router,
    private dialog: MatDialog,
    private seoService: SEOService,
    private menuService: MenuService,
    private siteService: SiteService,
    private basketService: BasketService,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private configurationService: ConfigurationService
  ) {
    super();
    this._variantPrice = new BehaviorSubject<number>(0);
    this._modifiersPrice = new BehaviorSubject<number>(0);
    this.modifierPrice$ = this._modifiersPrice.asObservable();
    this._quantitySubject = new BehaviorSubject<number>(1);
    this.quantity$ = this._quantitySubject.asObservable();

    this.siteService.currentSite
        .pipe(takeUntil(this.destroy$))
        .subscribe((currentSite: Site) => this.currentSite = currentSite);

    this.productPrice$ = combineLatest([this._variantPrice.asObservable(), this.modifierPrice$, this.quantity$])
        .pipe(map(([variant, modifier, quantity]: number[]) => quantity * (variant + modifier)));
  }

  ngOnInit() {
    this.knownAllergens = this.configurationService.getTenantAllergenMap();
    this.loadSiteIdOrderOccasionAndDisplayGroup();
    this.setupProduct();

    this.router.events
        .pipe(takeUntil(this.destroy$))
        .subscribe((event: Event | RouterEvent) => {
          if (event instanceof NavigationEnd) {
            this.setupProduct();
          }
        });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.seoSet) {
      this.seoService.restore();
    }
  }

  /**
  * adds a modifier to a product
  * @param product - the modifier being added
  */
  public addModifier(product: DisplayProduct): void {
    let totalMods = 0;

    this.addedModifiers.forEach((mod: QuantityOf) => totalMods += mod.Quantity);
    this.defaultModifiers.forEach((mod: QuantityOf) => totalMods += mod.Quantity);

    if (totalMods >= this.selectedProduct.Modifiers.Max) {
      this.showToast(ToastTypes.info, `Only ${this.selectedProduct.Modifiers.Max} modifiers can be added to this product`, 'Sorry!');
      return;
    }

    let modQuantity = 0;
    const foundMod = this.addedModifiers.find((modifier: QuantityOf) => modifier.Item === product.Id);
    const defaultMod = this.defaultModifiers.find((modifier: QuantityOf) => modifier.Item === product.Id);

    if (foundMod) {
      modQuantity += foundMod.Quantity;

      if (defaultMod) {
        modQuantity += defaultMod.Quantity;
      }
    } else if (!foundMod && defaultMod) {
      modQuantity += defaultMod.Quantity;
    }

    if (foundMod || defaultMod) {
      if (modQuantity + 1 > this.selectedProduct.Modifiers.MaxEach) {
        this.showToast(
            ToastTypes.info,
            `Only ${this.selectedProduct.Modifiers.MaxEach} ${product.CustomerName ? product.CustomerName : product.Name} can be added to this product`,
            'Sorry!'
        );
        return;
      }
    }

    const defaultModifier = this.selectedProduct.Modifiers.Default.find((_product: DisplayProduct) => _product.Id === product.Id);

    if (defaultModifier) {
      this.increaseDefaultModifierQuantity({ Item: product.Id, Quantity: 1 }, product);
    } else {
      this.addOptionalModifier(product);
    }

    this.calculateModifiersPrice();
  }

  /**
   * Updates the `_modifiersPrice` behavior subject with the cost of any modifiers changes.
   */
  private calculateModifiersPrice(): void {
    const mods: IProductModifiersState = this.getModifiersState();
    const selectedPrices: number[] = this.selectedProduct.Modifiers.Default
        .filter((x: DisplayProduct) => !mods.removed.some((y: QuantityOf) => y.Item === x.Id))
        .map((x: DisplayProduct) => (x.Prices.find((y: DisplayMenuPrice) => y.Occasion === this.orderOccasion).Amount));

    mods.added
        .forEach((x: QuantityOf) => {
          let price: DisplayMenuPrice;

          if (this.selectedModifiers[x.Item]) {
            price = this.selectedModifiers[x.Item].Prices
                .find((y: DisplayMenuPrice) => y.Occasion === this.orderOccasion);
          } else {
            this.selectedSubSections.forEach((y: DisplayModifierSubSection) => {
              const product: DisplayProduct = y.AllModifiers.find((z: DisplayProduct) => z.Id === x.Item);

              if (product) {
                price = product.Prices.find((w: DisplayMenuPrice) => w.Occasion === this.orderOccasion);
              }
            });
          }

          for (let i = 1; i <= x.Quantity; i++) {
            selectedPrices.push(price.Amount);
          }
        });

    if (this.selectedProduct.Modifiers.Default.length >= this.selectedProduct.Modifiers.MaxFree) {
      const selectedTotal: number = selectedPrices.length >= this.selectedProduct.Modifiers.MaxFree ?
          selectedPrices.reduce((p: number, c: number) => p + c, 0) :
          0;

      const ogCost: number = this.selectedProduct.Modifiers.Default
          .map((x: DisplayProduct) => x.Prices.find((y: DisplayMenuPrice) => y.Occasion === this.orderOccasion).Amount)
          .reduce((p: number, c: number) => p + c, 0);

      const total: number = selectedTotal - ogCost;
      this._modifiersPrice.next(total > 0 ? total : 0);
    } else {
      let selectedTotal: number = 0;

      if (selectedPrices.length >= this.selectedProduct.Modifiers.MaxFree) {
        selectedTotal = selectedPrices.sort((a: number, b: number) => a - b)
            .splice(selectedPrices.length - (selectedPrices.length - this.selectedProduct.Modifiers.MaxFree), selectedPrices.length)
            .reduce((p: number, c: number) => p + c, 0);
      }

      this._modifiersPrice.next(selectedTotal);
    }
  }

  /**
  * returns the amount of times a given modifier is applied to the current product
  * @param modifier - the display modifier
  */
  public getModifierQuantity(modifier: DisplayProduct): number {
    const foundMod = this.addedModifiers.find((_modifier: QuantityOf) => _modifier.Item === modifier.Id);
    const defaultMod = this.defaultModifiers.find((_modifier: QuantityOf) => _modifier.Item === modifier.Id);

    if (foundMod && defaultMod) {
      return foundMod.Quantity + 1;
    } else if (!foundMod && defaultMod) {
      return defaultMod.Quantity;
    } else if (foundMod) {
      return foundMod.Quantity;
    } else {
      return 0;
    }
  }

  /**
   * increases the quantity of the product that should be added to the basket.
   */
  public incrementQuantity(): void {
    this._quantitySubject.next(this._quantitySubject.value + 1);
  }

  /**
   * decreases the quantity of the product that should be added to the basket.
   */
  public decrementQuantity(): void {
    const currentValue = this._quantitySubject.value;
    if (currentValue > 1) {
      this._quantitySubject.next(currentValue - 1);
    }
  }

  /**
   * Cancels the products selection and returns the user to the previous group
   * @memberof ProductV2Component
   */
  public cancelProduct($event: KeyboardEvent | MouseEvent): void {
    $event.preventDefault();
    const routePath: string = this.navigatorService.generateRoutePath(this.location, this.orderOccasion, NavigationSectionType.Menu, this.displayGroup[0]);
    this.router.navigate([routePath]);
    this.close.emit();
  }

  /**
  * adds the product to the basket and reroutes back to the menu page.
  */
  public async addProductToBasket(): Promise<void> {
    this.isLoading = true;

    this.analyticsService.trackAddRemoveProductToBasket(
        true,
        this.selectedProduct.Name,
        this.selectedProduct.Id,
        this.displayGroup[0],
        this.selectedProduct.Prices?.find((item: DisplayMenuPrice) => item.Occasion === this.orderOccasion)?.Amount,
        'GBP'
    );

    const mods: IProductModifiersState = this.getModifiersState();
    const payload: BasketItemCreate = {
      Modifiers: [
        ...mods.added,
        ...this.selectedProduct.Modifiers.Default
            .filter((e: DisplayProduct) => !mods.removed.some((x: IQuantityOfIn) => x.Item === e.Id))
            .map((e: DisplayProduct) => ({ Item: e.Id, Quantity: 1 }))
      ],
      Product: {
        Item: this.selectedProduct.Id,
        Quantity: this._quantitySubject.value
      }
    };

    if (HttpStatusCodeHandler.isSuccessResponse(await this.basketService.addProductToBasket(payload, this.selectedProduct.Name))) {
      this.close.emit();
      this.router.navigate([this.navigatorService.generateRoutePath(this.location, this.orderOccasion, NavigationSectionType.Menu, this.displayGroup[0])]);
    }

    this.isLoading = false;
  }

  /**
  * adds the product to the deal and triggers the all done event that closes the product modal
  */
  public addProductToDeal(): void {
    this.isLoading = true;

    const mods: IProductModifiersState = this.getModifiersState();

    DisplayProduct.CheckModifiersForProduct(this.selectedProduct, mods.removed, mods.added);

    const result: DealItemResult = {
      addedToppings: mods.added,
      product: this.selectedProduct,
      removedToppings: mods.removed
    };

    this.allDone.emit(result);
    this.isLoading = false;
  }

  /**
  * returns the price for the current occasion of a given product
  * @param product - the product
  */
  public getPriceForDisplayProduct(product: DisplayProduct): number {
    return product.Prices?.find((price: DisplayMenuPrice) => price.Occasion === (this.currentBasket.Occasion as unknown as DisplayOccasionType))?.Amount;
  }

  /**
  * determines whether the added modifier is a default modifier or an optional modifier then calls the relevant function to decrease it's quantity
  * @param product - the added modifier
  */
  public removeModifier(product: DisplayProduct): void {
    const defaultModifier = this.selectedProduct.Modifiers.Default.find((_product: DisplayProduct) => _product.Id === product.Id);

    if (defaultModifier) {
      this.removeDefaultModifier(this.productToModifier(product));
    } else {
      this.removeOptionalModifier(this.productToModifier(product));
    }

    this.calculateModifiersPrice();
  }

  /**
  * decreases the quantity of a default modifier on the current product
  * @param modifier - the modifier to decrement
  */
  public removeDefaultModifier(modifier: QuantityOf): void {
    const selectedModifier = this.defaultModifiers.find((mod: QuantityOf) => mod.Item === modifier.Item);

    if (selectedModifier?.Quantity > 0) {
      selectedModifier.Quantity--;
    }
  }

  /**
  * decreases the quantity of a optional modifier on the current product
  * @param modifier - the modifier to decrement
  */
  public removeOptionalModifier(modifier: QuantityOf): void {
    const foundMods = this.addedModifiers.filter((mod: QuantityOf) => mod.Item === modifier.Item);

    if (foundMods?.length > 0) {
      foundMods[0].Quantity--;

      if (foundMods[0].Quantity === 0) {
        this.addedModifiers = this.addedModifiers.filter((mod: QuantityOf) => mod.Item !== modifier.Item);
      }
    }
  }

  /**
  * selects the first variant ('option 1') and enables all compatible second variants (if available) and selected product
  * @param group - the menu item group ('an option 1') to be selected
  */
  public selectSubGroup1Option(group: DisplayMenuItemGroup): void {
    this.selectedSubGroup1 = group;
    this.enabledOptions = {};
    this.option2PricePremiums = {};

    if (group.SubGroups.length === 0 && group.Products.length === 1) {
      this.setSelectedProduct(group.Products[0]);
      this.displaySubGroupTwo = false;
    } else if (group.SubGroups.length > 0) {
      this.displaySubGroupTwo = true;

      this.setOption2PricePremiums();

      this.selectedMenuItem.SubGroup.forEach((subGroup: DisplayMenuItemGroup) => {
        subGroup.SubGroups.forEach((_subgroup: DisplayMenuItemGroup) => {
          if (this.displayedOptions2.findIndex((option: DisplayMenuItemGroup) => option.GroupName === _subgroup.GroupName) === -1) {
            this.displayedOptions2.push(_subgroup);
          }
        });
      });

      group.SubGroups.forEach((subgroup: DisplayMenuItemGroup) => {
        this.enabledOptions[subgroup.GroupName] = subgroup.Products.length > 0;
      });

      if (this.selectedSubGroup2) {
        const groupIndex = group.SubGroups.findIndex((_group: DisplayMenuItemGroup) => _group.GroupName === this.selectedSubGroup2.GroupName && _group.Products.length > 0);

        if (groupIndex > -1) {
          this.selectSubGroup2Option(group.SubGroups[groupIndex]);
          return;
        }
      }

      const groupIndex = group.SubGroups.findIndex((_group: DisplayMenuItemGroup) => _group.Products.length > 0);

      if (groupIndex > -1) {
        this.selectSubGroup2Option(group.SubGroups[groupIndex]);
        return;
      }
    }
  }

  /**
  * selects the second variant ('option 2') and sets the first found product as the selected product
  * @param group - the menu item group ('an option 2') to be selected
  */
  public selectSubGroup2Option(group: DisplayMenuItemGroup): void {
    if (group && this.enabledOptions[group.GroupName]) {
      this.selectedSubGroup2 = this.selectedSubGroup1.SubGroups.find((_group: DisplayMenuItemGroup) => _group.GroupName === group.GroupName);

      if (this.selectedSubGroup2.SubGroups.length === 0 && this.selectedSubGroup2.Products.length === 1) {
        this.setSelectedProduct(this.selectedSubGroup2.Products[0]);
      }
    }
  }

  /**
  * emits an event that triggers the closure of product modal
  */
  public closeModal($event: KeyboardEvent | MouseEvent): void {
    $event.preventDefault();
    this.allDone.emit(null);
  }

  /**
  * selects a forced modifier within a modifier group and removes the previously selected modifier if was a default modifier of the currently selected product
  * @param subSection: the modifier group
  * @param modifierId: the modifier id to be selected
  */
  public selectForcedModifier(subSection: DisplayModifierSubSection, modifierId: string): void {
    if (!subSection.MutuallyExclusive) {
      return;
    }

    if (modifierId !== this.selectedForcedModifiers[subSection.Id]) {
      const defaultModifier: QuantityOf = this.defaultModifiers.find((mod: QuantityOf) => mod.Item === this.selectedForcedModifiers[subSection.Id]);

      if (defaultModifier) {
        this.removeDefaultModifier({ Item: this.selectedForcedModifiers[subSection.Id], Quantity: defaultModifier.Quantity });
      }
    }

    this.selectedForcedModifiers[subSection.Id] = modifierId;
  }

  /**
  * returns a string that can be used by the background-image css property
  * @param value: the image url
  */
  public getBackgroundImageUrl(value: string): string {
    return `url(${value})`;
  }

  /**
  * determines whether the info icon should be displayed on a given product
  * @param product: the product
  */
  public showInfoIcon(product: DisplayProduct): boolean {
    return product.Description ? true : DisplayProduct.hasAllergens(product);
  }

  /**
  * determines whether the info icon should be displayed on a given option
  * @param group: the menu item group
  */
  public showOptionInfoIcon(group: DisplayMenuItemGroup) {
    return DisplayProduct.hasOptionAllergens(group);
  }

  /**
  * maps allergen string to known allergens and returns unmapped allergen to caller and logs to appinsights on error
  * @param unMappedAllergen: allergen to be mapped
  */
  public mapAllergenToKnownAllergen(unMappedAllergen: string): string {
    const mappedAllergen = this.knownAllergens[unMappedAllergen];
    if (mappedAllergen) {
      return mappedAllergen.replace('cereals-', '');
    }
    this.trackException(`Unknown Allergen: ${ unMappedAllergen }`, false);
    return unMappedAllergen;
  }

  /**
  * opens up the allergens info template in a mat dialog and displays the allergens on a given product
  * @param name: name
  * @param customerName: customerName
  * @param description: description
  * @param allergenDetails: allergen details
  * @param allergensInfoModal: the allergens info modal
  */
  public showAllergenDetails(name: string, customerName: string, description: string, allergenDetails: DisplayAllergenDetails, allergensInfoModal: TemplateRef<any>): void {
    if (this.dialog.getDialogById('allergensInfoModal')) {
      return;
    }

    this.allergenInfoModalDetails.name = name;
    this.allergenInfoModalDetails.customerName = customerName;
    this.allergenInfoModalDetails.description = description;
    this.allergenInfoModalDetails.allergens = { contains: [], mayContain: [] };

    allergenDetails?.Contains.forEach((element: string) => {
      this.allergenInfoModalDetails.allergens.contains.push({allergen: this.mapAllergenToKnownAllergen(element), id: element, type: 'red'});
    });

    allergenDetails?.MayContain.forEach((element: string) => {
      this.allergenInfoModalDetails.allergens.mayContain.push({allergen: this.mapAllergenToKnownAllergen(element), id: element, type: 'amber'});
    });

    const dialogRef = this.dialog.open(allergensInfoModal, {
      id: 'allergensInfoModal',
      maxWidth: '400px',
      panelClass: 'allergens-info-modal',
    });

    dialogRef.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.allergenInfoModalDetails.allergens = { contains: [], mayContain: [] };
          this.allergenInfoModalDetails.name = '';
          this.allergenInfoModalDetails.customerName = '';
          this.allergenInfoModalDetails.description = '';
        });
  }

  /**
  * returns the given allergens icon path
  * @param allergen: the allergen
  */
  public getAllergenImage(allergen: IAllergenItem): string {
    let allergenId : string;
    switch (allergen.id) {
      case 'mil':
        allergenId = 'dairy';
        break;
      case 'cer':
      case 'cer-bar':
      case 'cer-kam':
      case 'cer-oat':
      case 'cer-ogf':
      case 'cer-rye':
      case 'cer-spe':
      case 'cer-whe':
        allergenId = 'glu';
        break;
      default:
        allergenId = allergen.id;
    }

    return `assets/allergens/${allergenId}/${allergenId}_${allergen.type}_100x100.png`;
  }

  /**
  * returns whether the given modifier within a modifier group is selected
  * @param subSection: the modifier group
  * @param subSection: the modifier
  */
  public isModifierSelected(subSection: DisplayModifierSubSection, modifier: DisplayProduct): boolean {
    return subSection.MutuallyExclusive ? this.selectedForcedModifiers[subSection.Id] === modifier.Id : this.getModifierQuantity(modifier) > 0;
  }

  /**
   * Returns wether the add to cart/deal button is disabled.
   */
  public shouldDisableAddToButton(): boolean {
    let hasNoSelection: boolean = false;

    this.selectedSubSections
        .filter((x: DisplayModifierSubSection) => x.MutuallyExclusive)
        .forEach((x: DisplayModifierSubSection) => {
          const modifierIds: string[] = x.Modifiers.map((y: DisplayProduct) => y.Id);

          if (!this.selectedForcedModifiers[x.Id] || !modifierIds.includes(this.selectedForcedModifiers[x.Id])) {
            hasNoSelection = true;
          }
        });

    return hasNoSelection ||
      (this.selectedMenuItem && (!this.selectedMenuItem.ValidForBasket || !!this.selectedMenuItem.OutOfStock)) ||
      (!this.selectedMenuItem && this.selectedProduct && (!this.selectedProduct.ValidForBasket || !!this.selectedProduct.OutOfStock));
  }

  /**
   * updates the option2PricePremiums dictionary for any group that has a product with a DealPricePremium.
   */
  private setOption2PricePremiums(): void {
    const subGroups: DisplayMenuItemGroup[] = this.selectedMenuItem.SubGroup
        .filter((x: DisplayMenuItemGroup) => x.GroupName === this.selectedSubGroup1.GroupName)
        .map((x: DisplayMenuItemGroup) => x.SubGroups)
        .flat();

    for (const _group of subGroups) {
      const dealPricePremium: number = _group.Products[0]?.DealPricePremium;

      if (dealPricePremium > 0) {
        this.option2PricePremiums[_group.GroupName] = dealPricePremium;
      }
    }
  }

  /**
   * returns the the current selection of modifiers in two arrays being 'added' for added modifiers and 'removed' for removed modifiers.
   */
  private getModifiersState(): { added: QuantityOf[], removed: QuantityOf[] } {
    const removedModifiers = JSON.parse(JSON.stringify(this.defaultModifiers)).filter((mod: QuantityOf) => mod.Quantity === 0);
    const additionalDefaults = JSON.parse(JSON.stringify(this.defaultModifiers)).filter((mod: QuantityOf) => mod.Quantity > 1);

    const addedModifiers: QuantityOf[] = JSON.parse(JSON.stringify(this.addedModifiers));

    additionalDefaults.forEach((modifier: QuantityOf) => {
      modifier.Quantity--;
      addedModifiers.push(modifier);
    });

    if (this.selectedProduct.Modifiers.Default) {
      const defaultIds: string[] = this.selectedProduct.Modifiers.Default.map((x: DisplayProduct) => x.Id);

      this.selectedSubSections
          .filter((x: DisplayModifierSubSection) => this.selectedForcedModifiers[x.Id] && !defaultIds.includes(this.selectedForcedModifiers[x.Id]))
          .forEach((section: DisplayModifierSubSection) => {
            const index: number = addedModifiers.findIndex((x: QuantityOf) => x.Item === this.selectedForcedModifiers[section.Id]);

            if (index > -1) {
              addedModifiers[index] = { Item: this.selectedForcedModifiers[section.Id], Quantity: 1 };
            } else {
              addedModifiers.push({ Item: this.selectedForcedModifiers[section.Id], Quantity: 1 });
            }
          });
    }

    return { added: addedModifiers, removed: removedModifiers };
  }

  /**
  * Returns all the allergens on the given product
  * @param product - the product
  */
  private getAllergens(product: DisplayProduct): IAllergenGroupString {
    if (!product.AllergenDetails) {
      return { contained: '', mayContain: '' };
    }

    let contained: string[] = [];
    let mayContain: string[] = [];

    product.AllergenDetails.Contains.forEach((productAllergen: string) => {
      const allergen: string = this.mapAllergenToKnownAllergen(productAllergen);
      contained.push(allergen);
    });

    product.AllergenDetails.MayContain.forEach((productAllergen: string) => {
      const allergen: string = this.mapAllergenToKnownAllergen(productAllergen);
      mayContain.push(allergen);
    });

    contained = this.alphabetiseItems(contained);
    mayContain = this.alphabetiseItems(mayContain);

    return {
      contained: contained.join(', '),
      mayContain: mayContain.join(', '),
    };
  }

  /**
  * takes in a string array and returns all display groups that have matching names as those input
  * @param displayGroups - the string array with names to match against all display groups in a menu
  */
  private getAllDisplayGroups(displayGroups: string[]): DisplayDisplayGroup[] {
    const allDisplayGroups = this.menu.GetDisplayGroups(this.orderOccasion);
    const response: DisplayDisplayGroup[] = [];

    for (const displayGroup of displayGroups) {
      const cleanDisplayGroup: string = this.navigatorService.clean(displayGroup);

      allDisplayGroups
          .filter((x: DisplayDisplayGroup) => this.navigatorService.clean(x.CustomerName) === cleanDisplayGroup)
          .forEach((x: DisplayDisplayGroup) => response.push(x));
    }

    return response;
  }

  /**
   * Sets the values of the `siteId`, `orderOccasion`, `location, and `displayGroup` properties.
   * @memberof ProductV2Component
   * @private
   */
  private loadSiteIdOrderOccasionAndDisplayGroup(): void {
    if (this.modalData?.siteId) {
      this.siteId = this.modalData.siteId;
      this.orderOccasion = this.modalData.occasion;
    } else {
      this.location = this.activatedRoute.snapshot.paramMap.get('location');
      this.siteId = this.navigatorService.getSiteIdByLocation(this.location).toLowerCase();

      if (this.activatedRoute.snapshot.paramMap.get('occasion')) {
        const routeOccasion: string = this.navigatorService.changeTakeAwayToCollection(this.activatedRoute.snapshot.paramMap.get('occasion'));
        this.orderOccasion = DisplayOccasionType[this.navigatorService.toTitleCase(routeOccasion)];
      }
    }

    if (this.activatedRoute.snapshot.paramMap.get('displayGroup')) {
      this.displayGroup = [this.activatedRoute.snapshot.paramMap.get('displayGroup')];
    }
  }

  /**
  * sets the product name from the current routes query parameters and gets the current basket
  */
  private setupProduct(): void {
    let productName: string;

    if (this.isDealModal) {
      productName = this.modalData.productName;
      this.displayGroup = this.modalData.displayGroups?.length > 0 ? [this.navigatorService.clean(this.modalData.displayGroups[0].CustomerName)] : [''];
    } else {
      const productId = this.activatedRoute.snapshot.paramMap.get('productId');

      if (productId) {
        productName = productId;
        this.useProductId = true;
      } else {
        const routeProductName = this.activatedRoute.snapshot.paramMap.get('productName');

        if (routeProductName) {
          productName = routeProductName;
        }
      }
    }

    this.basketService.getCurrentBasketBySiteId(this.siteId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((basket: Basket) => {
          if (basket) {
            this.currentBasket = basket;
            if (this.isDealModal) {
              this.setMenuAndProduct(basket, this.modalData.displayGroups, productName, true);
            } else {
              this.setMenuAndProduct(basket, this.displayGroup, productName);
            }
          } else {
            this.trackTrace('setupProduct: getCurrentBasketBySiteId(): null basket', SeverityLevel.Error);
          }
        });
  }

  /**
  * subscribes to the current menu and if there isn't one gets the menu with the current basket basket and sets the selected display groups and begins loading the selected product.
  * @param basket - the basket used to get the menu
  * @param displayGroups - the display groups that house the product and all it's variants
  * @param productName - the name of the current product
  * @param isDealModal - determines if the current page is a modal (true if in a deal) which will affect how display groups are handled
  */
  private setMenuAndProduct(basket: Basket, displayGroups: string[] | DisplayDisplayGroup[], productName: string, isDealModal?: boolean): void {
    this.menuService.currentMenu$
        .pipe(takeUntil(this.destroy$))
        .subscribe((menu: DisplayMenu) => {
          if (menu) {
            this.menu = menu;

            if (!this.selectedProduct) {
              this.selectedMenuGroups = isDealModal ? displayGroups as DisplayDisplayGroup[] : this.getAllDisplayGroups(displayGroups as string[]);
              this.loadSelectedItem(productName);
            }
          } else {
            this.menuService.getDisplayMenuByBasket(basket);
          }
        });

    const group: DisplayDisplayGroup = this.selectedMenuGroups?.length > 0 ? this.selectedMenuGroups[0] : null;
    this.seoService.updateProductSeoTitleAndDescription(this.currentSite, this.orderOccasion, group, this.selectedProduct);
  }

  /**
  * finds the selected menu item and selects the first 'option 1' item if available or loads the selected product
  * @param productName - the name of the current product
  */
  private loadSelectedItem(productName: string): void {
    if (this.selectedMenuGroups?.length > 0) {
      const allProducts = this.selectedMenuGroups.flatMap((group: DisplayDisplayGroup) => group.Items);
      this.selectedMenuItem = allProducts.find((product: DisplayMenuItem) => this.useProductId ?
        product.AllProductsInGroup.filter((p: DisplayMenuGroupItem) => p.Id === productName).length > 0 :
        product.ProductName === productName);

      if (this.selectedMenuItem) {
        if (this.selectedMenuItem.SubGroup.length === 0 && this.selectedMenuItem.AllProductsInGroup.length === 1) {
          this.displaySubGroups = false;
          this.setSelectedProduct(DisplayProduct.menuGroupItemToProduct(this.selectedMenuItem.AllProductsInGroup[0]));
        } else if (this.selectedMenuItem.SubGroup.length > 0) {
          this.displaySubGroups = true;
          this.productOptions = this.selectedMenuItem.SubGroup[0].ProductOptionGroups ? this.selectedMenuItem.SubGroup[0].ProductOptionGroups : [];
          this.selectSubGroup1Option(this.selectedMenuItem.SubGroup[0]);
        } else {
          this.loadSingleProduct(productName);
        }
      } else {
        if (this.isDealModal) {
          this.closeModal(new KeyboardEvent('keydown'));
        } else {
          const routePath: string = this.navigatorService.generateRoutePath(this.location, this.orderOccasion, NavigationSectionType.Menu, this.displayGroup[0]);
          this.router.navigate([routePath]);
        }

        this.showToast(ToastTypes.error, 'Something\'s gone wrong');
      }
    } else if (!this.selectedMenuGroups || this.selectedMenuGroups.length === 0 && productName) {
      this.loadSingleProduct(productName);
    }
  }

  /**
  * finds the selected product from the menu when there are no display groups found with the product. If it's not found reroutes the user to the menu page
  * @param productName - the name of the product
  */
  private loadSingleProduct(productName: string): void {
    let product: DisplayProduct;

    if (this.useProductId) {
      product = this.menu.Products.find((p: DisplayProduct) => p.Id === productName);
    } else {
      product = this.menu.Products.find((p: DisplayProduct) => p.Name === productName);
    }

    if (product) {
      this.setSelectedProduct(product);
    } else {
      if (this.isDealModal) {
        this.closeModal(new KeyboardEvent('keydown'));
      } else {
        const routePath: string = this.navigatorService.generateRoutePath(this.location, this.orderOccasion, NavigationSectionType.Menu, this.displayGroup[0]);
        this.router.navigate([routePath]);
      }

      this.showToast(ToastTypes.error, 'Something\'s gone wrong');
    }
  }

  /**
  * increases the quantity of a default modifier
  * @param modifier - the modifier being incremented
  * @param product - the display modifier
  */
  private increaseDefaultModifierQuantity(modifier: QuantityOf, product?: DisplayProduct): void {
    const selectedModifier = this.defaultModifiers.find((mod: QuantityOf) => mod.Item === modifier.Item);

    if (selectedModifier) {
      selectedModifier.Quantity += 1;

      if (product) {
        this.selectedModifiers[product.Id] = product;
      }
    }
  }

  /**
  * adds an optional modifier to the product
  * @param product - the display modifier
  */
  private addOptionalModifier(product: DisplayProduct): void {
    const foundMods = this.addedModifiers.filter((modifier: QuantityOf) => modifier.Item === product.Id);
    const defaultMods = this.defaultModifiers.filter((modifier: QuantityOf) => modifier.Item === product.Id);

    let modQuantity = 0;

    if (foundMods.length > 0 && defaultMods.length > 0) {
      foundMods.forEach((mod: QuantityOf) => modQuantity += mod.Quantity);
      defaultMods.forEach((mod: QuantityOf) => modQuantity += mod.Quantity);

      if (modQuantity + 1 <= this.selectedProduct.Modifiers.MaxEach) {
        foundMods[0].Quantity++;
      }
    } else if (foundMods?.length > 0 && (!defaultMods || defaultMods.length === 0)) {
      foundMods.forEach((mod: QuantityOf) => modQuantity += mod.Quantity);

      if (modQuantity + 1 <= this.selectedProduct.Modifiers.MaxEach) {
        foundMods[0].Quantity++;
      }
    } else if ((!foundMods || foundMods.length === 0) && defaultMods?.length > 0) {
      defaultMods.forEach((mod: QuantityOf) => modQuantity += mod.Quantity);

      if (modQuantity + 1 <= this.selectedProduct.Modifiers.MaxEach) {
        this.addedModifiers.push(this.productToModifier(product));
        this.selectedModifiers[product.Id] = product;
      }
    } else {
      this.addedModifiers.push(this.productToModifier(product));
      this.selectedModifiers[product.Id] = product;
    }
  }

  /**
  * sets the given product as the selected product and begins loading all it's modifier groups and allergens
  * @param product - the product
  */
  private setSelectedProduct(product: DisplayProduct): void {
    if (!product) {
      return;
    }

    // TRACKING
    const price = product.Prices?.find((item: DisplayMenuPrice) => item.Occasion === this.orderOccasion)?.Amount;
    this.analyticsService.trackViewProduct(product.Name, product.Id, this.displayGroup[0], price, 'GBP');

    this._variantPrice.next(price);
    this.isLoading = false;
    this.selectedProduct = product;
    this.selectedSubSections.forEach((mod: DisplayModifierSubSection) => mod.Modifiers = []);
    this.selectedForcedModifiers = {};

    this.loadModifierGroups(product);

    this.allergens = this.getAllergens(product);
    this.defaultModifiers = product.Modifiers.Default.map((p: DisplayProduct) => this.productToModifier(p));
    this.addedModifiers = [];
    this.calculateModifiersPrice();

    const group: DisplayDisplayGroup = this.selectedMenuGroups ? this.selectedMenuGroups[0] : null;
    this.seoService.updateProductSeoTitleAndDescription(this.currentSite, this.orderOccasion, group, this.selectedProduct);
  }

  /**
  * finds all the modifier groups that apply to the current product and places the compatible modifiers on a product in the relevent groups
  * @param product - the selected product
  */
  private loadModifierGroups(product: DisplayProduct): void {
    const allProductModIds = product.Modifiers.Default.map((modifier: DisplayProduct) => modifier.Id).concat(product.Modifiers.Optional.map((modifier) => modifier.Id));

    if (allProductModIds?.length > 0) {
      const allGroupedModifierIds: string[] = [];
      const availableModifiersArrays: DisplayProduct[][] = [];

      const modifierSections = (JSON.parse(JSON.stringify(this.menu.ModifierSections)) as DisplayModifierSection[]);

      modifierSections.forEach((section: DisplayModifierSection) => {
        const allModifierIds = section.AllModifierIds.filter((id: string) => allProductModIds.includes(id));

        if ((allModifierIds.length > 0 ||
            section.AllVariantsModIds.filter((id: string) => allProductModIds.includes(id)).length > 0) &&
            section.SubSections.length > 0) {
          section.Modifiers = section.Modifiers.filter((mod: DisplayProduct) => allProductModIds.includes(mod.Id));

          availableModifiersArrays.push(section.Modifiers);


          section.SubSections.forEach((subsection: DisplayModifierSubSection) => {
            subsection.Modifiers = subsection.AllModifiers.filter((mod: DisplayProduct) => allProductModIds.includes(mod.Id) && !allGroupedModifierIds.includes(mod.Id));

            subsection.Modifiers.forEach((mod: DisplayProduct) => allGroupedModifierIds.push(mod.Id));
            if (subsection.Modifiers.length > 0) {
              const selectedSubIndex = this.selectedSubSections.findIndex((_subsection: DisplayModifierSubSection) => _subsection.Id === subsection.Id);

              if (selectedSubIndex === -1) {
                this.selectedSubSections.push(subsection);
              } else {
                this.selectedSubSections[selectedSubIndex] = subsection;
              }
            }
          });
        }
      });

      if (availableModifiersArrays.length > 0) {
        this.availableModifiers = availableModifiersArrays.reduce((a: DisplayProduct[], b: DisplayProduct[]) => a.concat(b));
      }

      if (this.selectedSubSections.length > 0) {
        this.selectedSubSections.forEach((subSection: DisplayModifierSubSection) => {
          if (subSection.Modifiers.length === 0) {
            const groupedModifiers = [];

            subSection.ModifierVariants.forEach((variant: ModifierVariant) => {
              const modifiers = variant.AllModifiers.filter((mod: DisplayProduct) => allProductModIds.includes(mod.Id));

              if (modifiers.length > 0) {
                groupedModifiers.push(modifiers);
              }
            });

            subSection.Modifiers = groupedModifiers.length > 0 ? groupedModifiers.reduce((a: DisplayProduct[], b: DisplayProduct[]) => a.concat(b)) : [];
          }

          const index = subSection.Modifiers.findIndex((modifier: DisplayProduct) =>
            product.Modifiers.Default.map((_modifier: DisplayProduct) => _modifier.Id).includes(modifier.Id));

          if (index > -1) {
            this.selectForcedModifier(subSection, subSection.Modifiers[index].Id);
          }

          this.offerNoModifierOnForced[subSection.Id] = subSection.MutuallyExclusive && index > -1;
        });
      } else {
        this.availableModifiers = this.menu.Products.filter((_product: DisplayProduct) =>
          this.selectedProduct.Modifiers.Optional.filter((prod: DisplayProduct) =>
            prod.Id === _product.Id).length > 0 || this.selectedProduct.Modifiers.Default.filter((p) => p.Id === _product.Id).length > 0);
      }
    }
  }

  /**
  * converts a display product to a modifier with type QuantityOf
  * @param product - the product to convert
  */
  private productToModifier(product: DisplayProduct | Product): QuantityOf {
    const modifier = new QuantityOf();
    modifier.Item = product.Id;
    modifier.Quantity = 1;
    return modifier;
  }

  /**
  * takes in a string array and returns the array in alphabetical order
  * @param itemsArray - the collection to order
  */
  private alphabetiseItems(itemsArray: string[]): string[] {
    return itemsArray.sort((a: string, b: string) => {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
