import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { HttpStatusCodeHandler } from '@app/core/http.status.codes';
import { Basket } from '@app/models/basket';
import { DealsComponentModalData } from '@app/models/deals/DealsComponentModalData';
import { User } from '@app/models/user';
import { DealsComponent } from '@app/shared/deals/deals.component';
import { BasketService } from '@app/services/basket.service';

@Component({
  selector: 'app-basket-voucher',
  styleUrls: ['./basket-voucher.component.css', './../../shared-basket-styles.scss'],
  templateUrl: './basket-voucher.component.html'
})
export class BasketVoucherComponent extends AndroWebCoreComponent implements OnInit {
  @Input() public basket: Basket;
  @Input() public orderComplete: boolean;
  @Input() public disableVoucherEditing: boolean;

  @Input('user') private _user: User;

  public errorMessage: string;
  public isVoucherSaving: boolean;
  public inputFormControl: FormControl<string>;

  constructor(
    private _dialog2: MatDialog,
    private _basketService: BasketService
  ) {
    super();
  }

  ngOnInit() {
    this.errorMessage = null;
    this.inputFormControl = new FormControl('', [Validators.required]);
  }

  /**
   * applies a voucher to the basket.
   * @param voucher - the voucher code
   */
  public async applyVoucher(): Promise<void> {
    if (this.inputFormControl.invalid) {
      return;
    }

    this.isVoucherSaving = true;
    this.inputFormControl.disable();
    this.errorMessage = null;

    if (this._user && this.basket && !this.basket.HasCustomer) {
      await this._basketService.setCustomerOnBasket(this.basket.Id, this._user.Id);
    }

    const response: HttpErrorResponse | HttpResponse<Basket> = await this._basketService.addVoucherToBasket(this.basket.Id, { voucherCode: this.inputFormControl.value });

    if (HttpStatusCodeHandler.isSuccessResponse(response)) {
      this.basket = response['body'];

      if (this.basket.UnlockedProductIds?.length > 0) {
        this.openDealModal();
      }
    } else {
      this.errorMessage = await this.getErrorMessage(response['error'].type);

      if (this.errorMessage === 'something has gone wrong' && response['error'].detail) {
        this.errorMessage = response['error'].detail;
      }
    }

    this.isVoucherSaving = false;
    this.inputFormControl.enable();
  }

  /**
   * Opens the deals modal.
   */
  private openDealModal(): void {
    const dialogRef: MatDialogRef<DealsComponent, any> = this.openDialog(DealsComponent, 'deals-component', this.getDealModalConfig());

    dialogRef.afterClosed().subscribe((value: string) => {
      if (value === 'cancel') {
        this.removeVoucher();
      }
    });
  }

  /**
   * Sets up the voucher form.
   */
  private getDealModalConfig(): MatDialogConfig<DealsComponentModalData> {
    return {
      data: new DealsComponentModalData(this.basket.UnlockedProductIds[0], this.basket.SiteId, this.basket.Occasion as any),
      height: this.isMobile ? '100%' : '',
      maxHeight: '100%',
      panelClass: ['custom-dialog-two-container', 'product-modal'],
      width: this.isMobile ? '100%' : '450px'
    };
  }

  /**
  * removes a voucher from the basket
  */
  private async removeVoucher(): Promise<void> {
    const basket: Basket | null = await this._basketService.deleteVoucherFromBasket(this.basket.Id);

    if (basket) {
      this.basket = basket;
    }
  }
}
