import { OrderOccasion } from '@app/models/order-occasion';
import { MenuPrice } from '@app/models/menu-price';
import { DisplayOccasionType } from '@app/models/display-occasion-type';


export class DisplayMenuPrice {
  Occasion: DisplayOccasionType;
  Amount: number;
  TaxRate: number;

  public static FromApiMenuPrices(apiMenuPrices: Array<MenuPrice>): Array<DisplayMenuPrice> {
    if (!apiMenuPrices) {
      return [];
    }

    const menuPrice = [];
    apiMenuPrices.forEach((apiMenuPrice: MenuPrice) => menuPrice.push(DisplayMenuPrice.FromApiMenuPrice(apiMenuPrice)));

    return menuPrice;
  }

  public static ToApiMenuPrices(menuPrices: DisplayMenuPrice[]): MenuPrice[] {
    if (!menuPrices) {
      return [];
    }

    const menuPrice = [];
    menuPrices.forEach((apiMenuPrice: DisplayMenuPrice) => menuPrice.push(DisplayMenuPrice.ToApiMenuPrice(apiMenuPrice)));

    return menuPrice;
  }

  public static FromApiMenuPrice(apiMenuPrice: MenuPrice): DisplayMenuPrice {
    const menuPrice = new DisplayMenuPrice();
    menuPrice.Occasion = <DisplayOccasionType><unknown>apiMenuPrice.Occasion;
    menuPrice.Amount = apiMenuPrice.Amount;
    menuPrice.TaxRate = apiMenuPrice.TaxRate;

    return menuPrice;
  }

  public static ToApiMenuPrice(apiMenuPrice: DisplayMenuPrice): MenuPrice {
    const menuPrice = new MenuPrice();
    menuPrice.Occasion = <OrderOccasion><unknown>apiMenuPrice.Occasion;
    menuPrice.Amount = apiMenuPrice.Amount;
    menuPrice.TaxRate = apiMenuPrice.TaxRate;

    return menuPrice;
  }
}
