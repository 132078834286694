import { Meta, Title } from '@angular/platform-browser';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { DisplayDisplayGroup } from '@app/models/display-display-group';
import { DisplayMenuItem } from '@app/models/display-menu-item';
import { DisplayProduct } from '@app/models/display-product';
import { Injectable } from '@angular/core';
import { NavigatorService } from '@app/core/navigator.service';
import { PageSEO } from '@app/models/page-seo';
import { Site } from '@app/models/site';
import { Tenant } from '@app/models/tenant';

@Injectable()
export class SEOService {
  private tenant: Tenant;
  private defaultTitle: string = '';
  private defaultDescription: string = '';

  constructor(
    private meta: Meta,
    private title: Title,
    private configurationService: ConfigurationService,
    private navigatorService: NavigatorService
  ) {
    this.tenant = this.configurationService.tenant;

    this.defaultTitle = this.tenant?.WebsiteName ? this.tenant.WebsiteName : '';

    if (this.tenant?.PageSeos) {
      const defaultSEO = this.tenant.PageSeos.find(
          (seo: PageSEO) => seo.PageName?.toLowerCase() === 'default'
      );

      if (defaultSEO) {
        this.defaultTitle = defaultSEO.Title;
        this.defaultDescription = defaultSEO.Description;
      }
    }
  }

  /**
   * Updates the document title according to its path
   * @param {Site} currentSite
   * @param {string} occasionName
   * @param {DisplayDisplayGroup} groupName
   * @param {String} product
   * @memberof SEOService
   * @public
   */
  public updateSeoTitleAndDescription(currentSite?: Site, occasionName?: string, group?: DisplayDisplayGroup, productName?: string): void {
    const siteName: string = currentSite?.Name ? currentSite.Name : '';
    const occasion: string = occasionName ? this.navigatorService.toTitleCase(this.navigatorService.changeCollectionToTakeAway(occasionName)) : '';
    const groupName: string = group?.Name && group?.Name !== '' ? group?.Name : '';
    const product: string = productName ? productName : '';
    const town: string = currentSite?.Address?.Town ? currentSite?.Address?.Town : '';
    const postCode: string = currentSite?.Address?.Postcode?.split(' ')[0] ? currentSite.Address.Postcode.split(' ')[0] : '';

    const menuDetails: string = `${product ? `${product} - ` : ''}${groupName ? `${groupName} ` : ''}${occasion} Menu`;
    const siteDetails: string = `${siteName ? `${siteName} ` : ''}${town ? `in ${town} ` : ''}${(town || !postCode) ? postCode : `in ${postCode}`}`;
    const locationDetails: string = `${town ? `in ${town} ` : ''}${(town || !postCode) ? postCode : `in ${postCode}`}`;

    this.updateTitle(`${menuDetails} | ${siteDetails}`);
    const allProductsInGroup = group?.Items.map((d: DisplayMenuItem) => d.ProductName).join(', ');
    this.updateDescription(`See our ${groupName} ${occasion} menu ${locationDetails}. Choose from our wide range of ${allProductsInGroup} from ${siteName}.`);
  }

  /**
   * Updates the document title and meta-description for the Product page
   * @param currentSite
   * @param {string} occasionName
   * @param {DisplayDisplayGroup} groupName
   * @param {DisplayProduct} product
   * @memberof SEOService
   */
  public updateProductSeoTitleAndDescription(currentSite?: Site, occasionName?: string, group?: DisplayDisplayGroup, product?: DisplayProduct): void {
    const siteName: string = currentSite?.Name ? currentSite.Name : '';
    const occasion: string = occasionName ? this.navigatorService.toTitleCase(this.navigatorService.changeCollectionToTakeAway(occasionName)) : '';
    const groupName: string = group?.Name && group?.Name !== '' ? group?.Name : '';
    const productName: string = product?.Name ? product?.Name : '';
    const productDescription: string = product?.Description ? product?.Description : '';
    const town: string = currentSite?.Address?.Town ? currentSite?.Address?.Town : '';
    const postCode: string = currentSite?.Address?.Postcode?.split(' ')[0] ? currentSite.Address.Postcode.split(' ')[0] : '';

    const menuDetails: string = `${productName ? `${productName} - ` : ''}${groupName ? `${groupName} ` : ''}${occasion} Menu`;
    const siteDetails: string = `${siteName ? `${siteName} ` : ''}${town ? `in ${town} ` : ''}${(town || !postCode) ? postCode : `in ${postCode}`}`;

    this.updateTitle(`${menuDetails} | ${siteDetails}`);
    this.updateDescription(`Order ${productName} for ${occasion} from ${siteDetails}. ${productDescription}`);
  }

  /**
   * Updates the page title
   * @param {string} title
   * @memberof SEOService
   * @public
   */
  public updateTitle(title: string) : void {
    this.title.setTitle(this.replaceSEOStrings(title));
  }

  /**
   * Updates the meta description
   * @param {string} description
   * @memberof SEOService
   * @public
   */
  public updateDescription(description: string) : void {
    this.meta.updateTag({
      content: this.replaceSEOStrings(description),
      name: 'description',
    });
  }

  /**
   * Retores the title and description
   * @param {string} [title]
   * @memberof SEOService
   * @public
   */
  public restore(title?: string) : void {
    const _title = title ? title : this.defaultTitle;
    this.updateTitle(this.replaceSEOStrings(_title));
    this.updateDescription(this.replaceSEOStrings(this.defaultDescription));
  }

  /**
   * Replace SEO strings
   * @private
   * @param {string} updateText
   * @return {*}
   * @memberof SEOService
   */
  private replaceSEOStrings(updateText: string) : string {
    let response: string;

    if (updateText) {
      if (updateText.includes('{{TenantWebsiteName}}')) {
        response = response ?
          response.replace('{{TenantWebsiteName}}', this.tenant.WebsiteName) :
          updateText.replace(
              '{{TenantWebsiteName}}',
              this.tenant.WebsiteName
          );
      }
      if (updateText.includes('{{TenantName}}')) {
        response = response ?
          response.replace('{{TenantName}}', this.tenant.Name) :
          updateText.replace('{{TenantName}}', this.tenant.Name);
      }
    }

    return response ? response : updateText ? updateText : '';
  }
}
