import { ActivatedRoute, NavigationEnd, Router, RouterEvent, Event } from '@angular/router';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Optional, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AnalyticsService } from '@app/services/analytics.service';
import { Basket } from '@app/models/basket';
import { BasketService } from '@app/services/basket.service';
import { DealItemResult } from '@app/models/deal-item-result';
import { DisplayBasketDeal } from '@app/models/display-basket-deal';
import { DisplayBasketDealItem } from '@app/models/display-basket-deal-item';
import { DisplayDealItem } from '@app/models/display-deal-item';
import { DisplayDealLineOptDisplayedUI } from '@app/models/display-deal-line-opt-displayed-ui';
import { DisplayDealLineOptions } from '@app/models/display-deal-line-options';
import { DisplayDisplayGroup } from '@app/models/display-display-group';
import { DisplayLineTypes } from '@app/models/display-line-types';
import { DisplayMenu } from '@app/models/display-menu';
import { DisplayMenuGroupItem } from '@app/models/display-menu-group-item';
import { DisplayMenuItem } from '@app/models/display-menu-item';
import { DisplayOccasionType } from '@app/models/display-occasion-type';
import { DisplayProduct } from '@app/models/display-product';
import { MenuService } from '@app/services/menu.service';
import { NavigationSectionType } from '@app/models/navigation/navigation-section-type';
import { NavigatorService } from '@app/core/navigator.service';
import { PageSEO } from '@app/models/page-seo';
import { ProductModalData } from '@app/models/product/ProductModalData';
import { SEOService } from '@app/services/seo.service';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { DealsComponentModalData } from '@app/models/deals/DealsComponentModalData';
import { BasketDeal } from '@app/models/basket-deal';
import { BasketDealItem } from '@app/models/basket-deal-item';
import { Charge } from '@app/models/Charge';
import { ChargeTypes } from '@app/models/charge-types';
import { DealTracking } from '@app/models/deals/DealTracking';
import { IBasketDealIn } from '@app/models/basket/IBasketDealIn';
import { QuantityOf } from '@app/models/quantity-of';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { HttpStatusCodeHandler } from '@app/core/http.status.codes';
import { ToastsService } from '@app/services/toasts.service';
import { ToastTypes } from '@app/models/ToastTypes.enum';

/**
 * The deal component where deals get view and optionally added to the basket
 * @export
 * @class DealsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-deals',
  styleUrls: ['./deals.component.scss'],
  templateUrl: './deals.component.html',
})
export class DealsComponent extends AndroWebCoreComponent implements OnInit, OnDestroy {
  @ViewChild('CustomiseProductModal') private _customiseProductModal: TemplateRef<any>;
  @Output('close') private _close: EventEmitter<null> = new EventEmitter<null>();

  public productModalData: ProductModalData;
  public isLoading: boolean = true;
  public deal: DisplayDealItem;
  public lineType: DisplayLineTypes;
  public dealLineOptions: { [key: string]: DisplayDealLineOptDisplayedUI };

  private _occasion: DisplayOccasionType;
  private _displayGroup: string;
  private _createdDeal: DisplayBasketDeal;
  private _seoSet: boolean;
  private _menu: DisplayMenu;
  private _modalLineIndex: number;
  private _dealId: string;
  private _siteId: string;

  constructor(
    private _analyticsService: AnalyticsService,
    private _router: Router,
    private _seoService: SEOService,
    private _menuService: MenuService,
    private _activeRoute: ActivatedRoute,
    private _basketService: BasketService,
    private _toastsService: ToastsService,
    private _navigatorService: NavigatorService,
    @Optional() private _dialogRef: MatDialogRef<DealsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public modalData: DealsComponentModalData
  ) {
    super();
    this.dealLineOptions = {};
    this._createdDeal = { DealId: '', Items: [], name: '' };
  }

  ngOnInit() {
    this.setSiteAndOccasion();
    this.setDisplayGroup();
    this.getDealId();
    this.setupBasket();

    this.subscriptions$['DealsComponent-ngOnInit-router-events'] =
      this._router.events.subscribe((event: Event | RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.getDealId();
          this.setupBasket();
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._seoSet) {
      this._seoService.restore();
    }
  }

  /**
  * returns a menu items Image URL path
  * @param menuItem - the menu item
  */
  public getProductImage(menuItem: DisplayMenuItem): string {
    return menuItem?.ImagePath ? menuItem.ImagePath : '';
  }

  /**
  * Expands or collapses a deal line.
  * Upon expansion the selected product is deselected, unless it is the only product in the line and it is a simple product in which case
  * the product will remain selected
  * @param index - the line index
  */
  public toggleLineItem(index: number): void {
    const line = this.dealLineOptions[index];

    line.expanded = !line.expanded;

    if (line.menuItems.length === 1 && line.menuItems[0].AllProductsInGroup?.length === 1) {
      const product = line.menuItems[0].AllProductsInGroup[0];

      if (DisplayProduct.isSimpleProduct(product)) {
        return;
      }
    }

    line.itemPicked = false;
  }

  /**
  * Adds a simple product to the deal or opens the product modal if it's a complex product
  * @param lineIndex - the index of line to add the product to
  * @param menuItem - the product to add to the deal
  * @param onInitialise - (optional) prevents the product modal opening up automatically when the deal component is being initialised
  */
  public addProductToDeal(lineIndex: number, menuItem: DisplayMenuItem, onInitialise?: boolean): void {
    this.productModalData = {
      displayGroups: this.deal.DealLines[lineIndex].DisplayGroups,
      occasion: this._occasion,
      productName: menuItem.ProductName,
      siteId: this._siteId
    };

    const product: DisplayMenuGroupItem = menuItem.AllProductsInGroup[0];

    if (menuItem.SubGroup?.length > 0 || !DisplayProduct.isSimpleProduct(product)) {
      if (!onInitialise) {
        this.openCustomiseProduct(lineIndex);
      }

      return;
    }

    this.dealLineOptions[lineIndex].expanded = false;
    this.dealLineOptions[lineIndex].itemPicked = true;
    this.dealLineOptions[lineIndex].title = product.CustomerName ?? product.Name;

    const dealItem: DisplayBasketDealItem = {
      Addons: [],
      Charges: [],
      Issues: [],
      Product: DisplayProduct.menuGroupItemToProduct(product),
      Removes: [],
      lineIndex: lineIndex
    };

    this.addProductToCreatedDeal(dealItem);
  }

  /**
   * Cancels the deal and navigates back to deals page
   * @memberof DealsComponent
   * @public
   */
  public cancelDeal(): void {
    if (this.modalData) {
      this._dialogRef.close('cancel');
      return;
    }

    const location: string = this._navigatorService.getLocationBySiteId(this._siteId);
    const routePath: string = this._navigatorService.generateRoutePath(location, this._occasion, NavigationSectionType.Menu, this._displayGroup);
    this._close.emit();
    this._router.navigate([routePath]);
  }

  /**
   * Determines whether the deal is valid and if all deal lines have available products selected
   * @return {*}  {boolean}
   * @memberof DealsComponent
   * @public
   */
  public isDealComplete(): boolean {
    if (!this.deal) {
      return false;
    }

    return this.deal.ValidForBasket && !this.deal.DealLines.some((_, index) => !this.dealLineOptions[index].itemPicked);
  }

  /**
  * Closes the product modal and updates the relevant deal line if a product was selected
  */
  public closeProductModal(dealItemResult: DealItemResult): void {
    this.closeModalById('customiseProductModal');

    if (dealItemResult) {
      this.dealLineOptions[this._modalLineIndex].itemPicked = true;
      this.dealLineOptions[this._modalLineIndex].expanded = false;
      this.dealLineOptions[this._modalLineIndex].title = dealItemResult.product.CustomerName ?? dealItemResult.product.Name;

      const dealItem: DisplayBasketDealItem = {
        Addons: dealItemResult.addedToppings,
        Charges: [],
        Issues: [],
        Product: dealItemResult.product,
        Removes: dealItemResult.removedToppings,
        lineIndex: this._modalLineIndex
      };

      this.addProductToCreatedDeal(dealItem);
    }

    this._modalLineIndex = null;
  }

  /**
  * Adds the deal to the current basket and reroutes the user to the menu page
  */
  public async addDealToCart(): Promise<void> {
    this.isLoading = true;

    const response: HttpErrorResponse | HttpResponse<Basket> = await this._basketService.addDealToBasketAsync(this.getDealInPayload());

    if (HttpStatusCodeHandler.isSuccessResponse(response)) {
      this._toastsService.emitNotification(this.deal.DealName, ToastTypes.success, `${this.deal.DealName} has successfully been added to your basket`, 'Yay');
      this.trackAddRemoveDealToBasket(response['body']);

      if (this.modalData) {
        this._dialogRef.close();
      } else {
        this._close.emit();
        const location: string = this._navigatorService.getLocationBySiteId(this._siteId);
        const routePath: string = this._navigatorService.generateRoutePath(location, this._occasion, NavigationSectionType.Menu, this._displayGroup);
        this._router.navigate([routePath]);
      }
    } else {
      this._toastsService.showToast(ToastTypes.error, 'Could not add item', 'Sorry!');
    }

    this.isLoading = false;
  }

  /**
  * Returns the price of the deal
  */
  public calculateDealPrice(): number {
    let total = 0;

    this.deal.DealLines.filter((dealLines) => dealLines.LineType === DisplayLineTypes.Fixed).forEach((line) => {
      total += line.LineTypeValue;
    });

    return this.deal.FromPrice ? this.deal.FromPrice : total;
  }

  /**
   * returns the deal in payload for the api.
   */
  private getDealInPayload(): IBasketDealIn {
    this._createdDeal.name = this.deal.DealName;
    this._createdDeal.DealId = this.deal.Id;
    this._createdDeal.Items.sort((a: DisplayBasketDealItem, b: DisplayBasketDealItem) => {
      if (a.lineIndex > b.lineIndex) {
        return 1;
      } else if (a.lineIndex < b.lineIndex) {
        return -1;
      } else {
        return 0;
      }
    });

    return {
      DealId: this.deal.Id,
      Items: this._createdDeal.Items.map((item: DisplayBasketDealItem) => {
        const modifiers = item.Addons;

        item.Product.Modifiers.Default
            .filter((modifier: DisplayProduct) => !item.Removes.some((removed: QuantityOf) => removed.Item === modifier.Id))
            .forEach((modifier: DisplayProduct) => {
              modifiers.push({ Item: modifier.Id, Quantity: 1 });
            });

        return {
          Modifiers: modifiers,
          Product: { Item: item.Product.Id, Quantity: 1 }
        };
      })
    };
  }

  /**
   * adds the given deal item to the created deal.
   * @param dealItem
   */
  private addProductToCreatedDeal(dealItem: DisplayBasketDealItem): void {
    const index: number = this._createdDeal.Items.findIndex((item: DisplayBasketDealItem) => item.lineIndex === dealItem.lineIndex);

    if (index > -1) {
      this._createdDeal.Items[index] = dealItem;
      return;
    }

    this._createdDeal.Items.push(dealItem);
  }

  /**
   * sets the displayGroup group property.
   */
  private setDisplayGroup(): void {
    const displayParam: string = this._activeRoute.snapshot.paramMap.get('displayGroup');

    if (displayParam) {
      this._displayGroup = displayParam;
    }
  }

  /**
   * sets the site and occasion properties.
   */
  private setSiteAndOccasion(): void {
    if (this.modalData) {
      this._siteId = this.modalData.siteId;
      this._occasion = this.modalData.occasion;
      return;
    }

    const location: string = this._activeRoute.snapshot.paramMap.get('location');
    const siteId: string = this._navigatorService.getSiteIdByLocation(location);

    if (siteId) {
      this._siteId = siteId.toLowerCase();
    }

    const occasionParam: string = this._activeRoute.snapshot.paramMap.get('occasion');

    if (occasionParam) {
      this._occasion = DisplayOccasionType[this._navigatorService.toTitleCase(this._navigatorService.changeTakeAwayToCollection(occasionParam))];

      if (!this._occasion) {
        this._router.navigate([this._navigatorService.generateRoutePath(location, DisplayOccasionType.Delivery)]);
      }
    }
  }

  /**
  * Gets the current basket.
  */
  private setupBasket(): void {
    this.subscriptions$['DealsComponent-setupBasketAndParams-basketService-getCurrentBasketBySiteId'] =
      this._basketService.getCurrentBasketBySiteId(this._siteId).subscribe((basket: Basket) => {
        if (!basket) {
          return;
        }

        this.setDealAndMenu(basket, this._dealId);
      });
  }

  /**
  * Gets the group filter and deal id from the current routes query params.
  */
  private getDealId(): void {
    this._dealId = this.modalData ? this.modalData.dealId : this._activeRoute.snapshot.paramMap.get('dealId');
  }

  /**
  * Takes a string with a well defined placeholder ( '{{dealName}}' ) and replaces the placeholder with the deals name then returns the updated value
  * @param useDefault - determines whether the default meta title and description text should be used or not
  */
  private updatePageSEO(useDefault: boolean): void {
    if (useDefault) {
      this._seoSet = true;
      const title: string = this.replaceSEOStrings('{{dealName}} - {{TenantWebsiteName}}');
      this._seoService.updateTitle(title);
      this._seoService.updateDescription('{{TenantWebsiteName}} fresh food prepared daily');
    } else {
      const dealsPageSEO = this.tenant.PageSeos.find((seo: PageSEO) => seo.PageName.toLowerCase() === 'deals');

      if (dealsPageSEO) {
        this._seoSet = true;
        this._seoService.updateTitle(this.replaceSEOStrings(dealsPageSEO.Title));
        this._seoService.updateDescription(this.replaceSEOStrings(dealsPageSEO.Description));
      } else {
        this.updatePageSEO(true);
      }
    }
  }

  /**
  * takes a string with a well defined placeholder ( '{{dealName}}' ) and replaces the placeholder with the deals name then returns the updated value
  * @param updateText - the text string with a well defined placeholder
  */
  private replaceSEOStrings(updateText: string): string {
    let response = '';

    if (updateText.includes('{{dealName}}') && this.deal?.DealName) {
      response = response ? response.replace('{{dealName}}', this.deal.DealName) : updateText.replace('{{dealName}}', this.deal.DealName);
    }

    return response ? response : updateText;
  }

  /**
  * Gets the current menu by using the given basket and finds the deal with the matching deal id and sets up all it's lines
  * @param basket - the current basket
  * @param dealId - the selected deal id
  */
  private async setDealAndMenu(basket: Basket, dealId: string): Promise<void> {
    this._menu = await this._menuService.getDisplayMenuByBasket(basket);
    this.isLoading = false;
    this.deal = this._menu.GetDealsGroups(this._occasion, null, true).find((deal: DisplayDealItem) => deal.Id === dealId);

    if (this.deal) {
      if (this.deal.RequiresUnlock && !this.modalData) {
        this.cancelDeal();
        return;
      }

      this._analyticsService.trackViewDeals(this.deal.Id, this.deal.DealName, this._displayGroup, 'GBP');
      this._createdDeal.name = this.deal.CustomerName ? this.deal.CustomerName : this.deal.DealName;

      this.deal.DealLines.forEach((line: DisplayDealLineOptions, index: number) => {
        const displayedUI: DisplayDealLineOptDisplayedUI = {
          expanded: false,
          itemPicked: false,
          menuItems: this.getMenuItemsFromGroups(line.DisplayGroups),
          webSectionNames: this.getWebSectionNames(line.DisplayGroups)
        };

        this.dealLineOptions[index] = displayedUI;

        if (displayedUI.menuItems.length === 1 && displayedUI.menuItems[0].AllProductsInGroup?.length === 1) {
          const product: DisplayMenuGroupItem = displayedUI.menuItems[0].AllProductsInGroup[0];

          if (displayedUI.menuItems[0]?.SubGroup?.length === 0 && DisplayProduct.isSimpleProduct(product)) {
            this.addProductToDeal(index, displayedUI.menuItems[0]);
          }
        }
      });
    }

    this.updatePageSEO(this.tenant.PageSeos?.length === 0);
  }

  /**
   * returns all menu items from the given display groups.
   * @param displayGroups
   */
  private getMenuItemsFromGroups(displayGroups: DisplayDisplayGroup[]): DisplayMenuItem[] {
    return displayGroups
        .map((group: DisplayDisplayGroup) => group.Items)
        .flat()
        .filter((x: DisplayMenuItem) => x.ValidForBasket && !x.OutOfStock);
  }

  /**
   * returns all web section names from the given display groups.
   * @param displayGroups
   */
  private getWebSectionNames(displayGroups: DisplayDisplayGroup[]): string {
    return displayGroups
        .map((group: DisplayDisplayGroup) => {
          if (group.Items?.length > 0) {
            return group.CustomerName ? group.CustomerName : group.Name;
          }

          return '';
        })
        .filter((x: string) => x)
        .join('/');
  }

  /**
   * opens the product page in a mat dialog
   * @param lineIndex - the index of line to the product is being opened from
   */
  private openCustomiseProduct(lineIndex: number): void {
    this._modalLineIndex = lineIndex;
    this.lineType = this.deal.DealLines[lineIndex].LineType;

    this.openDialog(this._customiseProductModal, 'customiseProductModal', {
      autoFocus: false,
      height: this.isMobile ? '100%' : '',
      maxHeight: '100%',
      panelClass: ['custom-dialog-two-container', 'product-modal'],
      width: this.isMobile ? '100%' : '450px'
    });
  }

  /**
   * tracks the add/remove deal to basket event.
   */
  private trackAddRemoveDealToBasket(basket: Basket): void {
    const productIds = this._createdDeal.Items.map((item: DisplayBasketDealItem) => item.Product.Id);
    const deal: BasketDeal = basket.Deals.find((x) => x.DealId === this._createdDeal.DealId && x.Items.every((y) => productIds.includes(y.Product.Item)));

    const payload: DealTracking = {
      currency: 'GBP',
      items: this.getItemsForAnalytics(),
      transactionId: deal.Id,
      value: this.calculateCurrentDealPrice(deal)
    };

    this._analyticsService.trackAddRemoveDealToBasket(true, payload);
  }

  /**
   * returns the deal items for analytics.
   */
  private getItemsForAnalytics(): { [key: string]: string | number }[] {
    const items: { [key: string]: string | number }[] = [
      {
        'item_category': this._displayGroup,
        'item_id': this._createdDeal.DealId,
        'item_name': this._createdDeal.name,
        'price': '19.99',
        'quantity': 1
      }
    ];

    for (const key in this.dealLineOptions) {
      if (this.dealLineOptions[key]) {
        items.push({
          'item_category': this.dealLineOptions[key].webSectionNames,
          'item_id': this._createdDeal.Items[key].Product.Id,
          'item_name': this.dealLineOptions[key].title,
          'promotion_name': this._createdDeal.name,
          'quantity': 1
        });
      }
    }

    return items;
  }

  /**
   * @returns the total price of a deal
   */
  private calculateCurrentDealPrice(deal: BasketDeal): number {
    let total = this.calculateDealModifiersTotal(deal.Items) ?? 0;

    deal.Items
        .filter((x: BasketDealItem) => x.Charges?.length > 0)
        .forEach((item: BasketDealItem) => {
          item.Charges
              .filter((c: Charge) => c.ChargeType === ChargeTypes.ProductCharge)
              .forEach((c: Charge) => total += c.Total);
        });

    return total;
  }

  /**
  * calculates the combined price of all modifiers on a deal
  * @param dealItems - the items (lines) on a deal
  */
  private calculateDealModifiersTotal?(dealItems: BasketDealItem[]): number {
    let number = 0;

    dealItems?.forEach((item: BasketDealItem) => {
      item.Charges.filter((c: Charge) => c.ChargeType === ChargeTypes.ChargeableModifier)
          .forEach((c: Charge) => number += c.Total);
    });

    return number;
  }
}
