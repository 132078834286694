import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Configuration } from '@app/models/Configuration';
import { AuthConfig } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Tenant } from '@app/models/tenant';
import { AllergenMap } from '@app/models/allergen-map';

/**
 * Handles the configuration of the site
 * @export
 * @class ConfigurationService
 * @implements {OnDestroy}
 */
@Injectable()
export class ConfigurationService implements OnDestroy {
  public isTenantReady: Observable<boolean>;

  private _tenant: Tenant;
  private configuration: Configuration;
  private subscriptions: Subscription = new Subscription();
  private tenantLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isLoaded: boolean = false;

  constructor(
    private titleService: Title,
    private readonly http: HttpClient,
  ) {
    this.isTenantReady = this.tenantLoaded.asObservable();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Returns the configuration
   */
  public getConfiguration(): Configuration {
    return this.configuration;
  }

  /**
  * Returns the template URI for redirecting a user when no sites are found on the home page postcode picker
  */
  public getNoSitesForLocationUriTemplate(): string {
    return this._tenant.NoSitesForLocationUriTemplate;
  }

  /**
   * Returns the base url for the API
   */
  public getApiBaseUrl(): string {
    return this.configuration.api.baseUrl;
  }

  /**
  * Returns the api base URL for the current tenant
  */
  public getApiBaseUrlForTenant(): string {
    return `${this.getApiBaseUrl()}/tenants/${this._tenant.Id}`;
  }

  /**
  * Returns the Instrumentation Key setup in the config
  */
  public getInstrumentationKey(): string {
    return this.configuration.telemetry.instrumentationKey;
  }

  /**
  * Returns the Application Insights cloud-role-name to associate telemetry from this application with
  */
  public getTelemetryRoleName(): string {
    return this.configuration.telemetry.roleName;
  }

  /**
  * Returns the andromeda id used for google analytics
  */
  public getGoogleAnalyticsIdAndromeda(): string {
    return this.configuration.telemetry.googleAnalyticsIdAndromeda;
  }

  /**
  * Returns the client id used for google analytics
  */
  public getGoogleAnalyticsIdClient(): string {
    return this.configuration.telemetry.googleAnalyticsIdClient;
  }

  /**
  * Returns the client id used for google analytics
  */
  public getTenantAllergenMap(): AllergenMap {
    return this._tenant.AllergenMap;
  }

  /**
  * Returns the tenant id
  */
  public getTenantId(): string {
    return this._tenant.Id;
  }

  /**
  * returns the base URL for the tenant specific identity server
  */
  public getIdentityServerUrl(): string {
    return this.configuration.local.overrideIdentityServerUrl ?? this._tenant.IdentityServerUrl;
  }

  /**
  * returns the api key for the google map.
  */
  public getMapKey(): string {
    return this.configuration.api.mapKey;
  }

  /**
  * gets the configuration data from the api and assigns it to the configuration property
  */
  public loadConfiguration(): void {
    this.subscriptions.add(this.http.get<Configuration>('/api/configuration').subscribe((data: Configuration) => {
      this.configuration = data;
      this.loadTenant(data.api.baseUrl, data.local.endPoint);
      this.isLoaded = true;
    }));
  }

  /**
   * returns the tenant
   */
  public get tenant(): Tenant {
    return this._tenant;
  }

  /**
   * returns the current environment
   */
  public getEnvironment(): string {
    return this.configuration?.local?.environment;
  }

  /**
  * returns the current AppSlot
  */
  public getEnvironmentAppSlot(): string {
    if (this.isLoaded) {
      return this.configuration.appSlot;
    }
    return null;
  }

  /**
  * returns the auth config
  */
  public getAuthConfiguration(): AuthConfig {
    return {
      // The SPA's id. The SPA is registered with this id at the auth-server
      clientId: 'androweb-website',

      // Url of the Identity Provider
      issuer: this.getIdentityServerUrl(),

      // URL of the SPA to redirect the user to after login
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,

      // Set the scopes that the UI requires.
      scope: 'customers.read customers.write orders.read orders.write identity.read',

      showDebugInformation: true,
    };
  }

  /**
   * returns an observable of the current configurations tenant.
   * @param baseUrl
   * @param hostName
   */
  public tenant$(): Observable<Tenant> {
    return this.http.get<Tenant>(`${this.getApiBaseUrl()}/tenants?HostName=${this.configuration.local.endPoint}`);
  }

  /**
   * Gets the tenant for the given host
   * @param baseUrl
   * @param hostName
   */
  private loadTenant(baseUrl: string, hostName: string): void {
    this.subscriptions.add(this.http.get<Tenant>(`${baseUrl}/tenants?HostName=${hostName}`).subscribe((tenant: Tenant) => {
      this._tenant = tenant;
      if (tenant) {
        this.titleService.setTitle(tenant.WebsiteName !== null && tenant.WebsiteName !== '' ? tenant.WebsiteName : tenant.Name);
      }
      this.tenantLoaded.next(true);
    }));
  }
}
