import { ModifierType } from '@app/models/modifier-type';
import { IProductPath } from '@app/models/product/IProductPath';

/**
 * Display data for the UI to use when handling deals
 * @export
 * @class basketDealDisplayedUI
 */
export class BasketDealDisplayedUI {
  type: string;
  index?: number;
  expanded?: boolean;
  hasModifierCharges?: boolean;
  hasIssues: boolean;
  hasAddOns?: boolean;
  modifiers?: {
    addons: ModifierType[],
    removes: ModifierType[]
  }
  name?: IProductPath;
}
