import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { Basket } from '@app/models/basket';
import { BasketDeal } from '@app/models/basket-deal';
import { BasketDealItem } from '@app/models/basket-deal-item';
import { Charge } from '@app/models/Charge';
import { ChargeTypes } from '@app/models/charge-types';
import { DisplayDeal } from '@app/models/display-deal';
import { DisplayMenu } from '@app/models/display-menu';
import { DisplayOccasionType } from '@app/models/display-occasion-type';
import { DisplayProduct } from '@app/models/display-product';
import { Issue } from '@app/models/Issue';
import { ModifierType } from '@app/models/modifier-type';
import { QuantityOf } from '@app/models/quantity-of';
import { AnalyticsService } from '@app/services/analytics.service';
import { BasketService } from '@app/services/basket.service';
import { DealTracking } from '@app/models/deals/DealTracking';

@Component({
  selector: 'app-basket-deal-item',
  styleUrls: ['./basket-deal-item.component.scss', './../../shared-basket-styles.scss'],
  templateUrl: './basket-deal-item.component.html',
})
export class BasketDealItemComponent extends AndroWebCoreComponent implements OnChanges {
  @Input() public basketItemIndex: number;
  @Input() public item: BasketDeal;
  @Input() public basketIsModifiable: boolean;

  @Input('currentBasket') private _currentBasket: Basket;
  @Input('displayMenu') private _displayMenu: DisplayMenu;

  public dealName: string;
  public dealPrice: number;
  public dealImageUrl: string;
  public showVoucherTag: boolean;

  private _addAnotherButtonsDeals: { [id: string]: number };
  private _dealIdToDisplayDeal: { [id: string]: DisplayDeal };

  constructor(
    private _basketService: BasketService,
    private _analyticsService: AnalyticsService,
  ) {
    super();
    this._addAnotherButtonsDeals = {};
    this._dealIdToDisplayDeal = {};
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item?.currentValue) {
      const deal: DisplayDeal = this.getDealById(this.item.DealId);
      this.dealName = deal.CustomerName ?? deal.Name;
      this.dealImageUrl = deal.ImageUrl ?? '';
    }

    if (changes.item?.currentValue || changes._currentBasket?.currentValue) {
      this.showVoucherTag = this._currentBasket?.UnlockedProductIds?.includes(this.item?.DealId);
    }
  }

  /**
  * takes an array of basket issues and returns only unique (by IssueType) issues
  * @issues issue - the basket issues
  */
  public getUniqueItemIssues(issues: Issue[]): Issue[] {
    const response: Issue[] = [];

    if (Array.isArray(issues)) {
      issues?.forEach((_issue: Issue) => {
        if (response.findIndex((x: Issue) => x.IssueType === _issue.IssueType) === -1) {
          response.push(_issue);
        }
      });
    }

    return response;
  }

  /**
   * removes an deal from the basket
   * @param dealIndex - the index of the deal
   */
  public async removeDealItem(): Promise<void> {
    await this._basketService.deleteDealFromBasketAsync(this.item.Id);
    this.trackRemoveDealItem();
    const dealItemId: string = this.item.DealId;

    if (this._addAnotherButtonsDeals[dealItemId]) {
      this._addAnotherButtonsDeals[dealItemId] = null;
    }

    if (!this._currentBasket.UnlockedProductIds || !this._currentBasket.UnlockedProductIds.includes(dealItemId)) {
      return;
    }

    const basket: Basket | null = await this._basketService.deleteVoucherFromBasket(this._currentBasket.Id);

    if (basket) {
      basket.WantedTimeUtc = this._currentBasket.WantedTimeUtc;
      this._currentBasket = basket;
    }
  }

  /**
  * expands or collapses a deal
  * @param dealId - the id of the deal
  * @param dealIndex - the index of the deal
  */
  public toggleDealExpansion(): void {
    this.item.displayedUI.expanded = !this.item.displayedUI.expanded;
  }

  /**
  * returns a products full name
  * @param productId - the id of the product
  */
  public getDisplayProductFullNameById(productId: string): string {
    return this._displayMenu
        .GetFullProductPathFromId(productId, this._currentBasket.Occasion as any as DisplayOccasionType)
        .combined.filter(Boolean).join(', ');
  }

  /**
  * returns all removed and added modifiers on a basket item in separate arrays;
  * @param basketItem - the basket item
  * @param removedOnly - if the removed items array is all that should be returned only
  */
  public getModifierTypesForDisplayProduct(basketItem: BasketDealItem, removedOnly: boolean): ModifierType[] {
    const modifiers: ModifierType[] = [];

    if (basketItem.Modifiers?.length === 0) {
      return modifiers;
    }

    const displayProduct = this._displayMenu.Products.find((x) => x.Id === basketItem.Product.Item);

    if (!displayProduct) {
      return modifiers;
    }

    // Removed modifiers
    displayProduct.Modifiers.Default.forEach((mod) => {
      if (!basketItem.Modifiers.map((x) => x.Item).includes(mod.Id)) {
        const modifierType = new ModifierType();
        const modifierProduct = this._displayMenu.Products.find((x) => x.Id === mod.Id);

        modifierType.isRemoved = true;
        modifierType.product = modifierProduct;
        modifiers.push(modifierType);
      }
    });

    const uniqueModifiers: QuantityOf[] = [];

    basketItem.Modifiers.forEach((modifier) => {
      const foundIndex = uniqueModifiers.findIndex((x) => x.Item === modifier.Item);

      if (foundIndex === -1) {
        uniqueModifiers.push(modifier);
      } else {
        uniqueModifiers[foundIndex].Quantity += 1;
      }
    });

    uniqueModifiers.forEach((modifier) => {
      const isDefault = displayProduct.Modifiers.Default.map((x) => x.Id).includes(modifier.Item);

      if (isDefault && modifier.Quantity === 1) {
        return;
      }

      if (isDefault) {
        modifier.Quantity--;
      }

      const modifierType = new ModifierType();
      const modifierProduct = this._displayMenu.Products.find((x) => x.Id === modifier.Item);

      modifierType.isRemoved = false;
      modifierType.product = modifierProduct;
      modifierType.quantity = modifier.Quantity;
      modifiers.push(modifierType);
    });

    return removedOnly ? modifiers.filter((x) => x.isRemoved) : modifiers.filter((x) => !x.isRemoved);
  }

  /**
  * returns the image url for a given product
  * @param productId - the products id
  */
  public getProductImage(productId: string): string {
    const product = this._displayMenu.Products.find((p: DisplayProduct) => p.Id === productId);
    return product?.ImageUrl ? product.ImageUrl : '';
  }

  /**
   * @returns the total price of a deal
   */
  public calculateDealPrice(): number {
    let total = this.calculateDealModifiersTotal(this.item.Items) ?? 0;

    this.item.Items
        .filter((x: BasketDealItem) => x.Charges?.length > 0)
        .forEach((item: BasketDealItem) => {
          item.Charges
              .filter((c: Charge) => c.ChargeType === ChargeTypes.ProductCharge)
              .forEach((c: Charge) => total += c.Total);
        });

    return total;
  }

  /**
   * tracks the removal of a deal
   */
  private trackRemoveDealItem(): void {
    const items: { [key: string]: string | number }[] = [
      {
        'item_id': this.item.DealId,
        'item_name': this.dealName,
        'price': this.calculateDealPrice(),
        'quantity': 1
      }
    ];

    const track: DealTracking = {
      currency: 'GBP',
      items,
      transactionId: this.item.Id,
      value: this.calculateDealPrice(),
    };

    this._analyticsService.trackAddRemoveDealToBasket(false, track);
  }

  /**
  * returns a deal that matches the given id
  * @param dealId - the id of the deal
  */
  private getDealById(dealId: string): DisplayDeal {
    if (this._dealIdToDisplayDeal[dealId]) {
      return this._dealIdToDisplayDeal[dealId];
    }

    const deal = this._displayMenu?.Deals?.find((d: DisplayDeal) => d.Id === dealId);

    if (deal) {
      this._dealIdToDisplayDeal[dealId] = deal;
      return deal;
    }

    return null;
  }

  /**
  * calculates the combined price of all modifiers on a deal
  * @param dealItems - the items (lines) on a deal
  */
  private calculateDealModifiersTotal?(dealItems: BasketDealItem[]): number {
    let number = 0;

    dealItems?.forEach((item: BasketDealItem) => {
      item.Charges.filter((c: Charge) => c.ChargeType === ChargeTypes.ChargeableModifier)
          .forEach((c: Charge) => number += c.Total);
    });

    return number;
  }
}
