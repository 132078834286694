
import { BasketDeal } from '@app/models/basket-deal';
import { BasketItem } from '@app/models/basket-item';
import { Charge } from '@app/models/Charge';
import { Issue } from '@app/models/Issue';
import { OrderOccasion } from '@app/models/order-occasion';

/**
 * basket data returned from the API
 * @export
 * @class Basket
 */
export class Basket {
  Charges?: Charge[];
  CompatibleOccasions?: OrderOccasion[];
  Deals?: BasketDeal[];
  DeliveryCharge?: number;
  DeliveryChargeWaivedSpend?: number;
  DeliveryLocation?: string;
  DeliveryMinimumSpend?: number;
  ExpiryTimeUtc?: Date;
  HasCustomer?: boolean;
  Id?: string;
  IsModifiable?: boolean;
  IsValid?: boolean;
  Issues?: Issue[];
  Items?: BasketItem[];
  LoyaltyPointsValue: number;
  Occasion: OrderOccasion;
  OrderId?: string;
  SiteId?: string;
  UnlockedProductIds?: string[];
  VoucherCode?: string;
  WantedTimeUtc?: string;
  TableNumber?: number;
}
