import { ProductOptionGroup } from '@app/models/product-option-gGroup';
import { DisplayOccasionType } from '@app/models/display-occasion-type';
import { DisplayAvailableType } from '@app/models/display-available-type';
import { DisplayMenuGroupItem } from '@app/models/display-menu-group-item';
import { DisplayMenuItemGroup } from '@app/models/display-menu-item-group';

/**
 * Data inside a display menu item.
 * @class DisplayMenuItem
 * @export
 */
export class DisplayMenuItem {
  AllProductsInGroup: DisplayMenuGroupItem[] = [];
  CustomerName: string;
  FromPrice: number;
  Id?: string;
  ImagePath?: string;
  OutOfStock?: boolean;
  ProductDescription?: string;
  ProductName: string;
  ProductOptionGroups?: ProductOptionGroup[];
  RequiresUnlock?: boolean;
  SubGroup: DisplayMenuItemGroup[] = [];
  ToPrice: number;
  ValidForBasket: boolean;
  type: 'deal' | 'product';
  Sequence?: number;
  NutritionSummary?: string;

  public get AvailableOccasions(): Array<DisplayOccasionType> {
    let filteredItemsAsProducts: DisplayOccasionType[] = [];

    filteredItemsAsProducts = this.AllProductsInGroup
        .map((product: DisplayMenuGroupItem) => {
          if (product.type === 'deal') {
            return product.Occasions;
          } else {
            return product.AvailableOccasions;
          }
        })
        .reduce((a: DisplayOccasionType[], b: DisplayOccasionType[]) => a.concat(b));

    return Array.from(new Set(filteredItemsAsProducts).values());
  }

  public IsAvailableForWantedTime(): DisplayAvailableType {
    return this.AvailableForWantedTime();
  }

  public AvailableForWantedTime(): DisplayAvailableType {
    const availableOptions = Array.from(new Set(this.SubGroup.map((e: DisplayMenuItemGroup) => e.IsAvailableForWantedTime())).values());

    if (availableOptions.includes(DisplayAvailableType.Yes)) {
      return DisplayAvailableType.Yes;
    }

    if (availableOptions.includes(DisplayAvailableType.No)) {
      return DisplayAvailableType.No;
    }

    return DisplayAvailableType.Unknown;
  }
}
