import { DisplayDeal } from '@app/models/display-deal';
import { DisplayDealLine } from '@app/models/display-deal-line';
import { DisplayMenuPrice } from '@app/models/display-menu-price';
import { DisplayModifiers } from '@app/models/display-modifiers';
import { DisplayOccasionType } from '@app/models/display-occasion-type';
import { DisplayAvailableType } from '@app/models/display-available-type';
import { DisplayAllergenDetails } from '@app/models/display-allergen-details';
import { Nutrition } from './menu/nutrition';

/**
 * Data inside a display menu group item.
 * @class DisplayMenuGroupItem
 * @export
 */
export class DisplayMenuGroupItem {
  AllergenDetails?: DisplayAllergenDetails;
  AvailableOccasions?: DisplayOccasionType[];
  CustomerName: string;
  DealPricePremium?: number;
  Description?: string;
  DisplayOrder?: number;
  First2LinesCouldBeReordered?: boolean;
  Id: string;
  ImageUrl?: string;
  IsAvailableForWantedTime?: DisplayAvailableType; // Display_AvaliableType.Unknown;
  Lines?: DisplayDealLine[];
  MinimumSpend?: number;
  Modifiers?: DisplayModifiers;
  Name: string;
  Occasions?: DisplayOccasionType[];
  OutOfStock?: boolean;
  Prices?: Array<DisplayMenuPrice>;
  RequiresUnlock?: boolean;
  ValidForBasket: boolean;
  type: 'product' | 'deal';
  Sequence?: number;
  Nutrition?: Nutrition;

  public static dealToMenuGroupItem(deal: DisplayDeal) {
    const groupItem = new DisplayMenuGroupItem();
    groupItem.Id = deal.Id;
    groupItem.Name = deal.Name;
    groupItem.CustomerName = deal.CustomerName;
    groupItem.Description = deal.Description;
    groupItem.DisplayOrder = deal.DisplayOrder;
    groupItem.ValidForBasket = deal.ValidForBasket;
    groupItem.ImageUrl = deal.ImageUrl;
    groupItem.MinimumSpend = deal.MinimumSpend;
    groupItem.First2LinesCouldBeReordered = deal.First2LinesCouldBeReordered;
    groupItem.Occasions = deal.Occasions;
    groupItem.Lines = deal.Lines;
    groupItem.type = 'deal';
    groupItem.OutOfStock = deal.OutOfStock;
    groupItem.RequiresUnlock = deal.RequiresUnlock;
    groupItem.Sequence = deal.Sequence;
    groupItem.Nutrition = null;

    return groupItem;
  }
}
