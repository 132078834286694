import { DealLine } from '@app/models/deal-line';
import { DisplayLineTypes } from '@app/models/display-line-types';
import { DisplayProduct } from '@app/models/display-product';

export class DisplayDealLine {
  Number: number;
  LineType: DisplayLineTypes;
  Value: number;
  FreeModifiers: number;
  ProductsListId: number;
  displayedUI: {
    products: Array<DisplayProduct>;
    expanded: boolean;
    itemPicked: boolean;
    title: string;
  };

  public static FromApiDealLine(apiDealLine: DealLine): DisplayDealLine {
    const dealLine = new DisplayDealLine();
    dealLine.Number = apiDealLine.Number;
    dealLine.LineType = apiDealLine.Type as any;
    dealLine.Value = apiDealLine.Value;
    dealLine.FreeModifiers = apiDealLine.FreeModifiers;
    dealLine.ProductsListId = apiDealLine.ProductsListId;

    return dealLine;
  }
}
