import { MenuPrice } from '@app/models/menu-price';
import { Modifiers } from '@app/models/Modifiers';
import { Nutrition } from '@app/models/menu/nutrition';

/**
 * The data within a Product on the menu
 * @export
 * @class Product
 */
export class Product {
  Id: string;
  Name: string;
  CustomerName: string;
  Description: string;
  CustomerDescription?: string;
  AllergenDetails?: {
      Contains: string[],
      MayContain: string[]
  };
  ImageUrl?: string;
  ValidForBasket: boolean;
  OutOfStock: boolean;
  Sequence?: number;
  DealPricePremium: number;
  Prices: MenuPrice[];
  Modifiers: Modifiers;
  Nutrition?: Nutrition;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  /**
  * returns whether the product is editable or not
   *
  * @param products - the product in question
  */
  public static isProductEditable(product: Product): boolean {
    return ((product.Modifiers.Default && product.Modifiers.Default.length > 0) || (product.Modifiers.Optional && product.Modifiers.Optional.length > 0));
  }

  /**
  * returns whether the product has allergens or not
  *
  * @param products - the product in question
  */
  public static hasAllergens(product: Product): boolean {
    if (product.AllergenDetails) {
      return (product.AllergenDetails.Contains.length > 0 || product.AllergenDetails.MayContain.length > 0);
    } else {
      return false;
    }
  }
}
