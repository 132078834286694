/**
 * Data inside a display product list.
 * @class DisplayProductList
 * @export
 */
export class DisplayProductList {
  Id: number;
  ProductIds: string[];

  constructor(id?: number, productIds?: string[]) {
    this.Id = id;
    this.ProductIds = productIds;
  }
}
