import { Pipe, PipeTransform } from '@angular/core';

import { DisplayDisplayGroup } from '@app/models/display-display-group';
import { NavigatorService } from '@app/core/navigator.service';

@Pipe({
  name: 'menuV2FilterGroupName',
  pure: false,
})
export class MenuV2FilterGroupName implements PipeTransform {
  constructor(private navigatorService: NavigatorService) {
  }

  public transform(groups: DisplayDisplayGroup[], groupName: string): DisplayDisplayGroup[] {
    if (groups) {
      return !groupName || groupName === '' ?
       groups : groups.filter((group: DisplayDisplayGroup) => this.navigatorService.clean(group.CustomerName).toLocaleLowerCase() === groupName.toLocaleLowerCase());
    } else {
      return [];
    }
  }
}
