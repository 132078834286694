import { LOCAL_STORAGE, SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Injector, Inject, OnDestroy, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/services/user.service';
import { interval, Observable, of, Subscription } from 'rxjs';
import { User } from '@app/models/user';
import { AuthenticationExperience } from '@app/models/authentication-experience.enum';
import { MatDialog } from '@angular/material/dialog';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { NavigatorService } from '@app/core/navigator.service';
import { StorageKeys } from '@app/models/_root/_storage-keys';
import { IdentityIframeComponent } from '@app/shared/identity-iframe/identity-iframe.component';
import { ConfigurationService } from '@app/app-initialisers/configuration-service/configuration.service';
import { InsightsService } from '@app/app-initialisers/insights-service/insights.service';

@Injectable({
  providedIn: 'root'
})
export class InitialiseLoginService implements OnDestroy {
  private subscription: Subscription = new Subscription();
  private intervalSubscription: Subscription;
  public isMobile: boolean;
  public isTablet: boolean;
  public isShortDevice: boolean;

  private readonly mobileViewWidth: number = 479;

  constructor(
    private dialog: MatDialog,
    private injector: Injector,
    private userService: UserService,
    private oauthService: OAuthService,
    private insightsService: InsightsService,
    private configurationService: ConfigurationService,
    private navigatorService: NavigatorService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {
    this.mobileWidthCheck();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.intervalSubscription?.unsubscribe();
  }

  /**
   * Checks the width of the screen
   * @memberof AndroWebCoreComponent
   * @protected
   */
  protected mobileWidthCheck(): void {
    this.isMobile = window.innerWidth < this.mobileViewWidth;
  }

  /**
   * configures OAuthService with the ConfigurationService's auth config and tries to log the user in
   */
  public loginInit(): void {
    this.subscription.add(this.configurationService.isTenantReady.subscribe(async (status: boolean) => {
      if (status) {
        const config: AuthConfig = this.configurationService.getAuthConfiguration();
        const preferredExperience: AuthenticationExperience = this.configurationService.tenant.PreferredAuthenticationExperience;

        if (preferredExperience !== AuthenticationExperience.FullRedirect) {
          config.postLogoutRedirectUri = `${config.postLogoutRedirectUri}/authComplete`;
          config.redirectUri = `${config.redirectUri}/authComplete`;

          config.openUri = preferredExperience === AuthenticationExperience.NewTab ?
            (uri: string) => this.openNewTab(uri) :
            (uri: string) => this.openIframe(uri);
        }

        this.oauthService.configure(config);
        await this.oauthService.loadDiscoveryDocument();

        this.subscription.add(this.getUserFromLocalStorage().subscribe(() => {
          if (preferredExperience === AuthenticationExperience.FullRedirect) {
            this.insightsService.trackTrace('full redirect used as preferred authentication experience', SeverityLevel.Information);
            this.userService.tryLoginAndSetUser(window.location.hash);
          }
        }));
      }
    }));
  }

  /**
   * opens the identity iframe component in a mat dialog
   * @param uri
   */
  private openIframe(uri: string): void {
    this.mobileWidthCheck();
    this.sessionStorage.set('identitySrc', uri);

    if (this.dialog.getDialogById('identity-modal')) {
      return;
    }

    this.dialog.open(IdentityIframeComponent, {
      height: this.isMobile ? '100%' : window.innerHeight < 780 ? '100%' : '780px',
      id: 'identity-modal',
      maxWidth: 'unset',
      panelClass: 'identity-modal-window',
      width: this.isMobile ? '100%' : window.innerWidth < 510 ? '100%' : '510px',
    });
  }

  /**
   * overrides OAuthService login/logout redirect and opens a new child window at the given uri, once the users logged in then closes the child window
   * and tries to login with the returned tokens and params. For login it reroutes the browser location to the given uri
   * @param {string} uri
   */
  private openNewTab(uri: string): void {
    this.insightsService.trackTrace('new tab used as preferred authentication experience', SeverityLevel.Information);
    const windowHandle: Window = window.open(uri, '_blank');

    if (windowHandle) {
      this.intervalSubscription = interval().subscribe(() => {
        let hash: string;
        let href: string;

        try {
          hash = windowHandle.location.hash;
          href = windowHandle.location.href;
        } catch (e) { }

        if (href?.includes('/authComplete')) {
          this.intervalSubscription.unsubscribe();
          windowHandle.close();

          if (href.includes('/checkout') || href.includes('/profile')) {
            this.router.navigate([this.navigatorService.generateHomePath()]);
          }
        }

        if (hash) {
          this.userService.tryLoginAndSetUser(hash);
          this.intervalSubscription.unsubscribe();
          windowHandle.close();
        }
      });
    } else {
      this.insightsService.trackTrace('Id server popup blocked by browser.', SeverityLevel.Critical);
      this.router.navigate([this.navigatorService.generateHomePath()]);
    }
  }


  /**
   * checks if there's a user in local storage and if so returns an Observable of type user, if no user is present in
   * local storage it returns an empty observable
   */
  private getUserFromLocalStorage(): Observable<User> {
    const user: User = this.storage.get(StorageKeys.user);
    return user ? this.userService.getUser(user.Id) : of<User>(null);
  }

  /*
   * injects the router into the service and returns it
   */
  private get router(): Router {
    return this.injector.get<Router>(Router);
  }
}
