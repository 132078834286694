import { DisplayDeal } from '@app/models/display-deal';
import { DisplayDealLine } from '@app/models/display-deal-line';
import { DisplayMenuGroup } from '@app/models/display-menu-group';
import { DisplayProduct } from '@app/models/display-product';
import { DisplayMenuPrice } from '@app/models/display-menu-price';
import { DisplayLineTypes } from '@app/models/display-line-types';
import { DisplayOccasionType } from '@app/models/display-occasion-type';
import { DisplayAvailableType } from '@app/models/display-available-type';
import { DisplayProductList } from '@app/models/display-product-list';
import { DisplayMenuItem } from '@app/models/display-menu-item';
import { DisplayMenuItemGroup } from '@app/models/display-menu-item-group';
import { DisplayDisplayGroup } from '@app/models/display-display-group';
import { DisplayDealItem } from '@app/models/display-deal-item';
import { DisplayDealLineOptions } from '@app/models/display-deal-line-options';
import { DisplayMenuGroupItem } from '@app/models/display-menu-group-item';
import { DisplayModifierSection } from '@app/models/display-modifier-section';
import { IProductPath } from '@app/models/product/IProductPath';
import { Nutrition } from '@app/models/menu/nutrition';

/**
 * Data in the display menu.
 * @class DisplayMenu
 * @export
 */
export class DisplayMenu {
  private _dealsGroups: DisplayDealItem[] = [];
  private _displayGroups: { [id: string]: DisplayDisplayGroup[] } = {};

  Id: string;
  Deals: DisplayDeal[];
  Groups: DisplayMenuGroup[];
  Products: DisplayProduct[];
  ProductLists: DisplayProductList[];
  ModifierSections: DisplayModifierSection[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  private ReturnHighestOrLowestPriceForDealLines(dealLineOptions: Array<DisplayDealLineOptions>, occasion: DisplayOccasionType, lowest: boolean = true): number {
    return dealLineOptions.map((e: DisplayDealLineOptions) => this.ReturnHighestOrLowestPriceForDealLine(e, occasion, lowest))
        .reduce((sum: number, current: number) => sum + current, 0);
  }

  private ReturnHighestOrLowestPriceForDealLine(dealLineOptions: DisplayDealLineOptions, occasion: DisplayOccasionType, getLowest: boolean = true): number {
    let foundPrice: number = 0;

    if (dealLineOptions.DisplayGroups.length < 1) {
      return dealLineOptions.LineTypeValue;
    }

    const allProducts = dealLineOptions.DisplayGroups
        .map((e: DisplayDisplayGroup) => e.Items)
        .reduce((a: DisplayMenuItem[], b: DisplayMenuItem[]) => a.concat(b))
        .map((e: DisplayMenuItem) => e.AllProductsInGroup)
        .reduce((a: DisplayMenuGroupItem[], b: DisplayMenuGroupItem[]) => a.concat(b));

    if (dealLineOptions.LineType === DisplayLineTypes.Fixed || (dealLineOptions.LineType === DisplayLineTypes.Percent && dealLineOptions.LineTypeValue === 0)) {
      if (getLowest) {
        return dealLineOptions.LineTypeValue;
      } else {
        return dealLineOptions.LineTypeValue + allProducts.map((e: DisplayMenuGroupItem) => e.DealPricePremium)
            .reduce((highest: number, current: number) => {
              if (current > highest) {
                highest = current;
              }
              return highest;
            }, 0);
      }
    }

    if (getLowest) {
      foundPrice = allProducts.map((menuGroup: DisplayMenuGroupItem) => (
        menuGroup.Prices.filter((price: DisplayMenuPrice) => price.Occasion === occasion)
            .map((_price: DisplayMenuPrice) => {
              let total = _price.Amount;

              if (menuGroup.DealPricePremium && menuGroup.DealPricePremium > 0) {
                total += menuGroup.DealPricePremium;
              }

              return total;
            })
            .reduce((lowest: number, current: number) => {
              if (current < lowest) {
                lowest = current;
              }

              return lowest;
            }, Number.MAX_VALUE)))

          .reduce((lowest: number, current: number) => {
            if (current < lowest) {
              lowest = current;
            }

            return lowest;
          }, Number.MAX_VALUE);
    } else {
      foundPrice = allProducts.map((e: DisplayMenuGroupItem) => (
        e.Prices.filter((p: DisplayMenuPrice) => p.Occasion === occasion)
            .map((p: DisplayMenuPrice) => p.Amount)
            .reduce((highest: number, current: number) => {
              if (current > highest) {
                highest = current;
              }

              return highest;
            }, 0) + e.DealPricePremium))
          .reduce((highest: number, current: number) => {
            if (current > highest) {
              highest = current;
            }

            return highest;
          }, 0);
    }

    if (dealLineOptions.LineType === DisplayLineTypes.Discount) {
      foundPrice = (foundPrice - dealLineOptions.LineTypeValue);
    } else {
      foundPrice = (foundPrice * (dealLineOptions.LineTypeValue / 100));
    }

    return (foundPrice > 0 ? foundPrice : 0);
  }

  private ReturnHighestOrLowestPrice(products: DisplayMenuGroupItem[], productName: string, occasion: DisplayOccasionType, lowest: boolean = true): number {
    const allPrices = products.filter((searchFor: DisplayMenuGroupItem) => searchFor.Name === productName && searchFor.type !== 'deal')
        .map((product: DisplayMenuGroupItem) => product.Prices)
        .reduce((a: DisplayMenuPrice[], b: DisplayMenuPrice[]) => a.concat(b), [])
        .filter((price: DisplayMenuPrice) => price && price.Occasion === occasion);

    if (lowest) {
      let lowestPrice = allPrices
          .map((prices: DisplayMenuPrice) => prices.Amount)
          .reduce((_lowest: number, current: number) => {
            if (current < _lowest) {
              _lowest = current;
            }

            return _lowest;
          }, Number.MAX_VALUE);

      if (lowestPrice === Number.MAX_VALUE) {
        lowestPrice = 0;
      }

      return lowestPrice;
    }

    return allPrices
        .map((prices: DisplayMenuPrice) => prices.Amount)
        .reduce((highest: number, current: number) => {
          if (current > highest) {
            highest = current;
          }

          return highest;
        }, 0);
  }

  private ToMenuItemGroup(menuGroup: DisplayMenuGroup, productName: string, occasion: DisplayOccasionType, orderWantedAt: string = null): DisplayMenuItemGroup {
    const menuItemGroup = new DisplayMenuItemGroup();
    menuItemGroup.GroupName = menuGroup.Name;
    menuItemGroup.CustomerName = menuGroup.CustomerName;
    menuItemGroup.CustomerDescription = menuGroup.CustomerDescription;
    menuItemGroup.AllergenDetails = menuGroup.AllergenDetails;
    menuItemGroup.ProductOptionGroups = menuGroup.ProductOptionGroups;
    menuItemGroup.Products = menuGroup.Products.filter((searchFor: DisplayMenuGroupItem) => searchFor.Name === productName)
        .map((e: DisplayMenuGroupItem) => {
          const product = new DisplayProduct();
          product.DealPricePremium = e.DealPricePremium,
          product.Description = e.Description,
          product.ImageUrl = e.ImageUrl,
          product.OutOfStock = e.OutOfStock;
          product.Id = e.Id,
          product.Modifiers = e.Modifiers,
          product.Name = e.Name,
          product.CustomerName = e.CustomerName,
          product.Prices = e.Prices,
          product.IsAvailableForWantedTime = DisplayAvailableType.Unknown;
          product.AllergenDetails = e.AllergenDetails;
          product.Nutrition = e.Nutrition;
          product.NutritionSummary = e.Nutrition ? `${e.Nutrition.Calories} kcal • serves ${e.Nutrition.NumberOfPortions}` : null;
          product.Sequence = e.Sequence;

          return product;
        });

    if (menuGroup.Groups.length > 0) {
      menuItemGroup.SubGroups = menuGroup.Groups.map((group: DisplayMenuGroup) => this.ToMenuItemGroup(group, productName, occasion, orderWantedAt));
    }

    if (menuItemGroup.SubGroups.length > 0) {
      menuItemGroup.SubGroupsProducts.push(...menuItemGroup.SubGroups.flatMap((group: DisplayMenuItemGroup) => group.Products));
    }

    const tempProductsList: DisplayProduct[] = [];

    menuItemGroup.Products.reduce((products: DisplayProduct[], next: DisplayProduct) => {
      if (!products.includes(next)) {
        products.push(next);
      }

      return products;
    }, tempProductsList);

    menuItemGroup.SubGroupsProducts.reduce((products: DisplayProduct[], next: DisplayProduct) => {
      if (!products.includes(next)) {
        products.push(next);
      }

      return products;
    }, tempProductsList);

    const isBottomGroup: boolean = menuItemGroup.SubGroups
        .filter((x) => x.Products.length > 0 || x.SubGroups.length > 0)
        .every((x) => (!x.SubGroups || x.SubGroups.length === 0) && x.Products.length > 0);

    if (isBottomGroup) {
      const groupNutrition = menuItemGroup.SubGroups.flatMap((x) => x.Products.flatMap((t) => t.Nutrition).filter((y) => y));

      if (groupNutrition.length > 0) {
        const calories = groupNutrition.map((x) => x.Calories).sort((a, b) => a - b);
        const numberOfPortions = groupNutrition.map((x) => x.NumberOfPortions).sort((a, b) => a - b);

        menuItemGroup.Nutrition = {
          Calories: `${calories[0]}-${calories[calories.length - 1]} kcal`,
          NumberOfPortions: `${numberOfPortions[0]}-${numberOfPortions[numberOfPortions.length - 1]}`,
          isRange: true
        };
      } else if (menuItemGroup.SubGroups.length === 0 && menuItemGroup.Products.length > 0) {
        const nut = menuItemGroup.Products.map((x) => x.Nutrition).filter((x) => x);
        const calories = nut.map((x) => x.Calories).sort((a, b) => a - b);
        const numberOfPortions = nut.map((x) => x.NumberOfPortions).sort((a, b) => a - b);

        if (calories.length === 1 && numberOfPortions.length === 1) {
          menuItemGroup.Nutrition = {
            Calories: `${calories[0]} kcal`,
            NumberOfPortions: `${numberOfPortions[0]}`,
            isRange: false
          };
        } else if (calories.length > 1 && numberOfPortions.length > 1) {
          menuItemGroup.Nutrition = {
            Calories: `${calories[0]}-${calories[calories.length - 1]} kcal`,
            NumberOfPortions: `${numberOfPortions[0]}-${numberOfPortions[numberOfPortions.length - 1]}`,
            isRange: calories.length > 1
          };
        }
      }
    }

    menuItemGroup.FromPrice = this.ReturnHighestOrLowestPrice(tempProductsList as any, productName, occasion);
    menuItemGroup.ToPrice = this.ReturnHighestOrLowestPrice(tempProductsList as any, productName, occasion, false);

    const emptyGroups = menuItemGroup.SubGroups.filter((e: DisplayMenuItemGroup) => e.Products.length === 0);
    if (emptyGroups.length > 0) {
      menuItemGroup.SubGroups = menuItemGroup.SubGroups.filter((e: DisplayMenuItemGroup) => !(emptyGroups.map((_e: DisplayMenuItemGroup) => _e.GroupName).includes(e.GroupName)));
    }

    return menuItemGroup;
  }


  private GetProductsFromGroup(group: DisplayMenuGroup): DisplayMenuGroupItem[] {
    const products: DisplayMenuGroupItem[] = [];
    products.push(...group.Products);

    group.Groups.forEach((subGroup: DisplayMenuGroup) => {
      products.push(...this.GetProductsFromGroup(subGroup));
    });

    return products;
  }

  private ToDisplayGroup(menuGroup: DisplayMenuGroup, occasion: DisplayOccasionType, orderWantedAt: string = null): DisplayDisplayGroup {
    const foundProducts: DisplayMenuGroupItem[] = [];
    foundProducts.push(...this.GetProductsFromGroup(menuGroup));

    const uniqueNames: string[] = [];
    const uniqueProducts: DisplayMenuGroupItem[] = [];

    foundProducts.forEach((product: DisplayMenuGroupItem) => {
      if (!uniqueNames.includes(product.Name)) {
        uniqueNames.push(product.Name);
        uniqueProducts.push(product);
      }
    });

    const displayGroup = new DisplayDisplayGroup();
    displayGroup.Name = menuGroup.Name;
    displayGroup.CustomerName = menuGroup.CustomerName;
    displayGroup.CustomerDescription = menuGroup.CustomerDescription;
    displayGroup.ImageUrl = menuGroup.ImageUrl;
    displayGroup.Hidden = menuGroup.Hidden;
    displayGroup.Items = uniqueProducts.map((item: DisplayMenuGroupItem) => this.toDisplayMenuItem(foundProducts, item, occasion, orderWantedAt, menuGroup));

    displayGroup.Hidden = menuGroup.Hidden;

    displayGroup.Items.forEach((item: DisplayMenuItem) => {
      const emptySubGroups = item.SubGroup.filter((subgroup: DisplayMenuItemGroup) => subgroup.Products.length === 0 && subgroup.SubGroupsProducts.length === 0);

      if (emptySubGroups.length > 0) {
        item.SubGroup = item.SubGroup.filter((e: DisplayMenuItemGroup) => !(emptySubGroups.map((_e: DisplayMenuItemGroup) => _e.GroupName).includes(e.GroupName)));
      }
    });

    return displayGroup;
  }

  private toDisplayMenuItem(
      foundProducts: DisplayMenuGroupItem[],
      item: DisplayMenuGroupItem,
      occasion: DisplayOccasionType,
      orderWantedAt: string = null,
      menuGroup?: DisplayMenuGroup): DisplayMenuItem {
    const products: DisplayMenuGroupItem[] = foundProducts.filter((searchFor: DisplayMenuGroupItem) => searchFor.Name === item.Name);

    const menuItem = new DisplayMenuItem();
    menuItem.AllProductsInGroup = products;
    menuItem.CustomerName = item.CustomerName;
    menuItem.ImagePath = item.ImageUrl;
    menuItem.ProductDescription = item.Description;
    menuItem.ProductName = item.Name;
    menuItem.OutOfStock = item.OutOfStock;
    menuItem.RequiresUnlock = item.RequiresUnlock;
    menuItem.SubGroup = menuGroup ? menuGroup.Groups.map((group: DisplayMenuGroup) => this.ToMenuItemGroup(group, item.Name, occasion, orderWantedAt)) : [];
    menuItem.Sequence = item.Sequence;

    const sortedNutrition: Nutrition[] = products
        .map((e: DisplayMenuGroupItem) => e.Nutrition)
        .filter((e: Nutrition) => e)
        .sort((a: Nutrition, b: Nutrition) => a.Calories - b.Calories);

    if (sortedNutrition.length > 0) {
      const sortedPortions: number[] = products
          .map((e: DisplayMenuGroupItem) => e.Nutrition?.NumberOfPortions)
          .filter((e: number) => e)
          .sort((a: number, b: number) => a - b);

      menuItem.NutritionSummary = `${sortedNutrition.length > 1 ? 'From ' : ''}${sortedNutrition[0].Calories} kcal • serves ${sortedPortions[0]}`;
    }

    if (item.type === 'deal') {
      menuItem.FromPrice = this.ReturnHighestOrLowestPriceForDealLines(this.getDealLines(item as any, occasion, orderWantedAt), occasion);
      menuItem.ToPrice = this.ReturnHighestOrLowestPriceForDealLines(this.getDealLines(item as any, occasion, orderWantedAt), occasion, false);
    } else {
      menuItem.FromPrice = this.ReturnHighestOrLowestPrice(products, item.Name, occasion);
      menuItem.ToPrice = this.ReturnHighestOrLowestPrice(products, item.Name, occasion, false);
    }

    menuItem.ValidForBasket = item.ValidForBasket;
    menuItem.type = item.type;
    menuItem.Id = item.Id;

    return menuItem;
  }

  private RemoveEmptyGroups(groups: Array<DisplayMenuItemGroup>): void {
    const removeGroups: DisplayMenuItemGroup[] = [];

    groups.forEach((group: DisplayMenuItemGroup) => {
      if (this.RemoveEmptyGroup(group)) {
        removeGroups.push(group);
      }
    });

    let newGroups: DisplayMenuItemGroup[] = [];
    newGroups.push(...groups);

    if (removeGroups.length > 0) {
      removeGroups.forEach((remove: DisplayMenuItemGroup) => {
        newGroups = newGroups.filter((group: DisplayMenuItemGroup) => group !== remove);
      });
    }

    groups.forEach(() => {
      groups.pop();
    });

    const groupsToAdd = [];

    newGroups.forEach((newGroup: DisplayMenuItemGroup) => {
      const groupToAdd = groups.findIndex((group: DisplayMenuItemGroup) => group.GroupName.toLowerCase() === newGroup.GroupName.toLowerCase());

      if (groupToAdd === -1) {
        groupsToAdd.push(newGroup);
      }
    });

    groups.push(...groupsToAdd);
  }

  private RemoveEmptyGroup(group: DisplayMenuItemGroup): boolean {
    if (group.SubGroups.length > 0) {
      const removeGroups: DisplayMenuItemGroup[] = [];

      const _this = this;
      group.SubGroups.forEach((subGroup: DisplayMenuItemGroup) => {
        if (_this.RemoveEmptyGroup(subGroup)) {
          removeGroups.push(subGroup);
        }
      });

      let newGroups = [];
      newGroups.push(...group.SubGroups);

      if (removeGroups.length !== 0) {
        removeGroups.forEach((remove) => {
          newGroups = newGroups.filter((obj) => obj !== remove);
        });

        group.SubGroups.forEach(() => {
          group.SubGroups.pop();
        });

        group.SubGroups.push(...newGroups);
      }

      return (group.SubGroups.length === 0 && group.Products.length === 0);
    }

    return (group.Products.length === 0);
  }

  public GetDisplayGroups(occasion: DisplayOccasionType, orderWantedAt: string = null, refresh: boolean = false): Array<DisplayDisplayGroup> {
    if (this._displayGroups && this._displayGroups[occasion] && !refresh) {
      return this._displayGroups[occasion];
    }

    this._displayGroups[occasion] = this.Groups.map((group: DisplayMenuGroup) => this.ToDisplayGroup(group, occasion, orderWantedAt));

    return this._displayGroups[occasion];
  }

  private FilterGroupWithProducts(group: DisplayMenuGroup, productIds: Array<string>): DisplayMenuGroup {
    const newGroup = new DisplayMenuGroup();
    newGroup.Name = group.Name;
    newGroup.CustomerName = group.CustomerName;
    newGroup.CustomerDescription = group.CustomerDescription;
    newGroup.AllergenDetails = group.AllergenDetails;
    newGroup.Groups = group.Groups.map((menuGroup: DisplayMenuGroup) => this.FilterGroupWithProducts(menuGroup, productIds));
    newGroup.Products = group.Products.filter((e: DisplayMenuGroupItem) => productIds.includes(e.Id));
    newGroup.ProductOptionGroups = group.ProductOptionGroups;

    return newGroup;
  }

  private getDealLines(deal: DisplayDeal, occasion: DisplayOccasionType, orderWantedAt: string = null): DisplayDealLineOptions[] {
    let lineNumber = 1;
    const dealOptions: DisplayDealLineOptions[] = [];

    deal.Lines.forEach((dealLine: DisplayDealLine) => {
      let productListIds: string[];
      const productListIdsTemp = this.ProductLists.find((productList: DisplayProductList) => productList.Id.toString() === dealLine.ProductsListId.toString());

      if (productListIdsTemp) {
        productListIds = productListIdsTemp.ProductIds;
      }

      const options = new DisplayDealLineOptions();
      options.LineNumber = lineNumber;
      options.LineType = dealLine.LineType;
      options.LineTypeValue = dealLine.Value;

      const dealLineOptions: DisplayDisplayGroup[] = this.Groups
          .map((x: DisplayMenuGroup) => {
            const group = this.ToDisplayGroup(this.FilterGroupWithProducts(x, productListIds), occasion, orderWantedAt);
            group.Items.forEach((menuItem: DisplayMenuItem) => {
              this.RemoveEmptyGroups(menuItem.SubGroup);
            });
            return group;
          });

      const dealLineOption = new DisplayDisplayGroup();
      dealLineOption.Name = 'Web';

      if (dealLineOptions.length === 0) {
        dealLineOption.Items = productListIds.map((id: string) =>
          DisplayProduct.toMenuItem(this.Products.find((product: DisplayProduct) => product.Id === id), occasion));
      }

      options.DisplayGroups = dealLineOptions.length === 0 && dealLineOption.Items.length > 0 ? [dealLineOption] : dealLineOptions;
      dealOptions.push(options);

      lineNumber++;
    });

    return dealOptions;
  }

  public GetDealsGroups(occasion: DisplayOccasionType, orderWantedAt: string = null, refresh: boolean = false): DisplayDealItem[] {
    if (this._dealsGroups && this._dealsGroups[occasion] && !refresh) {
      const results = this._dealsGroups[occasion];
      return results;
    }

    const menu = this;
    const dealItems: DisplayDealItem[] = [];

    this.Deals.forEach((deal: DisplayDeal) => {
      const dealOptions = this.getDealLines(deal, occasion, orderWantedAt);

      const dealItem = new DisplayDealItem();
      dealItem.Id = deal.Id;
      dealItem.DealName = deal.Name;
      dealItem.CustomerName = deal.CustomerName;
      dealItem.DealLines = dealOptions;
      dealItem.ImagePath = deal.ImageUrl;
      dealItem.ProductDescription = deal.Description;
      dealItem.FromPrice = menu.ReturnHighestOrLowestPriceForDealLines(dealOptions, occasion);
      dealItem.ToPrice = menu.ReturnHighestOrLowestPriceForDealLines(dealOptions, occasion, false);
      dealItem.IsAvailableForWantedTime = DisplayAvailableType.Unknown;
      dealItem.AvailableOccasions = deal.Occasions;
      dealItem.ValidForBasket = deal.ValidForBasket;
      dealItem.OutOfStock = deal.OutOfStock;
      dealItem.RequiresUnlock = deal.RequiresUnlock;

      dealItems.push(dealItem);
    });

    this._dealsGroups[occasion] = dealItems;
    return this._dealsGroups[occasion];
  }

  public GetFullProductPathFromId(productId: string, occasion: DisplayOccasionType): IProductPath {
    let foundMenuItem: DisplayMenuItem;
    const response = {
      combined: [],
      name: '',
      options: ''
    };

    if (!this._displayGroups[occasion]) {
      this.GetDisplayGroups(occasion);
    }

    this._displayGroups[occasion].forEach((displayGroup: { Items: any[]; }) => {
      if (foundMenuItem) {
        return;
      }

      foundMenuItem = displayGroup.Items.find((itemGroup) => itemGroup.AllProductsInGroup.map((item: { Id: any; }) => item.Id).includes(productId));
      return foundMenuItem;
    });

    if (!foundMenuItem) {
      const foundProduct = this.Products.find((product: DisplayProduct) => product.Id === productId);

      if (foundProduct) {
        foundMenuItem = DisplayProduct.toMenuItem(foundProduct, occasion);
      } else {
        return response;
      }
    }

    if (foundMenuItem.CustomerName) {
      response.name = foundMenuItem.CustomerName;
    } else {
      response.name = foundMenuItem.ProductName;
    }

    const options: string[] = [];

    if (foundMenuItem.SubGroup.length > 0) {
      let foundName = false;

      foundMenuItem.SubGroup.forEach((subGroup: DisplayMenuItemGroup) => {
        if (foundName) {
          return;
        }

        if (subGroup.SubGroups.length > 0) {
          subGroup.SubGroups.forEach((subGroup2: DisplayMenuItemGroup) => {
            if (foundName) {
              return;
            }

            if (subGroup2.Products.map((product: DisplayProduct) => product.Id).includes(productId)) {
              if (subGroup2.CustomerName) {
                options.push(subGroup2.CustomerName);
              } else {
                options.push(subGroup2.GroupName);
              }

              if (subGroup.CustomerName) {
                options.push(subGroup.CustomerName);
              } else {
                options.push(subGroup.GroupName);
              }

              foundName = true;
            }
          });
        } else {
          if (subGroup.Products.map((product: DisplayProduct) => product.Id).includes(productId)) {
            if (subGroup.CustomerName) {
              options.push(subGroup.CustomerName);
            } else {
              options.push(subGroup.GroupName);
            }

            foundName = true;
          }
        }
      });
    }

    response.combined = [response.name, ...options];
    response.options = options.join(', ');

    return response;
  }
}
