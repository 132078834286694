// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --footer-background-colour: #333333;
  --footer-link-colour: #fff;
  --nav-bar-background-colour: #fff;
  --product-content-padding: 0 10px;
}

.future-wanted-time-picker h1, .future-wanted-time-picker h2 {
  font-family: Tenant-Bold, Courier, Helvetica, sans-serif;
  font-size: 20px;
  text-align: center;
}

ul.slots-list {
  margin-bottom: 0;
  list-style: none;
  padding: 0;
  font-size: 25px;
  max-height: 250px;
  min-height: 40px;
  overflow-y: auto;
}
ul.slots-list li {
  color: #E3000E;
  text-align: center;
  cursor: pointer;
  height: 43px;
  line-height: 43px;
}
ul.slots-list li.selected-item {
  background: #E3000E;
  border-radius: 12px;
  color: #fff;
}

.date-dropdown {
  width: calc(100% - 40px);
  margin: 20px 20px 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
