import { Component, Input } from '@angular/core';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { BasketItem } from '@app/models/basket-item';
import { Charge } from '@app/models/Charge';
import { ChargeTypes } from '@app/models/charge-types';
import { DisplayMenu } from '@app/models/display-menu';
import { DisplayProduct } from '@app/models/display-product';
import { Issue } from '@app/models/Issue';
import { ToastTypes } from '@app/models/ToastTypes.enum';
import { BasketService } from '@app/services/basket.service';
import { ToastsService } from '@app/services/toasts.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-basket-product-item',
  styleUrls: ['./../../shared-basket-styles.scss'],
  templateUrl: './basket-product-item.component.html'
})
export class BasketProductItemComponent extends AndroWebCoreComponent {
  @Input() public item: BasketItem;
  @Input() public basketItemIndex: number;
  @Input() public basketIsModifiable: boolean;

  @Input() private displayMenu: DisplayMenu;

  constructor(
    private _basketService: BasketService,
    private _toastsService: ToastsService
  ) {
    super();
  }

  /**
  * returns the image url for a given product
  * @param productId - the products id
  */
  public getProductImage(productId: string): string {
    const product = this.displayMenu.Products.find((p: DisplayProduct) => p.Id === productId);
    return product?.ImageUrl ? product.ImageUrl : '';
  }

  /**
  * calculates the price of a basket item
  * @param product - the basket item
  */
  public calculateProductPrice(product: BasketItem): number {
    let number = this.calculateModifiersPrice(product?.Charges) ?? 0;

    product?.Charges.filter((c: Charge) => c.ChargeType === ChargeTypes.ProductCharge)
        .forEach((c: Charge) => number += c.Total);

    return number;
  }

  /**
  * returns whether the basket item has issues or not
  * @param basketItem - the basket item
  */
  public hasItemIssues(basketItem: BasketItem): boolean {
    return basketItem.Issues?.length > 0;
  }

  /**
  * takes an array of basket issues and returns only unique (by IssueType) issues
  * @issues issue - the basket issues
  */
  public getUniqueItemIssues(issues: Issue[]): Issue[] {
    const response: Issue[] = [];

    if (Array.isArray(issues)) {
      issues?.forEach((y: Issue) => {
        if (response.findIndex((x: Issue) => x.IssueType === y.IssueType) === -1) {
          response.push(y);
        }
      });
    }

    return response;
  }

  /**
  * removes an product from the basket
  * @param basketItemId - the basket item id to be removed
  */
  public removeItem(basketItemId: string): void {
    this._basketService.updateProductQuantity(basketItemId, 0)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          error: () => {
            this._toastsService.showToast(ToastTypes.error, 'Could not remove item', 'Sorry!');
          }
        });
  }

  /**
  * calculates the combined price of all modifiers on a basket item
  * @param charges - the charges on the basket item
  */
  private calculateModifiersPrice(charges: Charge[]): number {
    let number = 0;

    charges?.filter((c: Charge) => c.ChargeType === ChargeTypes.ChargeableModifier)
        .forEach((c: Charge) => number += c.Total);

    return number;
  }
}
