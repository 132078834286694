import { Pipe, PipeTransform } from '@angular/core';
import { DisplayMenuItem } from '@app/models/display-menu-item';
import { DisplayOccasionType } from '@app/models/display-occasion-type';

@Pipe({
  name: 'ProductsShownOnMenuFilter',
  pure: false,
})
export class ProductsShownOnMenuFilter implements PipeTransform {
  public transform(items: DisplayMenuItem[], occasion: DisplayOccasionType): DisplayMenuItem[] {
    return items.filter((x: DisplayMenuItem) => !x.RequiresUnlock && x.AvailableOccasions?.includes(occasion));
  }
}
