// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --footer-background-colour: #333333;
  --footer-link-colour: #fff;
  --nav-bar-background-colour: #fff;
  --product-content-padding: 0 10px;
}

router-outlet + app-deals {
  flex: 1;
  overflow: hidden;
}

.deals-page {
  height: 100%;
  overflow-y: auto;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}
.deals-page-centered {
  height: 100%;
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 50px;
}

.deals-page-footer {
  height: 45px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  z-index: 1;
  padding-top: 1px;
  min-height: 45px;
}
.deals-page-footer-button {
  cursor: pointer;
  display: inline-block;
  line-height: unset;
  width: 100%;
  background-color: #E3000E;
  height: 44px;
  text-transform: uppercase;
  border-radius: 0 !important;
  border: none !important;
  padding: 0 15px !important;
}

.deals-page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  padding: 0 30px;
  position: relative;
}
.deals-page-header h1 {
  margin: 0;
  font-family: Tenant-Regular, Courier, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 0.9;
  text-align: center;
}
.deals-page-header .mat-icon {
  position: absolute;
  right: 10px;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 26px;
}

.deals-page-header {
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  z-index: 1;
}

.deals-page-content {
  max-height: calc(100vh - 235px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  align-items: center;
}
.deals-page-content-displayed {
  display: flex;
  flex-direction: row;
  padding: 20px 5%;
  justify-content: center;
}
.deals-page-content-displayed-container {
  max-width: 1170px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.deals-page-content-displayed-image {
  width: 100%;
}
.deals-page-content-displayed-image-wrapper {
  width: 100%;
  display: flex;
  padding-right: 10px;
}
.deals-page-content-displayed-info {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.deals-page-content-displayed-info-description {
  margin: 0;
  padding: 0 5px;
}
.deals-page-content-displayed-info-name {
  font-size: 24px;
  font-weight: bold;
}
.deals-page-content-displayed-info-price-amount {
  font-size: 30px;
  font-weight: bold;
}
.deals-page-content-displayed-info-image-wrapper {
  display: flex;
  min-width: 40%;
  background-color: #F5F5F5;
}

.deals-page-content-selections-list-wrapper {
  padding: 12px 2px 0;
  width: 100%;
  list-style-type: none;
  margin: 0 auto;
}

.deals-page-content-selections-list-item {
  background-color: #F5F5F5;
  border: thin solid #ebebeb;
  width: 100%;
  margin-bottom: 3px;
}
.deals-page-content-selections-list-item-content {
  padding: 20px;
}
.deals-page-content-selections-list-item-header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
}
.deals-page-content-selections-list-item-header .mat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

ul.deal-line-container {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 10px 5px 5px;
}

.deal-line-details {
  padding: 5px;
  line-height: 1.25;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.deal-line-details h2 {
  margin-bottom: 0;
  font-size: 15px;
}

.deal-line-details span {
  font-size: 13px;
}

.deal-line {
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.14), 0 0 5px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  margin: 0 3px 8px;
  width: 100%;
  overflow: hidden;
  min-height: 60px;
  padding: 2px;
}
.deal-line img {
  width: 100px;
}
.deal-line-button {
  display: flex;
  align-items: center;
  background-color: #0080F0;
  color: #fff;
  height: 100%;
  border: none;
  width: 50px;
  justify-content: center;
  border-radius: 3px;
}

.deal-line-selected {
  background-color: #D5E8D4;
}
.deal-line-selected .deals-page-content-selections-list-item-header-label {
  color: #026700;
  font-weight: bold;
}
.deal-line-selected .mat-icon {
  color: #026700;
}

.deal-item-unavailable {
  opacity: 0.75;
}

@media screen and (min-width: 1023px) {
  .deals-page-content::-webkit-scrollbar {
    width: 10px;
  }
  .deals-page-content::-webkit-scrollbar-thumb {
    background: #BBBAB9;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    border: 0px solid #fff;
  }
}
@media screen and (max-width: 1023px) {
  .deals-page-content-displayed-container {
    flex-direction: column;
    max-width: 100%;
  }
}
@media screen and (max-width: 1278.75px) {
  .deals-page.isModal .deals-page-content-displayed-container {
    flex-direction: column;
    max-width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .deals-page-content {
    max-height: unset;
    height: calc(100vh - 90px);
  }
  .deals-page-content-displayed-container {
    flex-direction: column;
    max-width: 100%;
  }
  .deals-page-content-displayed-image {
    height: unset;
  }
  .deals-page-content-displayed-image-wrapper {
    flex: 1;
    justify-content: center;
    max-width: unset;
    padding-right: 0px;
  }
  .deals-page-content-displayed-info {
    padding-top: 10px;
  }
  .deals-page-content-selections-list-item-content {
    padding: 0 0 10px;
  }
}
@media screen and (max-width: 598.75px) {
  .deals-page-content-displayed-container {
    flex-direction: column;
    max-width: 100%;
  }
  .deals-page-content-displayed-image {
    height: unset;
  }
  .deals-page-content-displayed-image-wrapper {
    flex: 1;
    justify-content: center;
    max-width: unset;
    padding-right: 0px;
  }
  .deals-page-content-displayed-info {
    padding-top: 10px;
  }
  .deals-page-content-selections-list-item-content {
    padding: 0 0 10px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
