import { DisplayOccasionType } from '@app/models/display-occasion-type';

/**
 * Input data for opening the deals component in a modal.
 * @class DealsComponentModalData
 * @export
 */
export class DealsComponentModalData {
    dealId: string;
    siteId: string;
    occasion: DisplayOccasionType;

    constructor(dealId: string, siteId: string, occasion: DisplayOccasionType) {
      this.dealId = dealId;
      this.siteId = siteId;
      this.occasion = occasion;
    }
}
