import { Router } from '@angular/router';
import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Site } from '@app/models/site';
import { Contact } from '@app/models/contact';
import { ModifierType } from '@app/models/modifier-type';
import { User } from '@app/models/user';
import { DisplayMenu } from '@app/models/display-menu';
import { DisplayOccasionType } from '@app/models/display-occasion-type';
import { DisplayProduct } from '@app/models/display-product';
import { DisplayDeal } from '@app/models/display-deal';
import { Basket } from '@app/models/basket';
import { UserService } from '@app/services/user.service';
import { MenuService } from '@app/services/menu.service';
import { SiteService } from '@app/services/site.service';
import { BasketService } from '@app/services/basket.service';
import { AnalyticsService } from '@app/services/analytics.service';
import { ToastsService } from '@app/services/toasts.service';
import { BasketItem } from '@app/models/basket-item';
import { BasketDeal } from '@app/models/basket-deal';
import { BasketDealItem } from '@app/models/basket-deal-item';
import { Charge } from '@app/models/Charge';
import { ChargeTypes } from '@app/models/charge-types';
import { Issue } from '@app/models/Issue';
import { IssueTypes } from '@app/models/issue-types';
import { OrderOccasion } from '@app/models/order-occasion';
import { QuantityOf } from '@app/models/quantity-of';
import { BasketDealDisplayedUI } from '@app/models/basket-deal-displayed-ui';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { NavigatorService } from '@app/core/navigator.service';
import { NavigationSectionType } from '@app/models/navigation/navigation-section-type';
import { ToastTypes } from '@app/models/ToastTypes.enum';
import { lastValueFrom, takeUntil } from 'rxjs';
import { IDictionary } from '@app/models/IDictionary';
import { CustomerLoyaltyState } from '@app/models/customer-loyalty-state';

@Component({
  selector: 'basket-component',
  styleUrls: ['./basket.component.scss'],
  templateUrl: './basket.component.html'
})
export class BasketComponent extends AndroWebCoreComponent implements OnInit {
  @Output() private allDone = new EventEmitter<null>();

  @Input() public displayTitle: boolean = true;
  @Input() public showBasketToggle: boolean = true;
  @Input() public showCheckout: boolean;
  @Input() public disableBasketEditing: boolean;
  @Input() public orderComplete: boolean;
  @Input() public displayChargesOnly: boolean;
  @Input() public disableVoucherEditing: boolean;

  public orderOccasion = OrderOccasion;
  public currentBasket: Basket;
  public orderedItems: (BasketItem | BasketDeal)[];
  public isThankYouPage: boolean;
  public basketIsModifiable: boolean;
  public showOccasionsToggle: boolean;
  public supportsBothOccasions: boolean;
  public postCodeWithinDelivery: boolean;
  public basketTotal: number;
  public loyaltyDiscount: number;
  public fastestDeliveryTime: number;
  public fastestCollectionTime: number;
  public displayMenu: DisplayMenu;
  public user: User;
  public currentSite: Site;
  public earnablePointsForCurrentBasket: number;
  public usersLoyaltyPoints: number;

  private isUpdatingBasket: boolean;
  private basketOccasionChanged: boolean;
  private productIdToDisplayProduct: IDictionary<DisplayProduct> = {};
  private dealIdToDisplayDeal: IDictionary<DisplayDeal> = {};
  private _userWelcomePoints: number;

  constructor(
    private router: Router,
    private userService: UserService,
    private menuService: MenuService,
    private siteService: SiteService,
    private _userService: UserService,
    private basketService: BasketService,
    private toastsService: ToastsService,
    private navigatorService: NavigatorService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit() {
    this.trackUserLoyaltyPoints();

    // postCodeWithinDelivery enables/disabled delivery occasion toggle at the top of the basket
    this.subscriptions$['BasketComponent-ngOnInit-basketService-postcodeWithinDelivery'] =
      this.basketService.postcodeWithinDelivery.subscribe((value: boolean) => {
        this.postCodeWithinDelivery = value;
      });

    this.isThankYouPage = this.router.url.startsWith('/checkout') && this.router.url.includes('/thank-you');

    this.setupSiteAndServiceEstimates();
    this.setupBasketAndMenu();

    this.subscriptions$['BasketComponent-ngOnInit-userService-user'] =
      this.userService.currentUser$.subscribe((user: User) => this.user = user);
  }

  /**
  * if there are no minimum spend issues and the user isn't logged in it navigates to login page or it navigates to checkout
  * But if there are minimum spend issues it displays a friendly toast to the user explaining that they have minimum spend issues
  */
  public goToCheckout(): void {
    if (!this.user?.Id) {
      this.login();
      return;
    }

    const basketIssues = this.currentBasket.Issues.filter((issue) => issue.IssueType === IssueTypes.TotalIsLessThanMinimumSpend);

    if (basketIssues.length > 0) {
      this.toastsService.showToast(ToastTypes.error, 'Sorry - Minimum spend requirements have not been met. Please add more items and try again');
      return;
    }

    if (this.currentBasket.Items.length > 0 || this.currentBasket.Deals.length > 0) {
      this.analyticsService.pageView('/initiateCheckout', 'Logged in');
      this.closeBasket();
      this.router.navigate(['/checkout', this.currentBasket.Id]);
    }
  }

  /**
  * closes the basket modal
  */
  public closeBasket(): void {
    this.allDone.emit();
  }

  /**
  * updates the occasion of the current basket
  * @param $event - a MatButtonToggleChange event with the order occasion to be used
  */
  public toggleBasketOccasionChange($event: MatButtonToggleChange): void {
    if (this.currentBasket.Occasion === $event.value) {
      return;
    }

    if (this.currentBasket.CompatibleOccasions.length === 0 || this.currentBasket.CompatibleOccasions.includes($event.value)) {
      this.currentBasket.Occasion = ($event.value as OrderOccasion);

      let defaultContact: Contact;

      if (this.user && ($event.value as OrderOccasion) === OrderOccasion.Delivery) {
        defaultContact = this.user.Contacts.find((contact: Contact) => contact.IsDefault);
      }

      this.displayMenu.GetDisplayGroups(($event.value as DisplayOccasionType));
      this.basketService.setOccasionOfCurrentBasket(this.currentSite.Id, ($event.value as OrderOccasion), defaultContact?.PostCode);
      this.basketOccasionChanged = true;

      const location: string = this.navigatorService.getLocationBySiteId( this.currentSite.Id);
      const routePath: string = this.navigatorService.generateRoutePath(location, ($event.value as OrderOccasion), NavigationSectionType.Menu);
      this.router.navigate([routePath]);
    }
  }

  /**
  * determines whether the checkout button should be enabled or disabled
  */
  public shouldDisableCheckout(): boolean {
    const itemsWithIssues = this.currentBasket.Items
        .map((item: BasketItem) => item.Issues)
        .filter((issues: any) => issues?.length > 0);

    const allDealItems: BasketDealItem[] = this.currentBasket.Deals.map((item: BasketDeal) => item.Items).reduce((a, b) => a.concat(b), []);
    const dealItemsWithIssues = allDealItems?.filter((item: BasketDealItem) => item.Issues?.length > 0);

    const basketIssues: Issue[] = this.currentBasket.Issues?.filter((issue: Issue) =>
      issue.IssueType !== IssueTypes.TotalIsLessThanMinimumSpend &&
      issue.IssueType !== IssueTypes.SiteDoesNotDeliverToDeliveryLocation &&
      issue.IssueType !== IssueTypes.DeliveryOccasionRequiresDeliveryLocation &&
      issue.IssueType !== IssueTypes.OccasionIsNotAvailableAtWantedTime &&
      issue.IssueType !== IssueTypes.SiteIsNotAvailableForAsapOrdersNow &&
      issue.IssueType !== IssueTypes.SiteDoesNotAcceptAsapOrdersForOccasion
    );

    return this.isUpdatingBasket || basketIssues?.length > 0 || itemsWithIssues?.length > 0 || dealItemsWithIssues?.length > 0;
  }

  /**
  * determines whether the you could earn text shows or not
  */
  public showEarnableLoyaltyPoints(): boolean {
    const maxPoints: number = this.tenant?.LoyaltyScheme?.EarningRules?.MaxPointsPerCustomer;
    return maxPoints ? this.usersLoyaltyPoints < maxPoints : true;
  }

  /**
  * orders items in a basket alphabetically
  * @param basket - the basket
  */
  private orderBasketItems(basket: Basket): void {
    this.orderedItems = [];

    const deals: BasketDeal[] = basket.Deals.sort((a: BasketDeal, b: BasketDeal) => {
      const dealA: DisplayDeal = this.getDealById(a.DealId);
      const dealB: DisplayDeal = this.getDealById(b.DealId);

      if (dealA && dealB) {
        if (dealA.Name < dealB.Name) {
          return -1;
        } else if (dealA.Name > dealB.Name) {
          return 1;
        }
      }

      return -1;
    });

    const products: BasketItem[] = basket.Items
        .sort((a: BasketItem, b: BasketItem) => {
          const productA: DisplayProduct = this.getProductById(a.Product.Item);
          const productB: DisplayProduct = this.getProductById(b.Product.Item);

          if (productA && productB) {
            if (productA.Name < productB.Name) {
              return -1;
            } else if (productA.Name > productB.Name) {
              return 1;
            }
          }

          return -1;
        });

    this.orderedItems = this.addUiDataToBasketItem(deals, products);
    this.checkBasketItemsOccasions();
  }

  /**
   * gets the current site and find the fastest delivery & occasion times
   */
  private setupSiteAndServiceEstimates(): void {
    this.subscriptions$['BasketComponent-ngOnInit-siteService-currentSite'] =
    this.siteService.currentSite.subscribe(async (currentSite: Site) => {
      let fastestDeliveryTime: number;
      let fastestCollectionTime: number;

      if (currentSite) {
        this.currentSite = currentSite;

        fastestDeliveryTime = this.getFastestAvailableTime(currentSite.EstimatedDeliveryTime);
        fastestCollectionTime = this.getFastestAvailableTime(currentSite.EstimatedCollectionTime);
      } else {
        const sites: Site[] = await lastValueFrom(this.siteService.getSites());

        if (this.currentBasket) {
          const site: Site = sites.find((s: Site) => s.Id === this.currentBasket.SiteId);

          if (site) {
            this.currentSite = site;
            fastestDeliveryTime = this.getFastestAvailableTime(site.EstimatedDeliveryTime);
            fastestCollectionTime = this.getFastestAvailableTime(site.EstimatedCollectionTime);
          }
        }
      }

      if (this.currentSite?.OccasionsSupported) {
        this.supportsBothOccasions = this.currentSite.OccasionsSupported.includes(OrderOccasion.Collection) &&
          this.currentSite.OccasionsSupported.includes(OrderOccasion.Delivery);
      } else {
        this.supportsBothOccasions = this.tenant.OccasionsSupported.includes(OrderOccasion.Collection) &&
          this.tenant.OccasionsSupported.includes(OrderOccasion.Delivery);
      }

      this.fastestDeliveryTime = fastestDeliveryTime ? fastestDeliveryTime : 30;
      this.fastestCollectionTime = fastestCollectionTime ? fastestCollectionTime : 15;
    });
  }

  /**
   * uses baskets charges & wanted time to update the dom, and checks if there are any issues.
   */
  private setupBasketAndMenu(): void {
    this.subscriptions$['BasketComponent-ngOnInit-basketService-currentBasket'] =
      this.basketService.currentBasket.subscribe(async (basket: Basket) => {
        if (!basket) {
          return;
        }

        if (this.router.url.toLowerCase().includes('/menu')) {
          basket.IsModifiable = true;
        }

        this.currentBasket = basket;
        this.basketTotal = this.getBasketCharge(basket, ChargeTypes.Total);
        this.loyaltyDiscount = Math.abs(this.getBasketCharge(basket, ChargeTypes.LoyaltyPointsDiscount));
        this.earnablePointsForCurrentBasket = this.getBasketEarnablePoints();

        this.basketIsModifiable = (!this.disableBasketEditing && basket.IsModifiable);

        if (this.basketOccasionChanged) {
          this.basketService.refreshMenu(basket);
          this.basketOccasionChanged = false;
        }

        if (this.currentBasket.WantedTimeUtc) {
          this.currentBasket.WantedTimeUtc = this.replaceZuluTime(basket.WantedTimeUtc);
        }

        this.displayMenu = await this.menuService.getDisplayMenuByBasket(this.currentBasket);
        this.orderBasketItems(this.currentBasket);
      });
  }

  /**
  * adds the displayedUI property to products and deals and returns them in a BasketItem array to be displayed on the UI
  * @param products - all products in the basket
  * @param deals - all deals in the basket
  */
  private addUiDataToBasketItem(deals: BasketDeal[], products: BasketItem[]): (BasketItem | BasketDeal)[] {
    const items: (BasketItem | BasketDeal)[] = [];

    deals.forEach((deal: BasketDeal, index: number) => {
      deal.displayedUI = new BasketDealDisplayedUI();
      deal.displayedUI.type = 'deal';
      deal.displayedUI.index = index;

      let hasIssues = 0;
      const hasModifiers = [];

      deal.Items.forEach((item: BasketDealItem) => {
        const additionModifiers = item.Charges.filter((charge: Charge) => charge.ChargeType === ChargeTypes.ChargeableModifier);

        hasIssues += item.Issues?.length;

        if (item.Issues?.length > 0) {
          item.issueTypes = item.Issues;
        }

        if (additionModifiers?.length > 0) {
          hasModifiers.push(item);
        }
      });

      deal.displayedUI.hasIssues = hasIssues > 0;
      deal.displayedUI.hasModifierCharges = hasModifiers?.length > 0;
      items.push(deal);
    });

    products.forEach((product: BasketItem) => {
      const modifierTypes: ModifierType[] = this.getModifierTypesForBasketItem(product);

      const modifiers = {
        addons: modifierTypes.filter((x: ModifierType) => !x.isRemoved),
        removes: modifierTypes.filter((x: ModifierType) => x.isRemoved)
      };

      product.displayedUI = {
        hasAddOns: modifiers.addons.length > 0 || modifiers.removes.length > 0,
        hasIssues: product.Issues?.length > 0,
        hasModifierCharges: false,
        modifiers: modifiers,
        name: this.displayMenu.GetFullProductPathFromId(product.Product.Item, this.currentBasket.Occasion as any as DisplayOccasionType),
        type: 'product',
      };
      product.issueTypes = [];

      items.push(product);
    });

    return items;
  }

  /**
  * returns a product that matches the given id
  * @param productId - the id of the product
  */
  private getProductById(productId: string): DisplayProduct {
    if (this.productIdToDisplayProduct[productId]) {
      return this.productIdToDisplayProduct[productId];
    }
    const products = this.displayMenu.Products.filter((product) => product.Id === productId);

    if (products?.length > 0) {
      this.productIdToDisplayProduct[productId] = products[0];
      return products[0];
    }

    return null;
  }

  /**
  * emits a return url string in an event that initiates the login process
  */
  private login(): void {
    const hasMinSpendIssues = this.currentBasket.Issues.filter((issue) => issue.IssueType === IssueTypes.TotalIsLessThanMinimumSpend).length > 0;
    this.analyticsService.pageView('/initiateCheckout', 'Not logged in');
    this.userService.login(false, hasMinSpendIssues ? `/menu/${this.currentSite.Id}/${this.currentBasket.Occasion}` : `/checkout/${this.currentBasket.Id}`);
    this.closeBasket();
  }

  /**
  * Finds the product from a given basketItem and checks which occasions it is available for
  * @param item - the basket item
  */
  private testBasketProductItemsOccasion(item: BasketItem | BasketDealItem): { product: DisplayProduct; occasion: string } {
    const displayedProduct = this.getProductById(item.Product.Item);
    let occasion: string;

    const includesDelivery = displayedProduct?.AvailableOccasions.includes(DisplayOccasionType.Delivery);
    const includesCollection = displayedProduct?.AvailableOccasions.includes(DisplayOccasionType.Collection);

    if (includesDelivery && includesCollection) {
      occasion = 'both';
    } else if (includesDelivery && !includesCollection) {
      occasion = 'delivery';
    } else if (!includesDelivery && includesCollection) {
      occasion = 'collection';
    } else {
      occasion = 'empty';
    }

    return {
      occasion: occasion,
      product: displayedProduct,
    };
  }

  /**
  * returns the estimated time as a number in terms of minutes
  * @param estimatedTime - the fasted estimated time
  */
  private getFastestAvailableTime(estimatedTime: string): number {
    const splitTime = estimatedTime.split(':');
    const time = (Number(splitTime[0]) * 3600) + (Number(splitTime[1]) * 60) + Number(splitTime[2]);

    return (time / 60);
  }

  /**
  * returns all removed and added modifiers on a basket item in separate arrays;
  * @param basketItem - the basket item
  */
  private getModifierTypesForBasketItem(basketItem: BasketItem): ModifierType[] {
    const displayProduct = this.displayMenu.Products.find((product) => product.Id === basketItem.Product.Item);

    if (!displayProduct) {
      return [];
    }

    const modifiers: ModifierType[] = [];
    const modifierIds = basketItem.Modifiers.map((x: QuantityOf) => x.Item);

    // Removed modifiers
    displayProduct.Modifiers.Default
        .filter((mod: DisplayProduct) => !modifierIds.includes(mod.Id))
        .forEach((mod: DisplayProduct) => {
          modifiers.push({
            isRemoved: true,
            product: this.displayMenu.Products.find((x: DisplayProduct) => x.Id === mod.Id)
          });
        });

    const uniqueModifiers: QuantityOf[] = [];

    basketItem.Modifiers.forEach((modifier: QuantityOf) => {
      const index = uniqueModifiers.findIndex((x: QuantityOf) => x.Item === modifier.Item);

      if (index > -1) {
        uniqueModifiers[index].Quantity += 1;
      } else {
        uniqueModifiers.push(modifier);
      }
    });

    uniqueModifiers.forEach((modifier) => {
      const isDefault = displayProduct.Modifiers.Default.map((mod) => mod.Id).includes(modifier.Item);

      if (isDefault && modifier.Quantity === 1) {
        return;
      }

      if (isDefault) {
        modifier.Quantity--;
      }

      modifiers.push({
        isRemoved: false,
        product: this.displayMenu.Products.find((x: DisplayProduct) => x.Id === modifier.Item),
        quantity: modifier.Quantity
      });
    });

    return modifiers;
  }

  /**
  * returns a deal that matches the given id
  * @param dealId - the id of the deal
  */
  private getDealById(dealId: string): DisplayDeal {
    if (this.dealIdToDisplayDeal[dealId]) {
      return this.dealIdToDisplayDeal[dealId];
    }

    const deal = this.displayMenu?.Deals?.find((d: DisplayDeal) => d.Id === dealId);

    if (deal) {
      this.dealIdToDisplayDeal[dealId] = deal;
      return deal;
    }

    return null;
  }

  /**
  * checks if all items in the basket at compatible for both order occasions
  */
  private checkBasketItemsOccasions(): void {
    const result = {
      both: [],
      collection: [],
      delivery: [],
      empty: [],
    };

    this.orderedItems.forEach((orderedBasketItem: BasketItem | BasketDeal) => {
      if (orderedBasketItem.displayedUI.type.toLowerCase() === 'deal') {
        const lineItems: BasketDealItem[] = (orderedBasketItem as BasketDeal).Items;

        if (lineItems) {
          const lineOccasion = Array.from(new Set(lineItems.map((x) => this.testBasketProductItemsOccasion(x).occasion)).values());
          result[lineOccasion[0]].push(orderedBasketItem);
        }
        return;
      }

      const displayProduct = this.testBasketProductItemsOccasion(orderedBasketItem as BasketItem);
      result[displayProduct.occasion].push(displayProduct.product);
    });

    this.showOccasionsToggle = result.both.length === this.orderedItems.length;
  }

  /**
  * returns the amount of a given charge on a given basket
  * @param basket - the basket
  * @param chargeType - the charge type that needs to be returned
  */
  private getBasketCharge(basket: Basket, chargeType: ChargeTypes): number {
    const amount = basket.Charges?.find((charge) => charge.ChargeType === chargeType)?.Price.Amount;
    return amount ? amount : 0;
  }

  /**
  * returns the amount of points a user can earn from their current basket
  */
  private getBasketEarnablePoints(): number {
    let potentialPoints = this.currentBasket.LoyaltyPointsValue + (this._userWelcomePoints ?? 0);
    const earningRules = this.tenant?.LoyaltyScheme?.EarningRules;

    if (earningRules?.MaxPointsPerCustomer) {
      const maxRemainingPoints: number = earningRules.MaxPointsPerCustomer - (this.usersLoyaltyPoints ?? 0);

      if (potentialPoints > maxRemainingPoints) {
        potentialPoints = maxRemainingPoints;
      }
    }

    return potentialPoints ?? 0;
  }

  /**
   * tracks the users loyalty points
   */
  private trackUserLoyaltyPoints(): void {
    this._userService.getUsersLoyalty()
        .pipe(takeUntil(this.destroy$))
        .subscribe((x: CustomerLoyaltyState) => {
          this.usersLoyaltyPoints = x.PointsBalance ?? 0;
          this._userWelcomePoints = x.WelcomePointsAvailable ?? 0;

          if (this.currentBasket) {
            this.earnablePointsForCurrentBasket = this.getBasketEarnablePoints();
          }
        });
  }
}
