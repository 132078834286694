// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --footer-background-colour: #333333;
  --footer-link-colour: #fff;
  --nav-bar-background-colour: #fff;
  --product-content-padding: 0 10px;
}

.delete-icon {
  margin: 0 5px;
  cursor: pointer;
}

.loyalty-button {
  border-color: #35793e;
  color: #35793e;
}

.loyalty-button.mat-mdc-outlined-button:disabled {
  border-color: #b9e0be;
  color: #b9e0be;
}

.basket-item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.basket-item-image {
  max-width: 80px;
  min-width: 80px;
}

.basket-item-image-container {
  padding-top: 5px;
}

.basket-item-image-container.deal-image-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 80px;
  width: 80px;
}

.basket-item-details {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 5px;
  justify-content: center;
}

.basket-item-details.no-max-width {
  max-width: unset;
}

.basket-item-details-addon p {
  text-decoration: underline;
  cursor: default;
}

.basket-item-details h3 {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 0;
}

.basket-item-details h4 {
  font-size: 15px;
  margin-bottom: 0;
}

.basket-item-details-row.basket-item-actions {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  padding: 5px 0;
}

.basket-item-details-row.basket-item-actions button {
  background: #E3000E;
  color: #fff;
  font-family: Tenant-Bold, Courier, Helvetica, sans-serif;
  font-size: 13px;
}

.basket-item-details-row.basket-item-actions button.mat-mdc-unelevated-button {
  border-radius: 12px;
  line-height: initial;
  padding: 7px;
}

.basket-item-details-row.basket-item-actions .mat-icon {
  border-radius: 50px;
  cursor: pointer;
  font-size: 27px;
  height: 27px;
  margin: 0 10px;
  width: 27px;
}

.basket-item-details-row.basket-item-actions .mat-icon:hover {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.035), 0 0 5px 0 rgba(0, 0, 0, 0.05);
}

.basket-item-details-row.basket-item-actions .delete-icon {
  margin: 0 5px 0 12px;
}

.basket-item-details-row div {
  display: flex;
  align-items: center;
}

.basket-item-details-row div p {
  flex: 1;
  font-family: Tenant-Bold, Courier, Helvetica, sans-serif;
  margin-bottom: 0;
}

.basket-item-addons-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.basket-item-addons-chip {
  -webkit-box-align: center;
  -webkit-box-pack: center;
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-family: Tenant-Regular, Courier, Helvetica, sans-serif;
  font-size: 13px;
  justify-content: center;
  margin: 3px 0 0 3px;
  padding: 0 3px 0 4px;
  min-height: 23px;
}

.basket-item-addons-chip.add-chip {
  background-color: #00a700;
  color: #fff !important;
  padding: 4px 2px;
}

.basket-item-addons-chip.remove-chip {
  background-color: #eb0000;
  color: #fff;
}

.basket-item-addons-chip .mat-icon {
  font-size: 15px;
  height: 15px;
  width: 15px;
}

.basket-item-addons-chip div {
  line-height: 1;
  padding: 0 2px;
}

span.basket-item-price {
  margin: 0 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
