// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --footer-background-colour: #333333;
  --footer-link-colour: #fff;
  --nav-bar-background-colour: #fff;
  --product-content-padding: 0 10px;
}

router-outlet + app-product {
  flex: 1;
  height: 100%;
}

.select-AddOns-Quantity-plus-disabled {
  opacity: 0.2;
  pointer-events: none;
}

.customise-options-price-amount {
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  padding: var(--product-content-padding);
}

.customise-image img {
  margin-top: 12px;
  max-width: 500px;
  width: 100%;
  height: auto;
}

.product-page-content-subGroups-header {
  line-height: initial;
}

.product-page-content-subGroups-header h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  font-family: Tenant-Regular, Courier, Helvetica, sans-serif;
}

.product-page-content-subGroups-header span {
  font-size: 15px;
}

.customise-info-possible-allergens {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.customise-info-possible-allergens-text {
  font-weight: bold;
  margin-bottom: 8px;
}

.may-contain {
  padding: var(--product-content-padding);
  margin-bottom: 10px;
}

.customise-toppings-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.customise-toppings-list-Item {
  align-items: center;
  display: flex;
  flex: 1;
  min-width: 330px;
  font-size: 14px;
  border: thin solid rgba(0, 0, 0, 0.12);
  margin: 5px 0;
  padding: 2px 5px;
  border-radius: 6px;
}
.customise-toppings-list-Item span {
  margin-right: 3px;
}
.customise-toppings-list-Item span span {
  font-size: 11px;
  margin-right: 0;
}

.customise-toppings-list-Item-price {
  color: #E3000E;
  text-align: right;
  font-size: 12px;
}

.customise-toppings-list-Item-wrapper {
  display: flex;
  align-items: center;
  width: 85px;
}

.customise-toppings-remove {
  margin-bottom: 10px;
}

.customise-toppings-title {
  font-size: 20px;
  font-weight: bold;
}

.desktop {
  display: block;
  height: 100%;
  width: 100%;
}

.mobile {
  display: none;
}

.ImgAndDescription {
  display: flex;
  flex-direction: row;
  max-width: 1300px;
  width: 100%;
  box-sizing: border-box;
  margin: 10px 10px 0;
  flex: none;
}

.productDescription {
  max-width: 50%;
  padding-left: 10px;
  flex: 1;
}

.productImg {
  margin-bottom: 5px;
}

.productImg img {
  height: unset;
  width: 100%;
}

.ProductPage {
  display: flex;
  justify-content: center;
  overflow-y: hidden;
  position: relative;
  flex-direction: column;
  height: 100%;
}

.select-AddOns-Quantity {
  display: flex;
  font-size: 24px;
  font-weight: bold;
  justify-content: center;
  width: 15px;
}

.select-AddOns-Quantity-minus, .select-AddOns-Quantity-plus {
  align-items: center;
  color: #E3000E;
  cursor: pointer;
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px;
  font-size: 18px;
  margin: 0 5px;
}
.select-AddOns-Quantity-minus .mat-icon, .select-AddOns-Quantity-plus .mat-icon {
  font-size: 22px;
  width: 22px;
  height: 22px;
}

.product-page-content {
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
  align-items: center;
  overflow: hidden;
  padding-bottom: 10px;
  max-height: calc(100vh - 285px);
  overflow-y: auto;
}
.product-page-content-add-on {
  display: flex;
  max-width: 1300px;
  margin: 20px auto;
  width: 100%;
}
.product-page-content-offers {
  flex: 1;
}

.product-page-footer {
  height: 100;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  z-index: 1;
  padding-top: 1px;
  min-height: 100;
}
.product-page-footer-quantity {
  height: 55px;
}
.product-page-footer-button {
  cursor: pointer;
  display: inline-block;
  line-height: unset;
  width: 100%;
  background-color: #E3000E;
  height: 45px;
  text-transform: uppercase;
  border-radius: 0 !important;
  border: none !important;
  padding: 0 15px !important;
}
.product-page-footer-button.mat-mdc-button {
  color: #fff;
}

.product-page-modal {
  max-height: 100%;
}
.product-page-modal .customise-info-name {
  font-size: 37px;
}

.product-page-content-card {
  width: calc(100% - 10px);
  box-sizing: border-box;
  padding: 4px;
  border-radius: 8px;
}

.product-page-content-subGroups-ul {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.product-page-content-subGroups-li {
  min-height: 42px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: thin solid rgba(0, 0, 0, 0.12);
  margin: 5px 0;
  padding: 2px 5px;
  width: 100%;
}

.product-page-content-subGroups-li span {
  margin-right: 3px;
  flex: 1;
}

.product-page-content-subGroups-li span span {
  font-size: 11px;
  margin-right: 0;
}

.product-page-content-subGroups-li div span {
  font-size: 12px;
}

.product-page-content-subGroups-li div mat-icon {
  margin: 0 5px;
}

.customise-toppings-add {
  width: 100%;
}

.customise-toppings-body-item {
  padding: 0 5px;
}

.product-page-modifier-section-items {
  padding: 0;
  margin: 0;
}

.product-page-modifier-section-items li {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.customise-options {
  margin-bottom: 5px;
  margin-top: -8px;
}

.customise-options {
  margin-bottom: 5px;
  margin-top: -8px;
}

.customise-toppings-list-Item-img {
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customise-toppings-list-Item-img-container {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
  margin: 1px;
  margin-right: 3px;
}

.customise-toppings-list-Item-name {
  flex: 1;
  display: flex;
  align-items: center;
  line-height: initial;
}

.customise-toppings-list-Item-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
}

.forced-modifier .customise-toppings-list-Item {
  cursor: pointer;
  min-height: 42px;
}
.forced-modifier .customise-toppings-list-Item span {
  margin-right: 3px;
  line-height: 1;
}
.forced-modifier .customise-toppings-list-Item span span {
  font-size: 11px;
  margin-right: 0;
}
.forced-modifier .customise-toppings-list-Item-wrapper {
  display: none;
}
.forced-modifier .customise-toppings-list-Item-img {
  width: 70px;
}
.forced-modifier .customise-toppings-list-Item-name {
  font-weight: bold;
  flex: unset;
  padding: 10px 0 3px;
}

.selected-forced-modifier {
  background-color: rgba(0, 0, 0, 0.04);
}

.product-page-content-offers {
  width: 100%;
}

.selected-modifier-item {
  background: #1976D2;
  color: #fff;
}
.selected-modifier-item .customise-toppings-list-Item-price {
  color: #fff !important;
}
.selected-modifier-item .select-AddOns-Quantity-minus {
  color: #fff;
  font-size: 18px;
}
.selected-modifier-item .select-AddOns-Quantity-plus {
  color: #fff;
}

.allergen-info-header {
  display: flex;
  align-items: center;
}

.allergens-info-icon {
  margin-right: 4px;
  cursor: pointer;
}

.option-allergen-info-header {
  flex-flow: column;
  align-items: flex-end;
  margin-right: 15px;
}

.allergen-header {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3676CB;
  color: #fff;
  min-width: 300px;
}
.allergen-header h1 {
  margin: 0;
}

.allergen-content-info {
  padding: 10px 13px;
}
.allergen-content-info span {
  font-size: 14px;
}
.allergen-content-info div {
  margin: 0 2px;
}

.allergen-content-items {
  display: flex;
  align-items: center;
  padding: 0px 0 10px;
  font-weight: bold;
  flex-wrap: wrap;
}
.allergen-content-items img {
  width: 35px;
  height: 35px;
}

.allergen-content-product {
  padding: 10px 10px 0;
}
.allergen-content-product h3 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0;
}
.allergen-content-product span {
  font-size: 15px;
}

.allergen-content-footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.allergen-content-footer button {
  min-width: 115px;
  background-color: #3676CB;
  color: #fff;
  text-transform: uppercase;
  margin: 0 10px;
}

a.close-button {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 26px;
  width: 26px;
  text-decoration: none;
  color: #000;
}

.product-page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  padding: 0 40px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  z-index: 1;
}
.product-page-header h1 {
  margin: 0;
  font-family: Tenant-Regular, Courier, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 0.9;
  text-align: center;
}
.product-page-header .mat-icon {
  height: 26px;
  width: 26px;
  font-size: 26px;
}

.customise-info-description {
  padding: var(--product-content-padding);
  margin-bottom: 0;
}

.customise-info-nutrition {
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 0;
}

.allergens {
  display: flex;
  align-items: center;
  font-weight: bold;
  flex-wrap: wrap;
  width: 100%;
  padding: var(--product-content-padding);
}

.allergens h2 {
  margin-bottom: 0;
  font-weight: 700 !important;
}

.allergens {
  color: #e70095;
}

.customise-toppings-list-Item-unavailable {
  text-align: center;
  color: silver;
  line-height: initial;
}

@media screen and (min-width: 1023px) {
  .product-page-content::-webkit-scrollbar {
    width: 10px;
  }
  .product-page-content::-webkit-scrollbar-thumb {
    background: #BBBAB9;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    border: 0px solid #fff;
  }
}
@media screen and (max-width: 1023px) {
  .customise-image {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .customise-image img {
    max-width: 750px;
    width: 100%;
  }
  .productDescription {
    padding: 10px 5% 0;
  }
  .product-page-content-add-on {
    background-color: #F5F5F5;
  }
  .ImgAndDescription {
    flex-direction: column;
    margin: 0;
  }
  .customise-image {
    width: 100%;
  }
  .productDescription {
    padding: 10px 0 0 !important;
    max-width: unset !important;
  }
}
@media screen and (max-width: 1023px) and (max-height: 700px) {
  li.customise-toppings-list-Item {
    margin-bottom: 7px;
  }
  li.customise-toppings-list-Item:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 479px) {
  .product-page-content {
    max-height: unset;
    height: calc(100vh - 146px);
  }
  .customise-toppings-list {
    flex-direction: column;
  }
  .customise-toppings-remove {
    max-width: 100%;
  }
  .product-page-modal .customise-info-name {
    font-size: 24px;
  }
}
@media screen and (max-width: 479px) and (max-width: 350px) {
  .customise-toppings-list-Item-name {
    min-width: 150px;
  }
}
@media screen and (max-width: 330px) {
  .customise-toppings-list-Item-name {
    min-width: unset !important;
  }
  .product-page-content-subGroups-li {
    min-width: 285px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
