import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { Basket } from '@app/models/basket';
import { BasketService } from '@app/services/basket.service';
import { LoyaltySchemeRedeemingRules } from '@app/models/loyalty-scheme-redeeming-rules';
import { Observable } from 'rxjs';
import { IssueTypes } from '@app/models/issue-types';
import { Issue } from '@app/models/Issue';
import { CurrencyPipe } from '@angular/common';
import { LoyaltySchemeEarningRules } from '@app/models/loyalty-scheme-earning-rules';
import { Site } from '@app/models/site';

@Component({
  providers: [CurrencyPipe],
  selector: 'app-basket-loyalty',
  styleUrls: ['./basket-loyalty.component.scss', './../../shared-basket-styles.scss'],
  templateUrl: './basket-loyalty.component.html'
})
export class BasketLoyaltyComponent extends AndroWebCoreComponent implements OnChanges {
  @ViewChild('confirmWantedTime', { static: true }) private _confirmWantedTimeModal: TemplateRef<any>;

  @Input() public orderComplete: boolean;
  @Input() public basket: Basket;
  @Input() public currentSite: Site;
  @Input() public usersLoyaltyPoints: number = 0;
  @Input() public earnablePointsForCurrentBasket: number;

  @Input('userId') private _userId: string;
  @Input('basketTotal') private _basketTotal: number = 0;

  public isLoading: boolean;
  public disableRedeemButton: boolean;
  public errorMessage: string;
  public discountValue: string;
  public redeemablePoints: number;
  public totalIsLessThanMinimumSpendError: string;

  private _redeemSucceeded: boolean;
  private _redeemingRules: LoyaltySchemeRedeemingRules;
  private _earningRules: LoyaltySchemeEarningRules;

  constructor(
    private _currencyPipe: CurrencyPipe,
    private _basketService: BasketService
  ) {
    super();
    this.isLoading = true;
    this._redeemingRules = this.tenant?.LoyaltyScheme?.RedeemingRules;
    this._earningRules = this.tenant?.LoyaltyScheme?.EarningRules;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.basket) {
      if (this._redeemSucceeded && this.basket.Issues?.some((x: Issue) => x.IssueType === IssueTypes.OccasionIsNotAvailableAtWantedTime)) {
        this.openWantedTimePicker();
        this._redeemSucceeded = false;
      }

      this.totalIsLessThanMinimumSpend = this.basket.Issues?.some((x: Issue) => x.IssueType === IssueTypes.TotalIsLessThanMinimumSpend);
    }

    if (changes._basketTotal || changes.usersLoyaltyPoints) {
      this.updateCard();
      this.isLoading = false;
    }
  }

  /**
  * takes in a number and multiplies it by the amount a single voucher point is worth to a tenant and returns the result.
  * @param points - the amount of points
  */
  public pointsMonitoryValue(points: number): string {
    const value: number = (points ? points : 0) * (this._redeemingRules ? this._redeemingRules.DiscountValue / this._redeemingRules.PointsValue : 0);
    return this.parseFormatNumber(value);
  }

  /**
  * redeems loyalty on the current basket and closes the loyalty modal
  */
  public async redeemLoyalty(): Promise<void> {
    this.isLoading = true;

    if (this._userId && this.basket && !this.basket.HasCustomer) {
      await this._basketService.setCustomerOnBasket(this.basket.Id, this._userId);
    }

    const redeemObservable: Observable<Basket> = this._basketService.redeemLoyaltyOnBasket(this.basket.Id, this.redeemablePoints);

    if (redeemObservable) {
      this.subscriptions$['BasketLoyaltyComponent-redeemLoyalty-basketService-redeemLoyaltyOnBasket()'] =
              redeemObservable.subscribe({
                error: (x: HttpErrorResponse) => {
                  this.showErrorMessage(x.error.title, x.error.detail);
                  this.trackException(x, false);
                  this.isLoading = false;
                },
                next: () => {
                  // if wanted time is invalid it will appear as if loyalty isn't added to basket, so set to true and listen to ngOnChanges
                  // to open wanted time picker modal
                  this._redeemSucceeded = true;
                  this.isLoading = false;
                }
              });
    }
  }

  /**
  * determines whether the you could earn text shows or not
  */
  public showEarnableLoyaltyPoints(): boolean {
    const maxPoints: number = this._earningRules?.MaxPointsPerCustomer;
    return maxPoints ? this.usersLoyaltyPoints < maxPoints : true;
  }

  /**
   * updates the card with the current redeemable points and whether the redeem button should be disabled or not.
   */
  private updateCard(): void {
    this.redeemablePoints = this._redeemingRules.MinPointsRequired;

    const isEnabled: boolean = this.getMaxRedeemablePoints() >= this.redeemablePoints && this.usersLoyaltyPoints >= this.redeemablePoints;
    this.errorMessage = isEnabled ?
      null :
      this.usersLoyaltyPoints < this.redeemablePoints ?
        'You do not have enough points to redeem' :
        'The basket total is too low to redeem this offer';

    this.disableRedeemButton = !isEnabled;
    this.discountValue = `£${this.pointsMonitoryValue(this.redeemablePoints)}`;
  }

  /**
   * Opens the wanted time picker modal.
   */
  private openWantedTimePicker(): void {
    const options = {
      autoFocus: false,
      disableClose: true,
      width: this.isMobile ? '100%' : '',
    };

    this.openDialog(this._confirmWantedTimeModal, 'order-wanted-time-modal', options);
  }

  /**
   * returns the max amount of points that can be redeemed on the current basket.
   */
  private getMaxRedeemablePoints(): number {
    const rules: LoyaltySchemeRedeemingRules = this._redeemingRules;
    const discountPerPoint: number = rules.DiscountValue / rules.PointsValue;

    let result: number = this.usersLoyaltyPoints;

    const basketTotalInPoints: number = this._basketTotal / discountPerPoint;
    if (result > basketTotalInPoints) {
      result = basketTotalInPoints;
    }

    if (rules.MinRemainingOrderValue) {
      const redeemableThreshold = this._basketTotal - rules.MinRemainingOrderValue;
      const points: number = redeemableThreshold / discountPerPoint;

      if (result > points) {
        result = points;
      }
    }

    if (rules.MaxDiscountValue) {
      const points: number = (rules.MaxDiscountValue / discountPerPoint);
      if (result > points) {
        result = points;
      }
    }

    if (rules.MaxDiscountPercent) {
      const points: number = (this._basketTotal * (rules.MaxDiscountPercent / 100)) / discountPerPoint;
      if (result > points) {
        result = points;
      }
    }

    return result > 0 ? Math.floor(result) : 0;
  }

  /**
  * returns an integer to two decimal places
  * @memberof BasketChargesComponent
  * @public
  */
  private parseFormatNumber(integer: number): string {
    return integer.toFixed(2) || '0.00';
  }

  /**
   * sets whether the total is less than the minimum spend or not.
   * and sets the `totalIsLessThanMinimumSpendError` error message.
   */
  private set totalIsLessThanMinimumSpend(value: boolean) {
    if (value) {
      const minSpend: string = this.basket.DeliveryMinimumSpend ?
        this._currencyPipe.transform(this.basket.DeliveryMinimumSpend, 'GBP', 'symbol', '1.2-2') :
        'the minimum required';
      this.totalIsLessThanMinimumSpendError = `To redeem points the basket subtotal must exceed ${minSpend}`;
    } else {
      this.totalIsLessThanMinimumSpendError = null;
    }
  }
}
