import { DisplayLineTypes } from '@app/models/display-line-types';
import { DisplayAvailableType } from '@app/models/display-available-type';
import { DisplayDisplayGroup } from '@app/models/display-display-group';

export class DisplayDealLineOptions {
  LineNumber: number;
  LineType: DisplayLineTypes;
  LineTypeValue: number;
  DisplayGroups: Array<DisplayDisplayGroup>;
  IsAvailableForWantedTime(): DisplayAvailableType {
    return this.AvailableForWantedTime();
  }

  public AvailableForWantedTime(): DisplayAvailableType {
    const availableOptions = Array.from(new Set(this.DisplayGroups.map((e: DisplayDisplayGroup) => e.IsAvailableForWantedTime).values()));

    if (availableOptions.includes(DisplayAvailableType.Yes)) {
      return DisplayAvailableType.Yes;
    }

    if (availableOptions.includes(DisplayAvailableType.No)) {
      return DisplayAvailableType.No;
    }

    return DisplayAvailableType.Unknown;
  }
}
