// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --footer-background-colour: #333333;
  --footer-link-colour: #fff;
  --nav-bar-background-colour: #fff;
  --product-content-padding: 0 10px;
}

.basket-charge-list-item {
  display: flex;
  font-family: Tenant-Bold, Courier, Helvetica, sans-serif;
  justify-content: space-between;
  width: 100%;
}

.basket-charge-list-item samp {
  font-family: Tenant-Bold, Courier, Helvetica, sans-serif;
}

.basket-charge-list-item.basket-total {
  font-size: 1.25em;
}

.basket-charge-list-item-group {
  display: flex;
  margin-right: -1px;
}

.basket-charge-list-item-group .mat-icon {
  cursor: pointer;
  padding: 0 0 0 2px;
}

.basket-charge-total {
  display: flex;
  font-family: Tenant-Bold, Courier, Helvetica, sans-serif;
  flex-direction: column;
}

.basket-charges {
  padding: 0 5px;
  overflow: hidden;
}

.basket-charge-errors {
  line-height: 1;
  text-align: center;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
}

.basket-charge-errors span {
  color: red;
  font-family: Tenant-Bold, Courier, Helvetica, sans-serif;
}

.basket-charge-alert {
  font-size: 13px;
  padding-left: 4px;
  font-weight: bold;
  margin: 3px 0 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
