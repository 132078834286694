import { BehaviorSubject, Observable, of } from 'rxjs';

import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Site } from '@app/models/site';
import { catchError, finalize, map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { ISiteMapDetails } from '@app/models/ISiteMapDetails';
import { ServiceHelper } from './service.helper';
import { PostCodeIO } from '@app/models/postcode-io';
import { ApiExtendedService } from './root/api-extended.service';

@Injectable({
  providedIn: 'root',
})
export class SiteService extends ApiExtendedService {
  public currentSite$: Observable<Site>;
  public sites: BehaviorSubject<Site[]>;
  public onBusy = new BehaviorSubject<boolean>(false);
  public currentSite: BehaviorSubject<Site>;

  private _detailedSites: BehaviorSubject<ISiteMapDetails[]>;
  private _sitesRequest$: Observable<Site[]> | null = null;

  constructor(
    private _serviceHelper: ServiceHelper
  ) {
    super();
    this.sites = new BehaviorSubject<Site[]>(null);
    this.currentSite = new BehaviorSubject<Site>(null);
    this.currentSite$ = this.currentSite.asObservable();
    this._detailedSites = new BehaviorSubject<ISiteMapDetails[]>(null);
  }

  /**
   * Get the sites for the tenant
   */
  public getSites(): Observable<Site[]> {
    this.onBusy.next(true);
    return this.sites.value ? this.sites.asObservable() : this.getSitesFromApi();
  }

  /**
   * Gets the site by postal code from the API
   */
  public getSitesByPostCode(postCode: string): Observable<Site[]> {
    this.onBusy.next(true);

    return this.getResource<Site[]>('sites', new HttpParams().set('PostCode', postCode))
        .pipe(
            catchError((error: HttpErrorResponse) => {
              this.trackError(error);
              return of([]);
            }),
            finalize(() => this.onBusy.next(false))
        ) as Observable<Site[]>;
  }

  /**
   * Sets the current site
   */
  public setCurrentSite(site: Site): void {
    this.currentSite.next(site);
  }

  /**
   * Sets the current site by site ID
   */
  public setCurrentSiteBySiteId(siteId: string): void {
    const getMatchingSite = (sites: Site[] | null) => sites?.find((site: Site) => site.Id.toLowerCase() === siteId.toLowerCase());

    const site = getMatchingSite(this.sites.getValue());

    if (site) {
      this.setCurrentSite(site);
      return;
    }

    this.getSites()
        .pipe(
            map((sites: Site[]) => getMatchingSite(sites)),
            takeUntil(this.destroy$)
        )
        .subscribe((x: Site) => this.setCurrentSite(x));
  }

  /**
   * Gets the detailed sites
   */
  public get detailedSites$(): Observable<ISiteMapDetails[]> {
    if (!this._detailedSites.value) {
      this._detailedSites.next([]);

      this.getSites()
          .pipe(takeUntil(this.destroy$))
          .subscribe((async (value: Site[]) => {
            this._detailedSites.next(await this.getDetailedSites(value));
          }));
    }

    return this._detailedSites.asObservable();
  }

  /**
   * Gets the sites from the API
   */
  private getSitesFromApi(): Observable<Site[]> {
    if (!this._sitesRequest$) {
      this.onBusy.next(true);

      this._sitesRequest$ = this.getResource<Site[]>('sites').pipe(
          tap((response: Site[]) => {
            this.sites.next(response);
            this.onBusy.next(false);
          }),
          catchError((error: HttpErrorResponse) => {
            this.trackError(error);
            this.onBusy.next(false);
            return of([]);
          }),
          shareReplay(1),
          finalize(() => {
            this._sitesRequest$ = null;
          })
      );
    }

    return this._sitesRequest$;
  }

  /**
   * maps all sites to the ISiteMapDetails interface
   */
  private async getDetailedSites(sites: Site[]): Promise<ISiteMapDetails[]> {
    const siteMapDetailsPromises: Promise<ISiteMapDetails>[] = sites
        .filter((x: Site) => x.Address.Postcode)
        .map((x: Site) => this.createSiteMapDetails(x));
    return await Promise.all(siteMapDetailsPromises);
  }

  /**
   * creates a site map details object from a site
   * @param site
   */
  private async createSiteMapDetails(site: Site): Promise<ISiteMapDetails> {
    const location = site.Address.Longitude !== 0 && site.Address.Latitude ?
      { latitude: site.Address.Latitude, longitude: site.Address.Longitude } :
      await this.getLatLongFromPostcode(site.Address.Postcode);

    return {
      address: site.Address,
      id: site.Id,
      location,
      name: site.Name,
      occasionsSupported: site.OccasionsSupported,
      openingHours: this._serviceHelper.getOpeningDatesAndTimes(site.OpeningHours),
      phone: site.Phone,
      selected: false,
      specialOpeningHoursMessages: site.SpecialOpeningHoursMessages,
    };
  }

  /**
  * returns latitude and longitude coordinates from a postcode
  * @param postcode - the postcode
  */
  private getLatLongFromPostcode(postcode: string): Promise<{ latitude: number; longitude: number }> {
    return fetch(`https://api.postcodes.io/postcodes/${postcode}`)
        .then((response) => response.json())
        .then((result: PostCodeIO) => ({
          latitude: result.result.latitude,
          longitude: result.result.longitude
        }));
  }
}
