import { Injectable, Inject, InjectionToken } from '@angular/core';
import { IAnalyticsProvider } from '@app/models/app-initialisers/IAnalyticsProvider';
import { ProductToTrack } from '@app/models/product-to-track';
import { ModifierSection } from '@app/models/modifier-section';
import { DealTracking } from '@app/models/deals/DealTracking';

export const AnalyticsProvider = new InjectionToken<IAnalyticsProvider>('AnalyticsProvider');

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements IAnalyticsProvider {
  private providers: IAnalyticsProvider[];

  constructor(@Inject(AnalyticsProvider) analyticsProviders: IAnalyticsProvider[]) {
    this.providers = analyticsProviders;
  }
  pageView(path: string, title: string): void {
    this.providers.forEach((p) => {
      p.pageView(path, title);
    });
  }
  setupUserId(userID: string) : void {
    this.providers.forEach((p) => {
      p.setupUserId(userID);
    });
  }
  trackException(exception: Error, isFatal: boolean): void {
    this.providers.forEach((p) => {
      p.trackException(exception, isFatal);
    });
  }
  trackPerformance(category: string, path: string, time: number, label?: string): void {
    this.providers.forEach((p) => {
      p.trackPerformance(category, path, time, label);
    });
  }

  trackViewProduct(productName: string, productId: string, category: string, price: number, currency: string): void {
    this.providers.forEach((p) => {
      p.trackViewProduct(productName, productId, category, price, currency);
    });
  }

  trackViewDeals(dealId: string, dealName: string, category: string, currency: string): void {
    this.providers.forEach((p: IAnalyticsProvider) => {
      p.trackViewDeals(dealId, dealName, category, currency);
    });
  }

  trackClickProduct(productName: string, productId: string, category: string, price: number, currency: string): void {
    this.providers.forEach((p) => {
      p.trackClickProduct(productName, productId, category, price, currency);
    });
  }
  trackAddRemoveProductToBasket(added: boolean, productName: string, productId: string, category: string, price: number, currency: string): void {
    this.providers.forEach((p) => {
      p.trackAddRemoveProductToBasket(added, productName, productId, category, price, currency);
    });
  }
  trackAddRemoveDealToBasket(added: boolean, payload: DealTracking): void {
    this.providers.forEach((p) => {
      p.trackAddRemoveDealToBasket(added, payload);
    });
  }
  trackCheckout(products: Array<ProductToTrack>, currency: string): void {
    this.providers.forEach((p) => {
      p.trackCheckout(products, currency);
    });
  }

  trackCheckoutOption(step: number, option: any): void {
    this.providers.forEach((p) => {
      p.trackCheckoutOption(step, option);
    });
  }

  trackCheckoutModifier(modifiers: ModifierSection[]): void {
    this.providers.forEach((p: IAnalyticsProvider) => {
      p.trackCheckoutModifier(modifiers);
    });
  }

  trackPurchase(orderId: string, amount: number, tax: number, deliveryCharge: number, voucher: string, occasion: string, products: Array<ProductToTrack>): void {
    this.providers.forEach((p) => {
      p.trackPurchase(orderId, amount, tax, deliveryCharge, voucher, occasion, products);
    });
  }
}
