import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Menu } from '@app/models/menu';
import { Basket } from '@app/models/basket';
import { DisplayMenu } from '@app/models/display-menu';
import { Deal } from '@app/models/Deal';
import { Product } from '@app/models/Product';
import { IDictionary } from '@app/models/IDictionary';
import { ApiExtendedService } from './root/api-extended.service';
import { DisplayDeal } from '@app/models/display-deal';
import { DisplayMenuGroup } from '@app/models/display-menu-group';
import { DisplayModifierSection } from '@app/models/display-modifier-section';
import { DisplayProduct } from '@app/models/display-product';
import { DisplayProductList } from '@app/models/display-product-list';
import { DisplayMenuPrice } from '@app/models/display-menu-price';

@Injectable()
export class MenuService extends ApiExtendedService {
  public currentMenu$: Observable<DisplayMenu>;

  private _cachedMenus:IDictionary<DisplayMenu> = {};
  private _currentMenu = new BehaviorSubject<DisplayMenu>(null);

  constructor() {
    super();
    this.currentMenu$ = this._currentMenu.asObservable();
  }

  public async getDisplayMenuByBasket(basket: Basket, refresh?: boolean): Promise<DisplayMenu> {
    const wantedTime: number | 'asap' = basket.WantedTimeUtc ? new Date(basket.WantedTimeUtc).getTime() : 'asap';
    const menuKey: string = `${basket.Id}::${basket.Occasion}::${wantedTime}`;
    const cachedMenu: DisplayMenu = this._cachedMenus[menuKey];

    if (!refresh && cachedMenu) {
      this._currentMenu.next(cachedMenu);
      return Promise.resolve(cachedMenu);
    }

    const response: Menu | HttpErrorResponse = await this.getResourceAsync<Menu>(`baskets/${basket.Id}/menu`);

    if (response instanceof HttpErrorResponse) {
      return null;
    }

    const menu: DisplayMenu = this.mapToDisplayMenu(this.transformMenu(response));
    this._cachedMenus[menuKey] = menu;
    this._currentMenu.next(menu);

    return menu;
  }

  /**
   * makes all product/deal images small and removes magic characters from deal descriptions.
   * @param menu
   */
  private transformMenu(menu: Menu): Menu {
    for (const deal of menu.Deals) {
      this.swapImageExtension(deal);
      this.checkDealsForMagicDescription(deal);
    }

    for (const product of menu.Products) {
      this.swapImageExtension(product);
    }

    return this.checkProductsForProviderDeals(menu);
  }

  /**
   * replaces all imageUrls that include an extension to `landscape-small.jpg`.
   * @param item
   */
  private swapImageExtension(item: Product | Deal): void {
    if (!item.ImageUrl) {
      return;
    }

    // eslint-disable-next-line no-useless-escape
    const reg: RegExp = /([^\/]+$)/g;
    const regResponse: RegExpExecArray = reg.exec(item.ImageUrl);
    const result: string = regResponse ? regResponse[0] : '';

    if (result.includes('.')) {
      item.ImageUrl = item.ImageUrl.replace(result, 'landscape-small.jpg');
    }
  }

  /**
   * TEMP SOLUTION
   * removes well defined text for Order Providers on the customerDescription.
   * Additionally locks all '!A' to filter out products not for Androweb.
   * Example text || !A, XJE, XUE, XD ||
   * @param menu
   */
  private checkDealsForMagicDescription(deal: Deal) {
    if (!deal.CustomerDescription) {
      return;
    }

    const regNotAndroWeb: RegExp = /\|\|.*!A.*\|\|/i;
    if (regNotAndroWeb.test(deal.CustomerDescription)) {
      deal.RequiresUnlock = true;
      return;
    }

    const reg: RegExp = /\|\|((?!\|\|).+)\|\|/g;
    deal.CustomerDescription = deal.CustomerDescription.replace(reg, '');
  }

  /**
   * TEMP SOLUTION
   * removes well defined text for Order Providers on the customerDescription.
   * Additionally locks all '!A' to filter out products not for Androweb.
   * Example text || !A, XJE, XUE, XD ||
   * @param menu
   */
  private checkProductsForProviderDeals(menu: Menu): Menu {
    const excludeFromAndroweb: RegExp = /\|\|.*!A.*\|\|/i;

    const products = menu.Products.filter((x: Product) => !x.CustomerDescription || !excludeFromAndroweb.test(x.CustomerDescription));

    const reg: RegExp = /\|\|((?!\|\|).+)\|\|/g;

    products.filter((x: Product) => !!x.CustomerDescription)
        .forEach((x: Product) => {
          x.CustomerDescription = x.CustomerDescription.replace(reg, '');
        });

    menu.Products = products;

    return menu;
  }

  private mapToDisplayMenu(apiMenu: Menu): DisplayMenu {
    const products: DisplayProduct[] = apiMenu.Products.map((product: Product) => this.mapToDisplayProduct(product));
    const deals: DisplayDeal[] = apiMenu.Deals.map((deal: Deal) => DisplayDeal.FromApiDeal(deal));

    const menu: DisplayMenu = new DisplayMenu();
    menu.Id = apiMenu.Id;
    menu.Products = products;
    menu.ProductLists = apiMenu.ProductLists?.map((e) => new DisplayProductList(e.Id, e.ProductIds)) ?? [];

    menu.Products.forEach((displayProduct: DisplayProduct) => {
      const apiProduct: Product = apiMenu.Products.find((x: Product) => x.Id === displayProduct.Id);

      if (!apiProduct) {
        return;
      }

      displayProduct.Modifiers = {
        Default: products.filter((e: DisplayProduct) => apiProduct.Modifiers.Default.includes(e.Id)),
        Max: apiProduct.Modifiers.Max,
        MaxEach: apiProduct.Modifiers.MaxEach,
        MaxFree: apiProduct.Modifiers.MaxFree,
        Optional: products.filter((e: DisplayProduct) => apiProduct.Modifiers.Optional.includes(e.Id))
      };
    });

    menu.Groups = DisplayMenuGroup.FromApiMenuGroups(apiMenu.Groups, products, deals);
    menu.Deals = apiMenu.Deals ? deals : [];
    menu.ModifierSections = DisplayModifierSection.fromApiMenuModifierSections(apiMenu.ModifierSections, menu);
    return menu;
  }

  private mapToDisplayProduct(apiProduct: Product): DisplayProduct {
    const product = new DisplayProduct();
    product.AllergenDetails = apiProduct.AllergenDetails;
    product.DealPricePremium = apiProduct.DealPricePremium;
    product.Description = apiProduct.CustomerDescription ? apiProduct.CustomerDescription : apiProduct.Description;
    product.ImageUrl = apiProduct.ImageUrl;
    product.Id = apiProduct.Id;
    product.Name = apiProduct.Name;
    product.CustomerName = apiProduct.CustomerName;
    product.Prices = DisplayMenuPrice.FromApiMenuPrices(apiProduct.Prices);
    product.ValidForBasket = apiProduct.ValidForBasket;
    product.OutOfStock = apiProduct.OutOfStock;
    product.Sequence = apiProduct.Sequence;
    product.NutritionSummary = apiProduct.Nutrition ? `${apiProduct.Nutrition.Calories} kcal • serves ${apiProduct.Nutrition.NumberOfPortions}` : null;
    product.Nutrition = apiProduct.Nutrition;
    return product;
  }
}

