import { ModifierSubSection } from '@app/models/modifier-sub-section';
import { DisplayProduct } from '@app/models/display-product';
import { DisplayMenu } from '@app/models/display-menu';
import { ModifierVariant } from '@app/models/modifier-variant';

export class DisplayModifierSubSection {
  Id: string;
  Name: string;
  CustomerName: string;
  CustomerDescription: string;
  MutuallyExclusive: boolean;
  AllModifiers: DisplayProduct[];
  Modifiers?: DisplayProduct[];
  ModifierVariants: ModifierVariant[];

  public static fromApiMenuModifierSections(_apiSections: ModifierSubSection[], menu: DisplayMenu): DisplayModifierSubSection[] {
    const subsections: DisplayModifierSubSection[] = [];

    _apiSections.forEach((section: ModifierSubSection) => {
      subsections.push(this.fromApiMenuModifierSubSection(section, menu));
    });

    return subsections;
  }

  private static fromApiMenuModifierSubSection(_modifierSection: ModifierSubSection, menu: DisplayMenu): DisplayModifierSubSection {
    const subSection = new DisplayModifierSubSection();
    subSection.Id = _modifierSection.Id;
    subSection.Name = _modifierSection.Name;
    subSection.CustomerName = _modifierSection.CustomerName;
    subSection.CustomerDescription = _modifierSection.CustomerDescription;
    subSection.AllModifiers = [];
    subSection.MutuallyExclusive = _modifierSection.MutuallyExclusive;

    const allModifiers: DisplayProduct[] = [];
    const allModifiersVariants: ModifierVariant[] = [];

    _modifierSection.ModifierIds.forEach((id: string) => {
      const product = menu.Products.find((_product: DisplayProduct) => _product.Id === id);

      if (product) {
        if (allModifiers.findIndex((mod: DisplayProduct) => mod.Id === product.Id) === -1) {
          allModifiers.push(product);
        }

        const modifier: ModifierVariant = {
          AllModifiers: menu.Products.filter((_product: DisplayProduct) => _product.Name === product.Name),
          Name: product.Name,
        };

        if (allModifiersVariants.findIndex((mod: ModifierVariant) => mod.Name === modifier.Name) === -1) {
          allModifiersVariants.push(modifier);
        }
      }
    });

    subSection.AllModifiers = allModifiers;
    subSection.ModifierVariants = allModifiersVariants;

    return subSection;
  }
}
