import { ModifierSection } from '@app/models/modifier-section';
import { ModifierSubSection } from '@app/models/modifier-sub-section';
import { PortalIdentifier } from '@app/models/portal-identifier';
import { DisplayProduct } from '@app/models/display-product';
import { DisplayMenu } from '@app/models/display-menu';
import { DisplayModifierSubSection } from '@app/models/display-modifier-sub-section';

export class DisplayModifierSection {
  Id: string;
  Name: string;
  Identifiers: PortalIdentifier[];
  SubSections: DisplayModifierSubSection[];
  Modifiers: DisplayProduct[];
  AllModifierIds: string[];
  AllVariantsModIds: string[];

  public static fromApiMenuModifierSections(_modifierSections: ModifierSection[], menu: DisplayMenu): DisplayModifierSection[] {
    const modifierSections: DisplayModifierSection[] = [];

    if (_modifierSections && _modifierSections.length > 0) {
      _modifierSections.forEach((_modifierSection: ModifierSection) => {
        modifierSections.push(this.fromApiMenuModifierSection(_modifierSection, menu));
      });
    }

    return modifierSections;
  }

  private static fromApiMenuModifierSection(_modifierSection: ModifierSection, menu: DisplayMenu) {
    const modifierSection = new DisplayModifierSection();
    modifierSection.Id = _modifierSection.Id;
    modifierSection.Name = _modifierSection.Name;
    modifierSection.Identifiers = _modifierSection.Identifiers;
    modifierSection.SubSections = DisplayModifierSubSection.fromApiMenuModifierSections(_modifierSection.SubSections, menu);
    modifierSection.Modifiers = [];
    modifierSection.AllModifierIds = this.getModifierIdsFromSection(_modifierSection, modifierSection.SubSections);
    modifierSection.AllVariantsModIds = this.getAllVaraintsModIdsFromSection(modifierSection);

    _modifierSection.ModifierIds.forEach((id) => {
      const mod = menu.Products.find((product: DisplayProduct) => product.Id === id);

      if (mod) {
        modifierSection.Modifiers.push(mod);
      }
    });

    return modifierSection;
  }

  private static getModifierIdsFromSection(section: ModifierSection | ModifierSubSection, displaySubSections?: DisplayModifierSubSection[]) {
    let modifierIds: string[] = [];

    if (section) {
      modifierIds = section.ModifierIds;

      if ((section as ModifierSection).SubSections) {
        (section as ModifierSection).SubSections.forEach((subSection: ModifierSubSection) => {
          const displaySection = displaySubSections.find((sub: DisplayModifierSubSection) => sub.Id === subSection.Id);

          if (displaySection && displaySection.AllModifiers.length > 0) {
            modifierIds = modifierIds.concat(displaySection.AllModifiers.map((mod: DisplayProduct) => mod.Id));
          }

          modifierIds.push(...this.getModifierIdsFromSection(subSection));
        });
      }
    }

    return modifierIds;
  }

  private static getAllVaraintsModIdsFromSection(section: DisplayModifierSection) {
    let modifierIds: string[] = [];

    if (section) {
      section.SubSections.forEach((subSection: DisplayModifierSubSection) => {
        subSection.ModifierVariants = [];
        modifierIds = subSection.ModifierVariants.length > 0 ? subSection.ModifierVariants.map((varaint) => varaint.AllModifiers.map((mod: DisplayProduct) => mod.Id)).reduce((a, b) => a.concat(b)) : [];
      });
    }

    return modifierIds;
  }
}
