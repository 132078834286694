import { OrderOccasion } from '@app/models/order-occasion';

/**
 * The data within a price on the menu
 * @export
 * @class MenuPrice
 */
export class MenuPrice {
    Occasion: OrderOccasion
    Amount: number
    TaxRate: number
}
