import { DisplayProduct } from '@app/models/display-product';

/**
 * The data within a modifier type
 * @export
 * @class ModifierType
 */
export class ModifierType {
  isRemoved: boolean;
  product: DisplayProduct;
  quantity?: number;
}
