import { NgModule } from '@angular/core';
import { PipesModule } from '@app/modules/pipes/pipes-module';
import { SharedMaterialModule } from '@app/shared/shared-material.module';
import { SharedImportsModule } from '@app/shared/shared-imports.module';
import { DealsComponent } from '@app/shared/deals/deals.component';
import { ProductV2Component } from '@app/shared/product/product.component';
import { BasketModule } from '@app/shared/basket/basket.module';
import { ViewHeightDirective } from '@app/directives/ViewHeight.directive';
import { AddressInputComponent } from '@app/shared/address-input/address-input.component';
import { AndrowebInputControlComponent } from '@app/shared/androweb-input-control/androweb-input-control.component';

@NgModule({
  declarations: [
    AddressInputComponent,
    AndrowebInputControlComponent,
    DealsComponent,
    ProductV2Component
  ],
  exports: [
    AddressInputComponent,
    AndrowebInputControlComponent,
    BasketModule,
    DealsComponent,
    PipesModule,
    ProductV2Component,
    SharedMaterialModule,
    SharedImportsModule,
    ViewHeightDirective
  ],
  imports: [
    BasketModule,
    PipesModule,
    SharedMaterialModule,
    SharedImportsModule,
    ViewHeightDirective
  ]
})
export class SharedComponentsModule { }
