import { DisplayOccasionType } from '@app/models/display-occasion-type';
import { DisplayAvailableType } from '@app/models/display-available-type';
import { DisplayMenuItem } from '@app/models/display-menu-item';
import { DisplayAllergenDetails } from '@app/models/display-allergen-details';

export class DisplayDisplayGroup {
  Name: string;
  CustomerName?: string;
  CustomerDescription?: string;
  AllergenDetails?: DisplayAllergenDetails;
  ImageUrl?: string;
  Items: DisplayMenuItem[] = [];
  Hidden?: boolean;

  get AvailableOccasions(): DisplayOccasionType[] {
    return Array.from(new Set(this.Items.map((e: DisplayMenuItem) => e.AvailableOccasions).reduce((a: DisplayOccasionType[], b: DisplayOccasionType[]) => a.concat(b))).values());
  }

  get IsAvailableForWantedTime(): DisplayAvailableType {
    return this.AvailableForWantedTime();
  }

  public AvailableForWantedTime(): DisplayAvailableType {
    const availableOptions = Array.from(new Set(this.Items.map((e: DisplayMenuItem) => e.IsAvailableForWantedTime())).values());

    if (availableOptions.includes(DisplayAvailableType.Yes)) {
      return DisplayAvailableType.Yes;
    }

    if (availableOptions.includes(DisplayAvailableType.No)) {
      return DisplayAvailableType.No;
    }

    return DisplayAvailableType.Unknown;
  }
}
